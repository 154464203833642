(function(app, $) {
	var $cache = {};
	var configs = {
		delay: 300,
		simpleSearchHandler: searchOriginalHandler,
		buildResponseDom: buildResponseDom,
		successSimpleSearchCallback: successSimpleSearchCallback,
		minimizedClass: 'h-minimized'
	};
	var timeout = null;
	var isLoading = false;
	var selectedItem = 0;
	var selectedClass = 'm-selected';
	var minimizedClass = 'h-minimized';

	/**
	 * @function
	 * @description Init cache
	 */
	function initializeCache() {
		$cache = {
			document: $(document),
			rootElements: $('html, body'),
			items: [],
			searchInput: $('.js-quicksearch'),
			resultContainer: $('.js-quicksearch_result_container'),
			seachItemTemp: $('#js-simple_search_item').html(),
			clearSearch: $('.js-search_clear'),
			suggestInput: $('.js-simple_search_suggest_phrase'),
			simpleSearchSuggestions: $('.js-simple_search_suggestions'),
			searchCategoryButtonsBlock: $('.js-simple_search_cat_btn_block'),
			searchButton: $('.js-simple_search_submit_button'),
			hiddenClass: 'h-hidden',
			searchIcon: $('.js-search-icon'),
			searchActiveButtonClass: '.js-simple_search_category_button.active',
			viewMoreTemp: $('#js-template-view-more').html(),
			productDetail: $('.js-product_detail'),
			searchSuggestionsActive: 'm-search_suggestions--active',
			searchQtySel: '.js-search-result-qty',
			searchNoResultsSel: '.js-search-result-noresults'
		};
	}

	function selectItem(offset) {
		if ($cache.items.length > 0) {
			if (selectedItem) {
				$($cache.items[selectedItem - 1]).removeClass(selectedClass);
			}

			selectedItem += offset;

			if (selectedItem > $cache.items.length) {
				selectedItem = 1;
			} else if (selectedItem < 1) {
				selectedItem = $cache.items.length;
			}

			$($cache.items[selectedItem - 1]).addClass(selectedClass);
		}
	}

	function searchFocus() {
		setTimeout(function() {
			$cache.searchInput.focus();
		}, 50);
	}

	/**
	 * @function
	 * @description Init DOM events
	 */
	function initializeEvents(params) {
		if (app.preferences.simplesearchUseNavigationKeys) {
			$cache.searchInput.on('keyup', function(event) {
				switch (event.which) {
					case 38: // up
						selectItem(-1);
						break;
					case 40: // down
						selectItem(+1);
						break;
					case 13: // enter
						$cache.searchInput.off();
						clearTimeout(timeout);
						var url = window.location.href;
						var input = $(this);

						input.prop('disabled', true);

						if (selectedItem) {
							url = $($cache.items[selectedItem - 1]).attr('data-url');
						} else {
							var categoryID = input.parents('form').find($cache.searchActiveButtonClass).val().trim();

							url = input.parents('form').attr('action') + '?q=' + input.val().trim();

							if (categoryID) {
								url = app.util.appendParamToURL(url, 'cgid', categoryID);
							}
						}

						window.location.href = url;
						break;
					default:
						if (timeout) {
							clearTimeout(timeout);
						}

						$cache.resultContainer.empty();
						$cache.rootElements.removeClass($cache.searchSuggestionsActive);
						reloadSearch();
						break;
				}

				return false;
			});
		} else {
			$cache.searchInput.on('keyup', function(event) {
				switch (event.which) {
					case 13: // enter
						$cache.searchInput.off();
						clearTimeout(timeout);
						var input = $(this);
						var url = input.parents('form').attr('action') + '?q=' + input.val().trim();
						var categoryID = input.parents('form').find($cache.searchActiveButtonClass).val().trim();

						if (categoryID) {
							url = app.util.appendParamToURL(url, 'cgid', categoryID);
						}

						input.prop('disabled', true);
						window.location.href = url;
						break;
					case 27:
						break;
					default:
						if (timeout) {
							clearTimeout(timeout);
						}

						// clean previous result
						$cache.resultContainer.empty();
						reloadSearch();
						break;
				}

				return false;
			});
		}

		$cache.searchInput.on('blur', function() {
			var $this = $(this);

			if ($this.val() == '') {
				$cache.resultContainer.empty().addClass(minimizedClass);
				$cache.rootElements.removeClass($cache.searchSuggestionsActive);
			}
		});

		$cache.document.on('close.element.toggle toggle.finished', function() {
			$cache.searchInput.val('');
			$cache.resultContainer.empty().addClass(minimizedClass);
			$cache.rootElements.removeClass($cache.searchSuggestionsActive);
		});

		$cache.document.on('toggler.toggled', function() {
			$cache.rootElements.toggleClass($cache.searchSuggestionsActive, !$cache.resultContainer.hasClass(minimizedClass) && !$cache.resultContainer.is(':empty'));
		});

		$cache.document.on('toggler.untoggled', function() {
			$cache.rootElements.removeClass($cache.searchSuggestionsActive);
		});

		$cache.searchIcon.on('click', searchFocus);
	}

	/**
	 * @function
	 * @description Wrapper for ajax call
	 */
	function reloadSearch() {
		if (!isLoading) {
			var searchTerm = $cache.searchInput.filter(Boolean).val() || $cache.searchInput.val();

			$cache.resultContainer.addClass(minimizedClass);

			if (searchTerm.length >= app.preferences.simplesearchTermLength && app.util.getConfig('showPreviewSearchResults')) {
				timeout = setTimeout(function() {
					configs.simpleSearchHandler($cache);
				}, configs.delay);
			}
		}
	}

	/**
	 * @function
	 * @description Ajax handler for search products
	 * @param {Object} global cache
	 */
	function searchOriginalHandler($cache) {
		isLoading = true;

		var searchTerm = $cache.searchInput.filter(Boolean).val() || $cache.searchInput.val();
		var selectedCategory = $('.js-min_search .js-simple_search_category_button.active').val() || app.preferences.simpleSearchDefaultCategoryId;
		var url = app.util.appendParamsToUrl(app.urls.searchSuggestions, { q: searchTerm, cgid: selectedCategory });

		app.ajax.load({
			url: url,
			callback: function(data) {
				configs.successSimpleSearchCallback(data, $cache);
				isLoading = false;
			}
		});
	}

	/**
	 * @function
	 * @description Build result DOM of searched products
	 * @param {Array} Array of searched product + suggested phrase
	 * @param {Object} global cache
	 */
	function buildResponseDom(data, $cache) {
		var items = '';
		var itemsQuantity = 0;
		var itemsData = data;

		if (app.preferences.simplesearchShowViewMore && itemsData.suggestionsJSON) {
			itemsData = itemsData.suggestionsJSON;
			itemsQuantity = data.itemsQuantity;
		}

		// get full html
		$.each(itemsData, function(index, value) {
			if (Object.prototype.hasOwnProperty.call(value, 'id')) {
				items += app.util.renderTemplate($cache.seachItemTemp, {
					id: value.id,
					name: value.name,
					category: value.category || '',
					image: value.image,
					url: value.url,
					price: value.price,
					brand: value.brand,
					badge: value.badge || {},
					badges: value.badges || {},
					wishlistUrl: value.wishlistUrl,
					quickviewUrl: value.quickviewUrl,
					alt: value.alt || '',
					title: value.title || '',
					ariaLabel: value.ariaLabel || '',
					preorderClass: value.preorderClass,
					preorderLabel: value.preorderLabel,
					absurl: value.absurl
				});
			}
		});

		// append result to the container
		$cache.resultContainer.html(items);
		$cache.rootElements.toggleClass($cache.searchSuggestionsActive, !!itemsData.length);

		if (app.preferences.simplesearchShowViewMore && $cache.viewMoreTemp.length && items) {
			var searchTerm = $cache.searchInput.val();
			var url = app.util.appendParamsToUrl(app.urls.searchShow, { q: searchTerm });

			var viewMoreHtml = app.util.renderTemplate($cache.viewMoreTemp, {
				itemsQuantity: itemsQuantity,
				url: url
			});

			$cache.resultContainer.append(viewMoreHtml);
		}

		$cache.items = $cache.resultContainer.children();
		selectedItem = 0;
		$cache.document.trigger('suggestions.updated', {
			height: $cache.items.height()
		});
	}

	/**
	 * @function
	 * @description Callback functions for Ajax search
	 * @param {Array} Array of searched product + suggested phrase
	 * @param {Object} global cache
	 */
	function successSimpleSearchCallback(data, $cache) {
		this.buildResponseDom(data, $cache);

		if ($cache.resultContainer.find('li').length) {
			$cache.resultContainer.removeClass(this.minimizedClass);
		}
	}

	/*
	 * @namespace app.global.searchsuggestions public object
	*/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.simplesearch = {
		init: function(params) {
			if (params) {
				configs = $.extend(true, {}, configs, params);
			}

			initializeCache(params);
			initializeEvents(params);
		},

		searchFocus: searchFocus
	};
}(window.app = window.app || {}, jQuery));
