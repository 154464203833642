import { v4 as uuidv4 } from 'uuid';
import VimeoAdapter from '../classes/VideoAdapter/Vimeo';
import YoutubeAdapter from '../classes/VideoAdapter/Youtube';

const $window = $(window);
const elements = {};
const scrollHandlers = [];
let scrollHandlerStarted = false;
const passiveEventListenerOptions = Modernizr.passiveeventlisteners ? { passive: true } : false;

const addToScrollHandler = (handler) => {
	if (!scrollHandlerStarted) {
		window.addEventListener('scroll', () => {
			scrollHandlers.forEach((callback) => {
				callback();
			});
		}, passiveEventListenerOptions);

		scrollHandlerStarted = true;
	}

	scrollHandlers.push(handler);
};

const resizeVideo = (iframe) => {
	const $iframe = $(iframe);
	const videoItem = $iframe.parent();
	const videoWidth = $iframe.width();
	const videoHeight = $iframe.height();
	const oRatio = videoWidth / videoHeight;
	const cRatio = videoItem.width() / videoItem.height();

	if (oRatio <= cRatio) {
		$iframe.width(videoItem.width());
		$iframe.height(videoItem.width() / oRatio);
	} else {
		$iframe.width(videoItem.height() * oRatio);
		$iframe.height(videoItem.height());
	}
};

class VideoMgr {
	init(config) {
		if ('elementSelector' in config) {
			$(config.elementSelector).each((key, element) => {
				const widgetFParent = $(element).closest('.js-widgetContainerF');

				if (widgetFParent[0]) {
					const firstTab = widgetFParent.find('.experience-assets-widgetF1').first();

					if ($(element).parents('.experience-assets-widgetF1').is(firstTab)) {
						this.initElement($(element));
					}
				} else {
					this.initElement($(element));
				}
			});
		}
	}

	initElement($container) {
		if ($container.hasClass('playing')) {
			return;
		}

		let userPaused = false;
		let options = {
			stopOnScroll: true
		};

		const $el = $container.children('video');
		const extendedOptions = $el.data('options');

		if (extendedOptions) {
			options = $.extend({}, options, extendedOptions);
		}

		const $poster = $container.children('.js-pseudo-poster');
		let $playIcon = $container.find('.play-icon');

		if (!$playIcon.length) {
			$playIcon = $('<div class="play-icon">');
			$container.prepend($playIcon);
		}

		let $loader = $container.find('.loader-indicator');

		if (!$loader.length) {
			$loader = $('<div class="loader-indicator">');
			$container.prepend($loader);
		}

		const $volumeUpButton = $container.find('.js-volume-up');
		const $volumeDownButton = $container.find('.js-volume-down');
		const $volumeMuteButton = $container.find('.js-volume-mute');
		const $volumeBar = $container.find('.js-volume-bar');

		$volumeUpButton.on('click', () => {
			element.volumeUp();
		});
		$volumeDownButton.on('click', () => {
			element.volumeDown();
		});
		$volumeMuteButton.on('click', () => {
			element.toggleMute();
		});

		const uuid = uuidv4();
		const Adapter = getAdapterByType(($el.find('source').attr('type')));
		const callback = () => {
			$poster.hide();
		};

		const element = new Adapter($el, callback, uuid, options);

		element.subscribe({
			/**
			 * Events listeners
			 * @param updateID {number}
			 * @param data {Object}
			 */
			update: (updateID, data) => {
				switch (updateID) {
					case 'play':
						$container.addClass('playing');

						if (options.stopOnScroll) {
							$window.one('scroll fullpage.scroll.transform', () => {
								element.pause('scrollHandler');
							});
						}

						userPaused = false;
						break;

					case 'afterLocalPause':
						if (data.source === 'scrollHandler') {
							userPaused = false;
						}

						break;

					case 'pause':
						userPaused = true;
						$container.removeClass('playing');
						break;

					case 'volumeChange':
						if (data.muted) {
							$volumeBar.addClass('muted');
						} else {
							$volumeBar.removeClass('muted');
						}

						$volumeBar.width(`${data.volume * 100}%`);

						break;
					case 'playerReady':
						if (data.options.parentOptions.crop) {
							if (navigator.userAgent.match('CriOS')) {
								window.addEventListener('orientationchange', () => {
									resizeVideo(element.player.element);
								});
							} else {
								const parentCarousel = $container.closest('.js-owl_carousel');

								if (parentCarousel.length) {
									parentCarousel.on('resized.owl.carousel', () => {
										resizeVideo(element.player.element);
									});
								} else {
									window.addEventListener('resize', () => {
										resizeVideo(element.player.element);
									});
								}
							}

							resizeVideo(element.player.element);
						} else {
							if (data.options.parentOptions.resizePlayerReady) {
								resizeVideo(element.player.element);
							}

							window.addEventListener('resize', () => {
								const containerWidthElement = $container.find('.js-videowrapper');

								if (containerWidthElement.length) {
									const containerWidth = containerWidthElement.width();

									element.player.element.height *= (containerWidth / element.player.element.width);
									element.player.element.width = containerWidth;
								}
							});
						}

						break;
					default:
						return null;
				}
			}
		});

		$playIcon.click(() => {
			element.togglePlayPause();
		});

		$container.on('pauseVideo', () => {
			element.pause('pauseVideoEvent');
		});

		if (options.playPauseWhenVisible) {
			options.stopOnScroll = false;

			window.addEventListener('load', () => {
				const hiddenPart = 0.30;
				let height;
				let offset;
				let screenHeight;

				const setSizes = () => {
					height = $container.height();
					offset = $container.offset().top;
					screenHeight = screen.height;
				};

				window.addEventListener('resize', setSizes);
				setSizes();

				const handler = () => {
					if (userPaused) {
						return;
					}

					if (
						((window.scrollY + screenHeight) >= offset + (height * hiddenPart))
						&& ((window.scrollY) <= offset + (height * (1 - hiddenPart)))
					) {
						if (!element.playing) {
							element.play('scrollHandler');
						}
					} else if (element.playing) {
						element.pause('scrollHandler');
					}
				};

				handler();
				addToScrollHandler(handler);
			});
		}

		element.player.element.setAttribute('data-uuid', element.uuid);
		elements[uuid] = element;

		return elements[uuid];
	}

	getByUuid(uuid) {
		return elements.hasOwnProperty(uuid) ? elements[uuid] : null;
	}
}

const getAdapterByType = function(type) {
	switch (type) {
		case 'video/youtube':
			return YoutubeAdapter;
		case 'video/vimeo':
			return VimeoAdapter;
		default:
			throw new Error(`Adapter for type: '${type}' not found!`);
	}
};

const videoMgr = new VideoMgr();

export default videoMgr;
app.components.global.videoMgr = videoMgr;
