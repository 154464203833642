(function(app, $) {
	function CheckoutRegistration() {
		app.popups.Popup.call(this, 'checkoutregistration');
	}

	CheckoutRegistration.prototype = Object.create(app.popups.Popup.prototype);
	CheckoutRegistration.prototype.constructor = app.popups.Popup;

	CheckoutRegistration.prototype.open = function() {
		this.markup = getMarkup();
		app.popups.Popup.prototype.open.call(this);
	};

	CheckoutRegistration.prototype.close = function() {
		sessionStorage.setItem('checkoutRegistrationClosed', true);
		app.popups.Popup.prototype.close.call(this);
	};

	CheckoutRegistration.prototype.initMarkup = function() {
		this.$markup.find('.js-cancel, .js-login-guest-btn').on('click', this.close.bind(this));
		this.$markup.find('.js-registration-ajax-form').on('success', () => {
			if (app.page.type === 'orderconfirmation') {
				var $fakeLoginCTA = $('.js-fakelogin-link-cta');

				if ($fakeLoginCTA.length) {
					$fakeLoginCTA.text(app.resources.MY_ACCOUNT_CTA);
				}

				app.popupsMgr.close('checkoutRegistration');
			} else {
				app.checkout.updateCSRFToken();
			}
		});
		this.$markup.find('.js-login-ajax-form').on('success', () => {
			app.checkout.updateCSRFToken();
		});
		app.popups.Popup.prototype.initMarkup.call(this);
	};

	function getMarkup() {
		return app.util.renderTemplate($('#checkoutRegistrationPopupTemplate').html());
	}

	app.popups.checkoutRegistration = new CheckoutRegistration();
})((window.app = window.app || {}), jQuery);
