import ToggleSizeGuideUnits from 'oneapp/src/components/ToggleSizeGuideUnits';

(function(app, $) {
	var $doc = $(document);
	function SizeFlyout(config) {
		app.flyouts.Flyout.call(this, 'size', config);
		this.sizeSelected = false;
	}

	SizeFlyout.prototype = Object.create(app.flyouts.Flyout.prototype);
	SizeFlyout.prototype.constructor = app.flyouts.Flyout;

	SizeFlyout.prototype.open = function(params) {
		this.markup = getMarkup();
		this.sizeSelected = false;
		app.flyouts.Flyout.prototype.open.call(this, params);
	};

	SizeFlyout.prototype.close = function(keepFocus) {
		if (!this.sizeSelected) {
			var addToCartSelected = 'js-add-to-cart-selected-variant';
			var addToWishlistSelected = 'js-add-to-wishlist-selected-variant';
			var applePayStartOnSelected = 'js-applepay-start-selected-variant';

			$('.' + addToCartSelected).removeClass(addToCartSelected);
			$('.' + addToWishlistSelected).removeClass(addToWishlistSelected);
			$('.' + applePayStartOnSelected).removeClass(applePayStartOnSelected);
		}
		app.flyouts.Flyout.prototype.close.call(this, keepFocus);
	};

	SizeFlyout.prototype.initMarkup = function(params) {
		var $sizeSelectors = this.$markup.find('.js-swatchanchor');

		$sizeSelectors.on('click', function(e) {
			var $sizeSelector = $(e.target);
			this.sizeSelected = true;

			if (!e.target.classList.contains('js-notifyme_link')) {
				this.close(params);
			}

			if ($sizeSelector.closest('.js-swatches_size-item-selected').length > 0) {
				return false;
			}
		}.bind(this));

		if (this.$markup[0].querySelector('.js-size_guide-tables_content')) {
			new ToggleSizeGuideUnits();

			initSizeGuideInFlyout.call(this);
		}

		app.flyouts.Flyout.prototype.initMarkup.call(this, params);
	};

	function initSizeGuideInFlyout() {
		const sizeFlyout = this.$markup[0];
		const measuringFlyout = sizeFlyout.querySelector('.js-size_guide_tips');
		const sizeguideWrapper = sizeFlyout.querySelector('.js-size_chart-wrapper');
		const tipsFlyoutToggler = sizeFlyout.querySelector('.js-open-measuring_tips-flyout');
		const sizeChartToggler = sizeFlyout.querySelector('.js-size_chart-toggler');
		const sizeChartLink = sizeFlyout.querySelector('.js-size_chart_link');

		sizeChartToggler.classList.remove('h-hidden');
		sizeChartLink.classList.add('h-hidden');

		measuringFlyout.classList.add(
			'b-slide-flyout-wrapper',
			'm-animation-out'
		);

		tipsFlyoutToggler.addEventListener('click', handleSizeguideTipsFlyout);
		sizeChartToggler.addEventListener('click', function() {
			toggleSizeGuideVisibility(this, sizeguideWrapper);
		});

		function toggleSizeGuideVisibility(toggler, sizeguide) {
			const isSizeguideExpanded = (toggler.dataset.expanded === 'true');
			const isSizeguideExpandedNew = !isSizeguideExpanded;

			toggler.setAttribute('data-expanded', isSizeguideExpandedNew);
			toggler.textContent = isSizeguideExpandedNew ? toggler.dataset.expandedText : toggler.dataset.collapsedText;
			sizeguide.classList.toggle('h-minimized');
		}

		function handleSizeguideTipsFlyout() {
			measuringFlyout.classList.toggle('m-animation-out');
		}
	}

	function getMarkup() {
		return app.util.renderTemplate($('#sizeFlyoutTemplate').html());
	}

	var config = app.configs.flyoutComponent;

	app.flyouts.size = new SizeFlyout(config);
})((window.app = window.app || {}), jQuery);
