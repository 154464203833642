import VideoAdapter from '.';

const videoPoster = '.mejs-poster';
const videoPlay = '.mejs-overlay-play';
const videoOverlaySel = '.mejs-overlay';
const currentDevice = app.device.currentDevice();
const $window = $(window);

class Youtube extends VideoAdapter {
	constructor($el, callback, uuid, parentOptions = {}) {
		super(...arguments);

		this.player = init($el, (mPlayer) => {
			callback(mPlayer);
			showPosterOnTabletAndMobile();

			$(mPlayer).on('play', () => {
				$window.one('scroll fullpage.scroll.transform', () => {
					showPosterOnTabletAndMobile();
				});
				$(videoOverlaySel).one('click', this.pause.bind(this));
				this.playing = true;
				this.notify('play');
			});

			$(mPlayer).on('pause', () => {
				this.playing = false;
				showPosterOnTabletAndMobile();
				this.notify('pause');
			});
		});
	}

	play(source = null) {
		this.notify('localPlay', { source: source });
		this.player.media.play();
		setTimeout(() => {
			this.notify('afterLocalPlay', { source: source });
		}, 500);
	}

	pause(source = null) {
		this.notify('localPause', { source: source });
		this.player.media.pause();
		setTimeout(() => {
			this.notify('afterLocalPause', { source: source });
		}, 500);
	}
}

const showPosterOnTabletAndMobile = () => {
	// Inherithed from BR, may be refactored
	// Fix for mobile and tablet poster showing
	if (currentDevice === 'tablet' || currentDevice === 'mobile') {
		$(videoPoster).show();
		$(videoPlay).show();
	}
};

const init = ($el, callback) => {
	new MediaElementPlayer($el, {
		success: callback
	});
};

export default Youtube;
