import CSRF from 'oneapp/src/utils/CSRF';
import ProductTileBuilder from '../../../src/classes/productTile/Builder';
import ProductTileDirector from '../../../src/classes/productTile/Director';
import ProductTile from '../../../src/classes/productTile/ProductTile';

(function(app, $) {
	function WishlistFlyout(config) {
		app.flyouts.Flyout.call(this, 'wishlist', config);
	}

	WishlistFlyout.prototype = Object.create(app.flyouts.Flyout.prototype);
	WishlistFlyout.prototype.constructor = app.flyouts.Flyout;

	WishlistFlyout.prototype.open = function(params) {
		if (!app.wishlist.markup.isAuthorized && !app.wishlist.markup.isWishlistForNotAuthUser) {
			$(document).trigger('wishlist.notauthenticated');

			return;
		}
		if (app.configs.wishlist.hideForGuest && !app.wishlist.markup.isAuthorized && app.wishlist.markup.lineItemsQuantity === 0) {
			return;
		}
		this.markup = getMarkup();
		app.flyouts.Flyout.prototype.open.call(this, {
			...params,
			appendToElement: app.configs.wishlist.selector
		});
		$(document).trigger('wishlist.flyout.open');
	};

	WishlistFlyout.prototype.close = function(params) {
		app.flyouts.Flyout.prototype.close.call(this, params);
		$(document).trigger('wishlist.flyout.close');
	};

	WishlistFlyout.prototype.update = function(params = {}) {
		this.markup = getMarkup();
		const $container = $(app.configs.wishlist.selector);

		$container.find('.js-slide-flyout-content.js-wishlist-flyout').replaceWith(this.markup);
		this.$markup = $container.find('.js-slide-flyout-wrapper');

		app.flyouts.Flyout.prototype.update.call(this, params);
		$(document).trigger('wishlist.flyout.update');
		document.dispatchEvent(new CustomEvent('lazyload-reinit'));
	};

	WishlistFlyout.prototype.initMarkup = function(params) {
		app.flyouts.Flyout.prototype.initMarkup.call(this, params);
		initRemoveButtons.call(this);
		initAddToCartButtons.call(this);
	};

	function getMarkup() {
		if (app.wishlist.markup.lineItemsQuantity > 0) {
			return app.util.renderTemplate(
				$('#wishlistFlyoutTemplate').html(),
				{
					...app.wishlist.markup,
					productTiles: getProductTilesMarkup()
				}
			);
		} else {
			return app.util.renderTemplate(
				$('#emptyWishlistFlyoutTemplate').html(),
				{
					...app.wishlist.markup
				}
			);
		}
	}

	function getProductTilesMarkup() {
		if (Array.isArray(app.wishlist.markup.products)) {
			return app.wishlist.markup.products.map((productTile) => {
				const builder = new ProductTileBuilder(productTile);
				const director = new ProductTileDirector(builder);

				director.buildWishlistProductTile();

				return new ProductTile($('#wishlistProductTile').html(), builder.getResult());
			}).join('');
		}

		return '';
	}

	function initRemoveButtons() {
		const $wishlistRemoveButtons = this.$markup.find('.js-wishlist_flyout-remove');

		$wishlistRemoveButtons.on('click', function(e) {
			e.preventDefault();
			const $this = $(this);

			app.wishlist.removeProduct($this.data('item'), $this.data('pid'));
		});
	}

	function initAddToCartButtons() {
		const $wishlistAddToCartButtons = this.$markup.find('.js-add_to_cart_button');

		$wishlistAddToCartButtons.on('click', function(e) {
			e.preventDefault();
			const $this = $(this);
			const $document = $(document);
			const customProductsData = $this.data('customproducts');
			const pliAttrs = $this.data('custom-attrs');
			const requestData = {
				Quantity: '1',
				cartAction: 'add',
				pid: $this.data('pid')
			};

			if (typeof customProductsData === 'object' && customProductsData !== null) {
				requestData.customProducts = JSON.stringify(customProductsData);
			}
			if (pliAttrs) {
				requestData.product_data = pliAttrs;
			}

			CSRF.populatePayloadByCSRFToken(requestData)
				.then((payload) => app.cart.update(payload, function(response) {
					app.minicart.update(response);
					app.flyoutMgr.open('minicart', {
						autoClose: true
					}).then(function() {
						$document.trigger('minicart.afterload');
					});
					$document.trigger('wishlist.addedToCart', { target: $this });
					$document.trigger('tracking.cartItemAdded');
				}));
		});
	}

	var config = app.configs.flyoutComponent;

	app.flyouts.wishlist = new WishlistFlyout(config);
})((window.app = window.app || {}), jQuery);
