import ProductTileDirector from 'oneapp/src/classes/productTile/Director';
import ProductTileBuilder from 'oneapp/src/classes/productTile/Builder';
import ProductTile from 'oneapp/src/classes/productTile/ProductTile';
import ShopApi from 'oneapp/src/classes/ocapi/ShopApi';

import SearchProvider from './SearchProvider';

/**
 * Templates used in the SFCCSearchProvider.
 * @type {Object<string, string>}
 */
const templates = {
	results: 'search-results',
	popular: 'search-popular',
	popularItem: 'search-popular-item',
	hits: 'search-hits',
	hit: 'search-hit',
	phraseSuggestionItem: 'search-phrase-suggestion-item',
	phraseSuggestionItemMatch: 'search-phrase-suggestion-item-match',
	noResults: 'search-no-results',
	initial: 'search-initial'
};

/**
 * Selectors used in the SFCCSearchProvider.
 * @type {Object<string, string>}
 */
const selectors = {
	input: '.js-search-field-input',
	viewAll: '.js-search-view-all',
	content: '.js-search-content',
	close: '.js-search-close',
	phaseSuggestion: '.js-phrase-suggestion',
	hits: '.js-search-hits',
	productSuggestions: '.js-search-product-suggestions',
	noResults: '.js-search-no-results',
	popularItem: '.js-search-popular-item',
	phraseSuggestions: '.js-search-phrase-suggestions',
	variantValue: '.js-variation-value',
	hit: '.js-search-hit',
	carousel: '.jcarousel-clip'
};

const classes = {
	notifyme: 'js-notifyme-button'
};

/**
 * SFCCSearchProvider class responsible for handling search functionality.
 * @extends {SearchProvider}
 */
class SFCCSearchProvider extends SearchProvider {
	/**
	 * Creates a new instance of the SFCCSearchProvider.
	 */
	constructor() {
		super();
	}

	/**
	 * Opens the search provider and initializes necessary components.
	 */
	open() {
		super.open();
		init.call(this);
	}
}

/**
 * Initializes the SFCCSearchProvider.
 */
function init() {
	applyInitialMarkup();
	initEvents.call(this);
}

/**
 * Initializes events for search interactions.
 */
function initEvents() {
	const inputNode = document.querySelector(selectors.input);
	const closeNode = document.querySelector(selectors.close);

	inputNode.addEventListener('input', app.util.debounce(({ target }) => {
		if (target.value) {
			if (target.checkValidity()) {
				applyHitsMarkup(target.value);
			}
		} else {
			applyInitialMarkup();
		}
	}));

	closeNode.addEventListener('click', () => {
		this.close();
	});
}

/**
 * Initializes events for handling hits events.
 * @param {string} query - The search query.
 */
const initHitsEvents = (query) => {
	const viewAllNodes = document.querySelectorAll(selectors.viewAll);

	for (const viewAllNode of viewAllNodes) {
		viewAllNode.addEventListener('click', () => {
			window.location.assign(`${app.urls.searchShow}?q=${query}`);
		});
	}

	const hitsNodes = document.querySelectorAll(selectors.hit);

	for (const hitNode of hitsNodes) {
		app.components.product.custom.initProductSlider($(hitNode));

		const variationValuesNodes = hitNode.querySelectorAll(selectors.variantValue);

		for (const variationValueNode of variationValuesNodes) {
			variationValueNode.addEventListener('click', (e) => {
				if (variationValueNode.classList.contains(classes.notifyme)) {
					app.product.openNotifyMePopup.call(e.target, e);
				} else {
					window.location.assign(variationValueNode.dataset.href);
				}
			});
		}
	}
};

/**
 * Initializes events for handling phase suggestion events.
 */
const initPhaseSuggestionEvents = () => {
	const phaseSuggestionNodes = document.querySelectorAll(selectors.phaseSuggestion);

	for (const phaseSuggestionNode of phaseSuggestionNodes) {
		phaseSuggestionNode.addEventListener('click', () => {
			updateSearchQuery(phaseSuggestionNode.dataset.suggestion);
		});
	}
};

/**
 * Initializes events for handling popular search item events.
 */
const initPopularSearchEvents = () => {
	const popularSearchesNodes = document.querySelectorAll(selectors.popularItem);

	for (const popularSearchesNode of popularSearchesNodes) {
		popularSearchesNode.addEventListener('click', () => {
			updateSearchQuery(popularSearchesNode.dataset.phrase);
		});
	}
};

/**
 * Updates the search query in the input field and applies hits markup.
 * @param {string} query - The new search query.
 */
const updateSearchQuery = (query) => {
	const inputNode = document.querySelector(selectors.input);

	inputNode.value = query;

	applyHitsMarkup(inputNode.value);
};

/**
 * Applies hits markup based on the provided query.
 * @param {string} query - The search query.
 */
const applyHitsMarkup = (query) => {
	const contentNode = document.querySelector(selectors.content);
	const resultsTemplate = document.getElementById(templates.results);

	contentNode.innerHTML = app.util.renderTemplate(resultsTemplate.innerHTML);

	ShopApi.getSearchSuggestion({ q: query }).then((suggestions) => {
		const phraseSuggestionsNode = document.querySelector(selectors.phraseSuggestions);

		if (suggestions?.length) {
			const phraseSuggestionsMarkup = getPhraseSuggestionsMarkup(suggestions, query);

			phraseSuggestionsNode.innerHTML = phraseSuggestionsMarkup;

			initPhaseSuggestionEvents();
		}

		phraseSuggestionsNode?.classList?.toggle('h-hidden', !suggestions?.length);
	});

	ShopApi.getProductSearch({ q: query, variation_attributes: 'size' }).then((data) => {
		const { total: count, hits } = data;

		if (count) {
			const hitsTemplate = document.getElementById(templates.hits);
			const hitsNode = document.querySelector(selectors.hits);
			const hitsBlock = getHitsMarkup(hits);

			hitsNode.innerHTML = app.util.renderTemplate(hitsTemplate.innerHTML, { count, hitsBlock });

			initHitsEvents(query);
		} else {
			const noResultsTemplate = document.getElementById(templates.noResults);

			contentNode.innerHTML = noResultsTemplate.innerHTML;

			loadRecommendations(contentNode);
		}
	});

	document.dispatchEvent(new CustomEvent('searchResult.loaded', {detail: query}));
};

/**
 * Applies initial markup for the search provider.
 */
const applyInitialMarkup = () => {
	const contentNode = document.querySelector(selectors.content);

	buildInitialMarkup().then((markup) => {
		contentNode.innerHTML = markup;

		initPopularSearchEvents();
		loadRecommendations(contentNode);
	});
};

/**
 * Loads recommendations into a specified container
 * @param {HTMLElement} containerNode - The container node where recommendations will be loaded.
 */
const loadRecommendations = (containerNode) => {
    reExecuteScripts(containerNode);

    const carouselNode = containerNode.querySelector(selectors.carousel);

	if (carouselNode) {
		app.owlcarousel.initCarousel(carouselNode);
	}

    document.dispatchEvent(new CustomEvent('lazyload-reinit'));
};


/**
 * Re-executes scripts in the specified container by replacing script tags.
 * @param {HTMLElement} containerNode - The container node containing scripts to be re-executed.
 */
const reExecuteScripts = (containerNode) => {
	const originScripts = containerNode.getElementsByTagName('script');

	for (const originScript of originScripts) {
		const newScript = document.createElement('script');

		newScript.text = originScript.innerHTML;
		originScript.replaceWith(newScript);
	}
};

/**
 * Builds the initial markup for the search provider.
 * @returns {Promise<string>} A promise that resolves with the markup.
 */
const buildInitialMarkup = () => {
	return new Promise((resolve) => {
		fetchPopularSearch().then(({ items }) => {
			const template = document.getElementById(templates.initial);
			const popularSearchesBlock = getPopularSearchMarkup(items);
			const markup = app.util.renderTemplate(template.innerHTML, { popularSearchesBlock });

			resolve(markup);
		});
	});
};

/**
 * Fetches popular search items.
 * @returns {Promise<object>} A promise that resolves with the popular search items.
 */
const fetchPopularSearch = () => {
	return new Promise((resolve) => {
		fetch(app.urls.searchPopularSearches, { method: 'GET' })
			.then((res) => res.json())
			.then((res) => resolve(res));
	});
};

/**
 * Generates markup for phrase suggestions.
 * @param {Array<string>} phrases - Array of suggested phrases.
 * @returns {string} Markup for phrase suggestions.
 */
const getPhraseSuggestionsMarkup = (phrases, query) => {
	const phraseSuggestionItemTemplate = document.getElementById(templates.phraseSuggestionItem);
	let markup = '';

	for (const phrase of phrases) {
		markup += app.util.renderTemplate(phraseSuggestionItemTemplate.innerHTML, {
			phrase: getPhraseWithMatch(phrase, query),
			suggestion: phrase
		});
	}

	return markup;
};

const getPhraseWithMatch = (phase, query) => {
	const phraseSuggestionItemMatchTemplate = document.getElementById(templates.phraseSuggestionItemMatch);

	return phase.replace(query, (match) => app.util.renderTemplate(phraseSuggestionItemMatchTemplate.innerHTML, { match }));
};

/**
 * Generates markup for popular search items.
 * @param {Array<string>} items - Array of popular search items.
 * @returns {string} Markup for popular search items.
 */
const getPopularSearchMarkup = (items) => {
	let itemsMarkup = '';

	if (!items.length) {
		return itemsMarkup;
	}

	const popularSearchesItemTemplate = document.getElementById(templates.popularItem);
	const popularSearchesTemplate = document.getElementById(templates.popular);

	for (const { phrase } of items) {
		itemsMarkup += app.util.renderTemplate(popularSearchesItemTemplate.innerHTML, { phrase });
	}

	return app.util.renderTemplate(popularSearchesTemplate.innerHTML, { itemsMarkup });
};

/**
 * Generates markup for search hits.
 * @param {Array<object>} hits - Array of search hits.
 * @returns {string} Markup for search hits.
 */
const getHitsMarkup = (hits) => {
	let hitsBlock = '';

	for (const hit of hits) {
		const firstOrderableVariant = hit.variationAttributes.size.find((el) => el.isOrderable);

		hitsBlock += getProductTileMarkup({
			...hit,
			addToWishlist: {
				pid: firstOrderableVariant ? firstOrderableVariant.variantId : hit.id,
				url: `${app.urls.wishlistAddAbs}?pid=${hit.id}`
			}
		});
	}

	return hitsBlock;
};

/**
 * Generates the markup for a product tile.
 * @param {Object} productTile - The product tile object.
 * @returns {string} The markup for the product tile.
 */
const getProductTileMarkup = (productTile) => {
	const builder = new ProductTileBuilder(productTile);
	const director = new ProductTileDirector(builder);
	const template = document.getElementById(templates.hit);

	director.buildSearchProductTile();

	return new ProductTile(template.innerHTML, builder.getResult());
};

export default SFCCSearchProvider;
