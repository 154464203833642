import SearchMgr from 'oneapp/src/classes/search/SearchMgr';

(function(app, $) {
	var $cache = {};
	var previousNavigationHeight;

	function initializeCache() {
		$cache = {
			document: $(document),
			window: $(window),
			body: $('body'),
			header: $('header'),
			mainHeader: app.device.isMobileView() ? $('.js-mobile-header_main') : $('.js-header_main'),
			headerServiceMenuSel: app.device.isMobileView() ? '.js-header_service_menu' : '.js-header_main-content',
			headerIcons: $('.js-search-icon, .js-mini_cart-title'),
			minicartTitle: $('.js-mini_cart-title'),
			minicartQuantityValue: $('.js-minicart-quantity_value'),
			minicartQuantityValueSel: '.js-minicart-quantity_value',
			wishlistTitle: $('.js-wish_list-title'),
			wishlistQuantityValue: $('.js-wishlist-quantity_value'),
			leftHeaderNavigation: $('.l-main_navigation'),
			mainNavigationBlock: $('.js-main_navigation'),
			mainNavigationClass: 'js-main_navigation',
			navigationCarouselSel: '.js-owl_carousel_navigation',
			menuButton: $('.js-vertical_menu-button'),
			menuButtonClass: 'js-vertical_menu-button',
			menuCategoryLvl3: $('.b-menu_category-level_3'),
			menuCategoryLvl3Sel: '.b-menu_category-level_3',
			tabletHeaderMenuNav: $('.tablet-header-menu-nav'),
			headerSearchInput: $('.js-quicksearch'),
			promoBanner: $('.js-header-promo-container'),
			refinementsSticky: $('.js-refinements-sticky'),
			breadcrumbsBlock: $('.js-breadcrumbs'),
			closePromoButton: $('.js-header-promo-close'),
			productDetailsWrapper: $('.js-product-details-wrapper'),
			toggler: $('.js-toggler'),
			toggledClassName: 'blured-placeholder',
			promoBannerClass: 'with-promo',
			slideEffect: 'easeInExpo',
			headerChangeEvent: 'header.change',
			hMinimizedClass_hHiddenClass: 'h-minimized h-hidden',
			owlCarouselSel: '.js-owl_carousel',
			minimizedHeaderClass: 'b-minimized_header',
			menuActiveClass: 'm-active',
			activeClass: 'active',
			hHiddenClass: 'h-hidden',
			stickyKitStuckSel: 'is_stuck',
			promoBannerCookieName: app.preferences.promoBannerCookieName,
			miniCartWrapper: $('.js-mini_cart'),
			wishlistWrapper: $('.js-wish_list'),
			hMinimizedClass: 'h-minimized',
			hToggledClass: 'h-toggled',
			promoHideTimeout: 1 * 60 * 60 * 1000, // Hours * minutes * seconds * milliseconds
			shippingInfoIconSel: '.js-shipping-info-icon',
			shippingInfoContentSel: '.js-shipping-info-content',
			isMobileView: app.device.isMobileView(),
			headerFlyoutContainer: $('.js-header-flyout-container'),
			flyoutWrapperSelector: '.js-slide-flyout-wrapper',
			menuOpenedClass: 'm-menu_opened',
			searchButtonSelector: '.js-search-icon',
			menuButtonsSelector: '.js-login_dropdown-title, .js-wish_list-title, .js-mini_cart-title'
		};
		$cache.event = {
			headerChange: 'header.change',
			toggleHideall: 'toggle.hideall'
		};
	}

	function initializeDOM() {
		previousNavigationHeight = $cache.mainNavigationBlock.height();
		initPromoBanner();
		initSearch();
	}

	const initSearch = () => {
		const searchProvider = SearchMgr.getProvider();

		if (searchProvider) {
			$cache.document.on('click', $cache.searchButtonSelector, function() {
				searchProvider.open();
			});

			const menuButtonsNodes = document.querySelectorAll($cache.menuButtonsSelector);

			for (const menuButtonNode of menuButtonsNodes) {
				menuButtonNode.addEventListener('click', () => {
					searchProvider.close();
				});
			}

			$cache.header.on('header.change', function() {
				searchProvider.close();
			});
		}
	};

	function getPromoBannerHeight() {
		return parseInt($cache.promoBanner.outerHeight());
	}

	function getServiceMenuHeight() {
		return parseInt($($cache.headerServiceMenuSel).outerHeight());
	}

	function adaptBodyPadding() {
		var headerHeight = $cache.mainHeader.height();

		$cache.body.css('padding-top', headerHeight + 'px');
		document.documentElement.style.setProperty('--header-height', headerHeight + 'px');
		updateHeaderCustomVariables();
	}

	function updateHeaderCustomVariables() {
		const promoBannerHeight = getPromoBannerHeight();
		const serviceMenuHeight = getServiceMenuHeight();

		document.documentElement.style.setProperty('--promo_banner-height', promoBannerHeight + 'px');
		document.documentElement.style.setProperty('--service_menu-height', serviceMenuHeight + 'px');
	}

	function initPromoBanner() {
		if (!$.cookie($cache.promoBannerCookieName)) {
			$cache.promoBanner.removeClass($cache.hMinimizedClass_hHiddenClass);
			$cache.productDetailsWrapper.addClass($cache.promoBannerClass);
		} else {
			$cache.promoBanner.addClass($cache.hMinimizedClass_hHiddenClass);
		}

		$cache.closePromoButton.on('click', function() {
			var expiration = new Date();

			$cache.promoBanner.addClass($cache.hMinimizedClass_hHiddenClass);
			$cache.productDetailsWrapper.removeClass($cache.promoBannerClass);

			expiration.setTime($cache.promoHideTimeout + expiration.getTime());
			$.cookie($cache.promoBannerCookieName, true, { expires: expiration, path: '/' });

			$cache.header.trigger($cache.event.headerChange, { height: getPromoBannerHeight() });
			updateHeaderCustomVariables();
		});
	}

	function initShippingInfoPopup() {
		if (app.device.isMobileView()) {
			$cache.document.on('click', $cache.shippingInfoIconSel, function() {
				const $this = $(this);
				const $shippingInfo = $($cache.shippingInfoContentSel);

				if ($shippingInfo.length > 0) {
					app.fancybox.open($this, {
						content: $shippingInfo.html(),
						wrapCSS: 'shipping-info-popup',
						autoSize: false,
						afterClose: function() {
							$cache.document.trigger('product.added');
						}
					});
				}
			});
		} else {
			$cache.document.on('mouseenter mouseleave', $cache.shippingInfoIconSel, function(e) {
				const $shippingInfo = $($cache.shippingInfoContentSel);

				if ($shippingInfo.length > 0) {
					$shippingInfo.parent().toggleClass($cache.hHiddenClass, e.type !== 'mouseenter');
				}
			});
		}
	}

	function adaptBodyPosition() {
		var navigationHeight = $cache.mainNavigationBlock.height();

		if (navigationHeight && previousNavigationHeight !== navigationHeight) {
			$cache.header.trigger('update.header', 0);
		}

		previousNavigationHeight = navigationHeight;
	}

	function initNavCarousels() {
		const $navCarousels = $(`.${$cache.mainNavigationClass}`).find($cache.navigationCarouselSel);

		$navCarousels.each((index, carousel) => {
			app.owlcarousel.initCarousel($(carousel));
		});
	}

	function initializeEvents() {
		$cache.document.on('body-padding-updated', function() {
			adaptBodyPadding();
			$cache.document.trigger('scroll');
		});

		$cache.headerIcons.on('click', function() {
			$cache.header.trigger('header.change', {
				headerHeight: $cache.header.height()
			});
		});

		$cache.document.on('scroll', function() {
			if ($cache.header.length) {
				toggleMinimizedHeader();
				updateHeaderCustomVariables();
			}
		});

		$cache.document.on('body.changed', function() {
			initNavCarousels();
		});

		initMiniCartFlyOutEvents();
		initWishListFlyOutEvents();
		initFlyOutEvents();

		if ($cache.mainHeader.length && $cache.mainHeader.css('position') === 'fixed') {
			new ResizeSensor($cache.mainHeader.get(0), function() {
				adaptBodyPosition();
				adaptBodyPadding();
			});

			$cache.window.resize(function() {
				adaptBodyPosition();
				adaptBodyPadding();
			});
		}

		// show|hide tablet menu with categories & customer services|contact links, hide 3rd level categories
		$cache.menuButton.on('click', function() {
			$cache.document.trigger($cache.event.toggleHideall);
			$cache.header.trigger($cache.event.headerChange);

			$cache.menuButton.toggleClass($cache.menuActiveClass);
			$cache.body.toggleClass($cache.menuOpenedClass);
			$cache.tabletHeaderMenuNav.toggleClass($cache.hHiddenClass);
			$cache.document.find($cache.menuCategoryLvl3).toggleClass($cache.hHiddenClass);

			if ($cache.leftHeaderNavigation.css('display') === 'none') {
				$cache.leftHeaderNavigation.toggleClass($cache.menuActiveClass);

				if (!app.device.isMobileView()) {
					app.components.global.navigation.adjustLeftNavHeight();
				}
			} else if ($cache.leftHeaderNavigation.css('display') === 'block') {
				$cache.leftHeaderNavigation.toggleClass($cache.menuActiveClass);
				$cache.document.find($cache.menuCategoryLvl3).removeClass($cache.hHiddenClass);
			}
		});

		// add specific class to header when scrolling position is higher then 0
		function toggleMinimizedHeader() {
			$cache.header.toggleClass($cache.minimizedHeaderClass, $cache.window.scrollTop() > 0);
			var headerHeight = $cache.header.height();
			var breadcrumbsHeight = $cache.breadcrumbsBlock.height() || 0;
			var bodyPaddingTop = headerHeight + breadcrumbsHeight;

			$cache.refinementsSticky.css('padding-top', $cache.refinementsSticky.hasClass($cache.stickyKitStuckSel) ? bodyPaddingTop + 'px' : '0');
		}

		$cache.headerSearchInput.on('focus blur', function() {
			var $this = $(this);

			$this.toggleClass($cache.toggledClassName, $this.val() === $this.attr('placeholder'));
		});

		// navigation menu is closing, if click outside on tablet
		$cache.document.on('click', hideNavigation);
		$cache.toggler.on('click', hideNavigation); // to bypass toggler's e.stopPropagation()

		initShippingInfoPopup();

		function hideNavigation(e) {
			if (($cache.menuButton.hasClass($cache.menuActiveClass)) && !($(e.target).hasClass($cache.mainNavigationClass)) && !($(e.target).parents(`.${$cache.mainNavigationClass}.${$cache.menuActiveClass}`).length || $(e.target).hasClass($cache.menuButtonClass))) {
				e.preventDefault();
				$cache.menuButton.toggleClass($cache.menuActiveClass);
				$cache.tabletHeaderMenuNav.toggleClass($cache.hHiddenClass);
				$cache.leftHeaderNavigation.toggleClass($cache.menuActiveClass);
				$cache.document.find($cache.menuCategoryLvl3Sel).removeClass($cache.hHiddenClass);
				$cache.body.removeClass($cache.menuOpenedClass);
			}
		}
	}

	function initMiniCartFlyOutEvents() {
		if (!$cache.isMobileView) {
			$cache.minicartTitle.on('click', function() {
				if (!app.flyoutMgr.isOpen('minicart')) {
					app.flyoutMgr.open('minicart');
					$cache.document.trigger('minicart.afterload');
				} else {
					app.flyoutMgr.close('minicart');
				}

				return false;
			});

			$cache.document.on('minicart.flyout.open', function() {
				openFlyoutHandler($cache.miniCartWrapper);
			});

			$cache.document.on('minicart.flyout.closed', function() {
				$cache.miniCartWrapper.find($cache.flyoutWrapperSelector).removeClass($cache.activeClass);
			});
		}
	}

	function initWishListFlyOutEvents() {
		if (!$cache.isMobileView) {
			$cache.wishlistTitle.on('click', function() {
				if (!app.flyoutMgr.isOpen('wishlist')) {
					app.flyoutMgr.open('wishlist');
					$cache.document.trigger('wishlist.afterload');
				} else {
					app.flyoutMgr.close('wishlist');
				}

				return false;
			});

			$cache.document.on('wishlist.flyout.open', function() {
				openFlyoutHandler($cache.wishlistWrapper);
			});

			$cache.document.on('wishlist.flyout.closed', function() {
				$cache.wishlistWrapper.find($cache.flyoutWrapperSelector).removeClass($cache.activeClass);
			});
		}
	}

	function initFlyOutEvents() {
		if (!$cache.isMobileView) {
			$cache.document.on('flyout.animationend', function() {
				app.components.toggler.custom.updateBodyPaddingTop($cache.header.height());
			});

			$cache.document.on('toggle.closed', function() {
				if (app.components.toggler.custom.areTogglesClosed()) {
					$cache.headerFlyoutContainer.find($cache.flyoutWrapperSelector).addClass($cache.activeClass);
				}
			});
		}
	}

	/**
	* @function
	* @description opens flyout if toggler header components are closed
	* @param {jQuery} $flyoutWrapper - current flyout wrapper
	*/
	function openFlyoutHandler($flyoutWrapper) {
		if (app.components.toggler.custom.areTogglesClosed()) {
			$flyoutWrapper.find($cache.flyoutWrapperSelector).addClass($cache.activeClass);
		} else {
			$cache.document.trigger('toggle.hideall', {
				srcFlyout: true
			});
		}
	}

	function updateMinicartQuantity(quantity) {
		const $minicartQuantityValueEl = $($cache.minicartQuantityValueSel);

		if ($cache.minicartTitle.length && $minicartQuantityValueEl.length) {
			$cache.minicartTitle.removeClass('h-click-disable');
			const quantityTemplate = app.configs.minicart.quantityTemplate;
			const renderedTemplate = app.util.renderTemplate(quantityTemplate, {
				quantity: quantity.toString()
			});

			$minicartQuantityValueEl.text(renderedTemplate);

			if (quantity === 0 && !app.configs.minicart.showZeroValue) {
				$minicartQuantityValueEl.parent().addClass($cache.hHiddenClass);
			} else {
				$minicartQuantityValueEl.parent().removeClass($cache.hHiddenClass);
			}
		}
	}

	function update(updateID, data) {
		switch (updateID) {
			case 'updateMinicartQuantity':
				updateMinicartQuantity(data.quantity);
				break;

			case 'updateWishlistQuantity': {
				const $wishlistQuantityValue = $($cache.wishlistQuantityValue);

				app.wishlist.updateWishlistQuantity({
					quantity: data.quantity,
					wishlistTitleCtnr: $($cache.wishlistTitle),
					wishlistQuantityCtnr: $wishlistQuantityValue.parent(),
					wishlistQuantityValueCtnr: $wishlistQuantityValue
				});
				break;
			}
			default:
				break;
		}
	}

	/* app.components.global.header public object */
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.header = {
		init: function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
			app.minicart.subscribe(this);
			app.wishlist.subscribe(this);
		},
		update: update
	};
})(window.app = window.app || {}, jQuery);
