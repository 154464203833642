(function(app, $) {
	var $cache = {};
	var configs = {};

	var contentNumber = 0;
	var currentScrollPosition = [];

	function initializeConfigs() {
		configs = {
			numberToDisplay: 2
		};
	}

	function initializeCache() {
		var pageName = $('.js-journalpage-main').data('pagename');

		$cache = {
			window: $(window),
			document: $(document),
			slotWrappers: $("div[class^='journal-slot']"),
			journalContainer: '.js-journalpage-main',
			journalFooter: $('body.s-' + pageName + ' .l-footer'),
			hiddenClass: 'h-hidden_minor',
			loadingClass: 'loading-slots',
			slotWrappersSelector: "div[class^='journal-slot']",
			errorNotifierClass: '.error_notifier',
			videoTagSel: '.js-slot-video',
			initedClass: 'js-initedVideo',
			tabletOrMobileDevice: app.device.isTabletUserAgent() || app.device.isMobileUserAgent()
		};

		$cache.mobileOffset = 0;

		if ($cache.tabletOrMobileDevice) {
			if (app.device.currentDevice() === 'mobile') {
				$cache.mobileOffset = 200;
			} else {
				$cache.mobileOffset = app.device.isAndroidOS() ? 150 : 70;
			}
		}
	}

	function initializeDOM() {
		// in case the video is on the top, in purpose do not init it twice
		var videoBlock = $($cache.videoTagSel + ':not(".' + $cache.initedClass + '")');

		videoBlock.addClass($cache.initedClass);
		videoBlock.closest('div' + $cache.videoTagSel).addClass($cache.initedClass);

		if ($cache.journalFooter.length) {
			if ($cache.slotWrappers.length > configs.numberToDisplay) {
				markSections();
				checkViewPortSize();
			}
		}
	}

	function checkViewPortSize() {
		var lastVisibleSlot = $($cache.slotWrappersSelector).not('.' + $cache.loadingClass).last();
		var slotTop = lastVisibleSlot.offset().top;
		var slotBottom = slotTop + lastVisibleSlot.outerHeight();
		var viewPortTop = $cache.window.scrollTop();
		var viewPortBottom = viewPortTop + $cache.window.height();

		if (slotBottom < viewPortBottom) {
			var slotIndex = contentNumber++;

			getRemoteContent($cache.slotWrappers.eq(slotIndex), true);
		}
	}

	function markSections() {
		contentNumber += configs.numberToDisplay;
		$cache.slotWrappers.eq(contentNumber - 1).nextAll($cache.slotWrappersSelector).addClass($cache.loadingClass);

		// hiding footer
		$cache.journalFooter.addClass($cache.hiddenClass);
	}

	function initializeEvents() {
		if ($cache.journalFooter.length && $cache.slotWrappers.length > configs.numberToDisplay) {
			$cache.window.on('scroll.journal', function() {
				if ($cache.window.scrollTop() + $cache.window.height() >= ($cache.document.height() - $cache.mobileOffset)) {
					if ($cache.tabletOrMobileDevice && currentScrollPosition.length) {
						if (
							!($cache.window.scrollTop() + $cache.window.height() >= currentScrollPosition[currentScrollPosition.length - 1].topPositionSegment
							&& $cache.window.scrollTop() + $cache.window.height() <= currentScrollPosition[currentScrollPosition.length - 1].bottomPositionSegment)
						) {
							revealContent();
						}
					} else {
						revealContent();
					}
				}
			});

			if ($cache.tabletOrMobileDevice) {
				$cache.window.resize(checkViewPortSize);
			}
		}
	}

	function revealContent() {
		var endPointNumber = contentNumber + configs.numberToDisplay;
		var mobileCorrection;

		if (endPointNumber > $cache.slotWrappers - 1) {
			endPointNumber = $cache.slotWrappers.length - 1;
		}

		$cache.window.trigger('resize');

		for (var i = contentNumber; i < endPointNumber; i++) {
			getRemoteContent($cache.slotWrappers.eq(i));
		}

		contentNumber = endPointNumber;

		if ($cache.tabletOrMobileDevice) {
			mobileCorrection = (app.device.currentDevice() === 'mobile') ? 200 : 0;
			currentScrollPosition.push({
				topPositionSegment: $cache.document.height() - $cache.mobileOffset,
				bottomPositionSegment: $cache.document.height() + mobileCorrection
			});
		}
	}

	function getRemoteContent(currentSlot, checkViewPortFlag) {
		var configBlock = currentSlot.children('.config_content-block');
		var assetIDs = configBlock.data('assetIds');
		var decoratorTemplate = configBlock.data('decoratorTemplate');
		var remoteContentObject = null;
		var isTheEndOfDocument = false;

		$.ajax({
			url: app.util.appendParamsToUrl(app.urls.slotsCluster, {
				assetIDs: assetIDs,
				decoratorTemplate: decoratorTemplate
			}),
			method: 'get',
			dataType: 'html',
			beforeSend: function() {
				if (checkViewPortFlag == null) {
					app.progress.show($cache.journalContainer);
				}
			},
			complete: function() {
				app.progress.hide();
			},
			success: function(remoteContent) {
				currentSlot.removeClass($cache.loadingClass);
				configBlock.remove();
				remoteContentObject = $(remoteContent);

				if (!remoteContentObject.closest($cache.errorNotifierClass).data('error')) {
					currentSlot.html(remoteContentObject);

					// init the video if the block exists
					$cache.window.trigger('storefrontvideo.init', [$cache.initedClass]);
					isTheEndOfDocument = monitorTheEndOfDocument();

					if (checkViewPortFlag !== null && !isTheEndOfDocument) {
						checkViewPortSize();
					}
				}
			}
		});
	}

	function monitorTheEndOfDocument() {
		if ($($cache.slotWrappersSelector).not('.' + $cache.loadingClass).length === $cache.slotWrappers.length) {
			app.progress.hide();
			$cache.journalFooter.removeClass($cache.hiddenClass);

			$cache.window.off('.journal');

			return true;
		}
	}

	app.components = app.components || {};
	app.components.loadmore = app.components.loadmore || {};
	app.components.loadmore.custom = {
		init: function() {
			initializeConfigs();
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
