import ImgLazyLoad from '../libs/lazyload';

var lazyLoad = new ImgLazyLoad({
	cancel_on_exit: false
});

document.addEventListener('lazyload-reinit', function() {
	lazyLoad.update();
});

document.addEventListener('lazyload-loadAll', function() {
	lazyLoad.loadAll();
});

document.addEventListener('DOMContentLoaded', function() {
	document.dispatchEvent(new CustomEvent('lazyload-reinit'));

	(function($) {
		$(document).on('recommendations.loaded wishlist.uploaded nextpage-loaded', function() {
			document.dispatchEvent(new CustomEvent('lazyload-reinit'));
		});
	})(jQuery);
});
