class Builder {
	constructor(productTile) {
		this.productTile = productTile;
	}

	buildRemoveButton() {
		if (this.productTile.removeButton) {
			this.productTile.removeButtonBlock = app.util.renderTemplate(
				$('#productTileRemoveButton').html(),
				this.productTile.removeButton
			);
		} else {
			this.productTile.removeButtonBlock = '';
		}
	}

	buildRemoveWishlistButton() {
		if (this.productTile.removeButton) {
			this.productTile.removeButtonBlock = app.util.renderTemplate(
				$('#productTileRemoveWishlistButton').html(),
				this.productTile.removeButton
			);
		} else {
			this.productTile.removeButtonBlock = '';
		}
	}

	buildAddtoWishlistBlock() {
		if (this.productTile.addToWishlist) {
			this.productTile.addToWishlistBlock = app.util.renderTemplate(
				$('#productTileAddToWishlist').html(),
				this.productTile.addToWishlist
			);
		} else {
			this.productTile.addToWishlistBlock = '';
		}
	}

	buildWishlistAddToCartBlock() {
		let templateSelector = null;
		const availableBtnOnly = app.configs.wishlist.permanentShowInStockBtn;

		if (this.productTile.isGiftCert && !availableBtnOnly) {
			templateSelector = '#wishlistProductTileGiftAddToCart';
		} else if (this.productTile.isNotifyMe && !availableBtnOnly) {
			templateSelector = '#wishlistProductTileNotifyMe';
		} else if (this.productTile.isAvailable || availableBtnOnly) {
			templateSelector = '#wishlistProductTileAddToCart';
		}
		if (templateSelector) {
			this.productTile.addToCartBlock = app.util.renderTemplate(
				$(templateSelector).html(),
				{
					...this.productTile.addToCart,
					priceBlock: this.productTile.priceBlock
				}
			);
		} else {
			this.productTile.addToCartBlock = '';
		}
	}

	buildSliderBlock() {
		const linkProductTileImageTemplate = document.getElementById('linkProductTileImageBlock');
		const productTileImageTemplate = document.getElementById('productTileImageBlock');
		const productTileSliderTemplate =  document.getElementById('productTileSliderBlock');
		let slidesBlock = '';

		if (this.productTile.images?.length) {
			for (const image of this.productTile.images) {
				const imageBlock = app.util.renderTemplate(productTileImageTemplate.innerHTML, image);

				slidesBlock += app.util.renderTemplate(linkProductTileImageTemplate.innerHTML, {
					imageBlock,
					url: this.productTile.productLink,
					title: this.productTile.name,
					ariaLabel: this.productTile.ariaLabel
				});
			}
		}

		this.productTile.sliderBlock =  app.util.renderTemplate(productTileSliderTemplate.innerHTML, { slidesBlock });
	}

	buildImageBlock() {
		let imageBlockHTML = '';

		if (this.productTile.isCustomized && this.productTile.customizedProductImages) {
			if (this.productTile.customizedProductImages.length > 1) {
				for (const customizedSide of this.productTile.customizedProductImages) {
					imageBlockHTML += app.util.renderTemplate(
						$('#productTileImageBlock').html(),
						customizedSide
					);
				}
			} else {
				imageBlockHTML = app.util.renderTemplate(
					$('#productTileImageBlock').html(),
					this.productTile.customizedProductImages[0]
				);
			}
		} else {
			imageBlockHTML = app.util.renderTemplate(
				$('#productTileImageBlock').html(),
				this.productTile.image
			);
		}

		this.productTile.imageBlock = imageBlockHTML;
	}

	buildImageBlockWithLink() {
		this.buildImageBlock();
		this.productTile.imageBlock = app.util.renderTemplate(
			$('#linkProductTileImageBlock').html(),
			{
				imageBlock: this.productTile.imageBlock,
				url: this.productTile.productLink,
				title: this.productTile.name,
				ariaLabel: this.productTile.ariaLabel
			}
		);
	}

	buildAvailabilityBlock() {
		const mapStatusWithCssClass = {
			notAvailibleInStore: 'notavailable',
			preorder: 'on-order',
			backorder: 'on-order'
		};

		if (this.productTile.availability && this.productTile.availability.status !== 'inStock') {
			if (Object.prototype.hasOwnProperty.call(mapStatusWithCssClass, this.productTile.availability.status)) {
				this.productTile.availability.cssClass = mapStatusWithCssClass[this.productTile.availability.status];
			}

			this.productTile.availabilityBlock = app.util.renderTemplate(
				$('#productTileAvailability').html(),
				this.productTile.availability
			);
		} else {
			this.productTile.availabilityBlock = '';
		}
	}

	buildLowInStockBlock() {
		if (this.productTile.lowInStockMsg) {
			this.productTile.lowInStockMessage = app.util.renderTemplate(
				$('#lowInStockMessage').html(),
				{ message: this.productTile.lowInStockMsg }
			);
		} else {
			this.productTile.lowInStockMessage = '';
		}
	}

	buildNameBlock(tmplName) {
		if (!this.productTile.isBonusProduct) {
			this.productTile.nameBlock = app.util.renderTemplate(
				$(tmplName || '#productTileName').html(),
				this.productTile
			);
		} else {
			this.productTile.nameBlock = this.productTile.name;
		}
	}

	buildPriceBlock() {
		if (!this.productTile.isBonusProduct) {
			const prices = this.productTile.prices;
			const quantityText = prices.showTotalNumber ? this.productTile.qty + '&nbsp;&times;' : '';
			const applicablePriceClass = prices.hideStandardPriceIfSalesAvailable ? 'h-hidden' : 'b-product_price-standard--line_through';
			const standardPriceContent = (!prices.salesPrice ? quantityText : '') + prices.standardPrice;
			const salePriceContent = (prices.hideStandardPriceIfSalesAvailable ? quantityText : '') + prices.salesPrice;

			this.productTile.priceBlock = app.util.renderTemplate(
				$('#productTilePrice').html(),
				{
					standardPrice: standardPriceContent,
					salesPrice: prices.salesPrice ? salePriceContent : '',
					discountPercent: prices.discountPercent || '',
					standardPriceClass: prices.salesPrice ? applicablePriceClass : '',
					discountPercentClass: (prices.hidePricePercent || !prices.salesPrice || !prices.discountPercent) ? 'h-hidden' : '',
					salesPriceClass: !prices.salesPrice ? 'h-hidden' : ''
				}
			);
		} else {
			this.productTile.priceBlock = '';
		}
	}

	buildBadgesBlock() {
		var badgesBlockHTML = '';

		if (this.productTile.badges?.length) {
			for (const badge of this.productTile.badges) {
				badgesBlockHTML += app.util.renderTemplate(
					$('#productTileBadge').html(),
					{
						badge
					}
				);
			}
		}

		this.productTile.badgesBlock = badgesBlockHTML;
	}

	buildSizeBlock() {
		if (!this.productTile.quantitySelector) {
			this.productTile.sizeBlock = app.util.renderTemplate(
				$('#sizeInfo').html(),
				{
					size: this.productTile.size
				}
			);
		}
	}

	buildVariationAttributesBlock() {
		const variationObject = this.productTile.variationAttributes ?? {};
		const attributeTemplate = document.getElementById('productTileVariationAttribute');
		const valueTemplate = document.getElementById('productTileVariationValue');
		let variationAttributesBlock = '';

		for (const [id, variations] of Object.entries(variationObject)) {
			let valuesBlock = '';

			for (const variation of variations) {
				const additionalClasses = !variation.isOrderable ? 'b-variation-value--notifyme js-notifyme-button' : '';

				valuesBlock += app.util.renderTemplate(valueTemplate.innerHTML, { ...variation, additionalClasses });
			}

			variationAttributesBlock += app.util.renderTemplate(attributeTemplate.innerHTML, { id, valuesBlock });
		}

		this.productTile.variationAttributesBlock = variationAttributesBlock;
	}

	buildColorAmountBlock() {
		const amount = this.productTile.colors?.length;

		if (amount) {
			const template = document.getElementById('productTileColorAmount');

			this.productTile.colorAmountBlock = app.util.renderTemplate(template.innerHTML).replace('{0}', amount)
		}
	}

	buildQuantitySelectorBlock() {
		if (!this.productTile.isBonusProduct && this.productTile.quantitySelector) {
			this.productTile.quantitySelectorBlock = app.util.renderTemplate(
				$('#productTileQuantitySelector').html(),
				{
					qty: this.productTile.qty,
					lineItemId: this.productTile.uuid,
					minusButtonAriaLabel: this.productTile.quantitySelector.minusButtonAriaLabel,
					quantityAriaLabel: this.productTile.quantitySelector.quantityAriaLabel,
					plusButtonAriaLabel: this.productTile.quantitySelector.plusButtonAriaLabel
				}
			);
		}
	}

	getResult() {
		return this.productTile;
	}

	setProductAdditionalClasses() {
		this.productTile.productAdditionalClasses = '';

		if (this.productTile.isBonusProduct) {
			this.productTile.productAdditionalClasses = 'l-product-tile--bonus ';
		}

		if (this.productTile.isHeroProduct) {
			this.productTile.productAdditionalClasses += 'js-hero-product ';
		}

		if (this.productTile.availability && this.productTile.availability.status === 'notAvailibleInStore') {
			this.productTile.productAdditionalClasses += 'l-product-tile--notavailable ';
		}

		if (this.productTile.badges && this.productTile.badges.length) {
			this.productTile.productAdditionalClasses += 'l-product-tile--has-badges';
		}
	}
}

export default Builder;
