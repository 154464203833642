(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			footer: $('footer'),
			socialIconTriggerSel: '.js-social-icon-trigger'
		};
	}

	function initializeEvents() {
		$cache.footer.find($cache.socialIconTriggerSel).on('click', function(e) {
			e.preventDefault();

			var $this = $(this);
			var $content = $cache.footer.find($this.data('content-selector'));

			if (!$content.length) {
				return;
			}

			app.flyoutMgr.open('static', {
				content: $content.html(),
				customClass: 'm-flyout-centered',
				direction: !app.device.isMobileView() ? 'center' : ''
			});

			return false;
		});
	}

	/** app.components.global.footer public object */
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.footer = {
		init: function() {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
