import SearchProviderFactory from './SearchProviderFactory';

/**
 * Represents a search manager that retrieves the appropriate search provider based on the current preferences.
 */
class SearchMgr {
	/**
	 * Creates a new instance of the SearchMgr.
	 */
	constructor() {
		/**
		 * The ID of the search provider obtained from the application preferences.
		 * @type {string}
		 */
		this.providerId = app.preferences.searchProvider;
	}

	/**
	 * Gets the search provider based on the provider ID.
	 *
	 * @returns {*} The search provider instance.
	 */
	getProvider() {
		return SearchProviderFactory.create(this.providerId);
	}
}

export default new SearchMgr();
