import PriceUtils from 'oneapp/src/utils/Price';

/**
 * Class representing the Ocapi service for handling search suggestions and product searches.
 */
class ShopApi {
	/**
	 * Gets search suggestions based on the provided query.
	 * @param {string} query - The search query.
	 * @returns {Promise<Array>} A promise that resolves to an array of custom search suggestions.
	 */
	getSearchSuggestion(params) {
		return new Promise((resolve) => {
			makeRequest(`search_suggestion`, { params }).then((data) => {
				resolve(data.custom_suggestions.custom_suggestions);
			});
		});
	}

	/**
	 * Gets product search results based on the provided query.
	 * @param {string} query - The search query.
	 * @returns {Promise<Object>} A promise that resolves to an object containing product search hits and total count.
	 */
	getProductSearch(params) {
		const defaultParams = {
			country_code: app.user.country.value,
			count: '4',
			expand: 'prices,images',
			badge_extended: 'true',
			images_extended: 'true',
			locale: app.preferences.currentLocale.replace('_', '-')
		};

		return new Promise((resolve) => {
			makeRequest('product_search', { params: { ...defaultParams, ...params } }).then((data) => {
				resolve(parseProductSearch(data));
			});
		});
	}
}

/**
 * Parses the product search data and extracts relevant information.
 * @param {Object} data - The product search data.
 * @returns {Object} An object containing parsed product search hits and total count.
 */
const parseProductSearch = (data) => {
	const hits = [];

	for (const hitData of data.hits ?? []) {
		const {
			c_url,
			product_name,
			product_id,
			c_badge,
			c_productPrices,
			image: c_image,
			c_images: images,
			c_variations_attributes: variationAttributes,
		} = hitData ?? {};

		const salesPrice = c_productPrices.salesPrices[0];
		const prices = {
			standardPrice: PriceUtils.decodeFormattedPrice(c_productPrices.listPrices[0].formattedPrice),
			salesPrice: salesPrice.value && PriceUtils.decodeFormattedPrice(salesPrice.formattedPrice),
			discountPercent: c_productPrices.discountPercent
		};
		const image = {
			url: c_image?.link,
			alt: c_image?.alt
		};

		const hit = {
			images,
			variationAttributes,
			id: product_id,
			productLink: c_url,
			badges: c_badge,
			prices,
			image,
			name: product_name
		};

		hits.push(hit);
	}

	return {
		hits,
		total: data.total
	};
};

/**
 * Creates a query string from the given parameters.
 * @param {Object} options - The parameters to be included in the query string.
 * @returns {string} The generated query string.
 */
const createQueryStringFromParams = (options) => {
	return Object.entries(options).reduce((acc, [key, value], index) => `${index === 0 ? `?${acc}` : `${acc}&`}${key}=${value}`, '');
};

/**
 * Make a request to the OCAPI endpoint.
 * @param {string} endUrl - The endpoint URL.
 * @param {Object} options - The request options.
 * @param {string} options.method - The HTTP method (default is 'GET').
 * @param {Object} options.body - The request body.
 * @param {Object} options.headers - The request headers.
 * @param {string} type - The type of request (default is 'shop').
 * @returns {Promise<Object>} A promise that resolves to the response JSON.
 */
const makeRequest = (endUrl, { method, body, headers, params } = { method: 'GET', headers: {}, params: {} }) => {
	return new Promise((resolve, reject) => {
		const options = {
			headers: {
				'x-dw-client-id': app.preferences.ocapiClientId,
				'content-type': 'application/json',
				...headers
			},
			method
		};

		if (method === 'POST' || method === 'PUT') {
			options.body = JSON.stringify(body);
		}

		return fetch(`${app.urls.shopOcapi}/${endUrl}${createQueryStringFromParams(params)}`, options)
			.then((res) => res.json())
			.then((res) => resolve(res))
			.catch((res) => reject(res));
	});
};

export default new ShopApi();
