(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			tileCntrSel: '.js-content_item',
			zoomCntrSel: '.js-content_zoom',
			zoomlinkSel: '.js-content_link'

		};
	}

	function initializeEvents() {
		$cache.document.on('click', $cache.zoomlinkSel, function(e) {
			app.ajax.load({
				url: app.util.appendParamToURL($(this).attr('href'), 'attr', 'bodyDetail'),
				callback: function(response) {
					$(response).find('img').one('load', function() {
						app.fancybox.open(response, {
							fitToView: false,
							wrapCSS: 'fancybox-celeb'
						});
					});
				}
			});
			e.preventDefault();
		});
	}

	/**
	* @namespace app.components.cluster.custom public object
	*/
	app.components = app.components || {};
	app.components.cluster = app.components.cluster || {};
	app.components.cluster.custom = {
		init: function() {
			if (!app.device.isMobileView()) {
				initializeCache();
				initializeEvents();
			}
		}
	};
}(window.app = window.app || {}, jQuery));
