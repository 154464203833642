(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			staticRecommendations: $('.js-carousel-static-recommendations'),
			predictiveRecommendationsSel: '.jcarousel-clip-horizontal',
			carouselBlockSel: '.js-carousel',
			recommendationItemsSel: '.js-product_tile_wrapper',
			lastVisitedWrapper: '.js-last-visited-wrapper',
			youMayAlsoLikeWrapper: '.js-you-may-also-like-wrapper',
			lastVisitedProductTile: '.js-product_tile',
			colorSwatchesCarouselSel: '.js-swatches.js-owl_carousel',
			clonedCarouselImageSel: '.cloned .js-producttile_image'
		};
	}

	function initializeDOM() {
		if ($cache.staticRecommendations.length) {
			initProductCarousel($cache.staticRecommendations);
		}
	}

	function initializeEvents() {
		// event for making actions right before carousel initialization
		// recommendationblock.custom.js:
		// get the amount of items after ajax call, correct carousel setting depending on received items amount
		$cache.document.on('recommendations.init.before', $cache.predictiveRecommendationsSel, function() {
			removeArrowsForSmallestCarousel($(this), $cache.recommendationItemsSel);
			var $youMayAlsoLikeWrapper = $($cache.youMayAlsoLikeWrapper);

			initProductCarousel($youMayAlsoLikeWrapper);
		});

		$cache.document.on('last.visited.loaded', function() {
			var $lastVisitedWrapper = $($cache.lastVisitedWrapper);
			removeArrowsForSmallestCarousel($lastVisitedWrapper, $cache.lastVisitedProductTile);

			initProductCarousel($lastVisitedWrapper);
		});

		function removeArrowsForSmallestCarousel(carouselBlock, carouselBlockTile) {
			var recommendationBlock = carouselBlock;
			var itemsLength = recommendationBlock.find(carouselBlockTile).length;
			var carouselContainer = recommendationBlock.parents($cache.carouselBlockSel);
			var inclusiveAmountEnable = carouselContainer.data('inclusiveAmount');
			var hideTitleEnabled = carouselContainer.data('hideTitle');
			var carouselConfigs = recommendationBlock.data('settings');

			if (
				inclusiveAmountEnable
				&& carouselConfigs
				&& typeof carouselConfigs === 'object'
				&& 'items' in carouselConfigs
				&& itemsLength > carouselConfigs.items
			) {
				recommendationBlock.data('settings', $.extend(carouselConfigs, { nav: true, loop: true }));
			}

			if (hideTitleEnabled && !itemsLength) {
				carouselContainer.hide();
			}
		}

		$cache.document.on('recommendations.loaded', function(event, recommendationBlock) {
			$(recommendationBlock).trigger('reload.single.owl');
		});
	}

	/**
	 * Initializes product carousel
	 * @param {jQuery} $container - Carousel container
	 */
	function initProductCarousel($container) {
		$container.on('owl.afterInit', () => {
			initColorSwatches($container);
			handlerCarouselClonedItemsLazyLoad($container);
			document.dispatchEvent(new CustomEvent('lazyload-reinit'));
		});

		const isCarouselInited = app.owlcarousel.initCarousel($container);

		if (!isCarouselInited) {
			initColorSwatches($container);
		}
	}

	/**
	 * Initializes color swatches carousel
	 * @param {jQuery} $container - Container with color swatches
	 */
	function initColorSwatches($container) {
		const $colorSwatches = $container.find($cache.colorSwatchesCarouselSel);

		$colorSwatches.each(function() {
			const $colorSwatch = $(this);

			$colorSwatch.on('owl.afterInit', () => false);
			app.owlcarousel.initCarousel($colorSwatch);
		});
	}

	/**
	 * Handles lazy load for cloned carousel items
	 * @param {jQuery} $container - Carousel container
	 */
	function handlerCarouselClonedItemsLazyLoad($container) {
		const $clonedCarouselImages = $container.find($cache.clonedCarouselImageSel);

		$clonedCarouselImages.each(function() {
			const $image = $(this);
			const removeLoader = () => app.lazyloadxt.removeLoader($image.closest('DIV'));

			if (this.complete) {
				removeLoader();
			} else {
				$image.on('load', removeLoader);
			}
		});
	}

	app.components = app.components || {};
	app.components.recommendationblock = app.components.recommendationblock || {};
	app.components.recommendationblock.custom = {
		init: function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
