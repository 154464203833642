/**
 * Represents markup
 */
class Markup {
	/**
	 *
	 * @param {String} markup
	 * @param {Object} data
	 */
	constructor(markup, data) {
		this.markup = markup;
		this.data = data;
	}

	/**
	 * Renders and returns markup
	 * @returns {String} Rendered markup
	 */
	[Symbol.toPrimitive]() {
		return getMarkup.call(this);
	}
}

/**
 * Returns markup
 * @returns {String} Markup
 */
function getMarkup() {
	return app.util.renderTemplate(this.markup, this.data);
}

export default Markup;
