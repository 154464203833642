(function(app, $) {
	function BookAppointment() {
		app.popups.Popup.call(this, 'bookAppointment');

		// fix for IOS, set <select> element on focus triggers its not expected opening on phone, tablet
		if (app.device.currentDevice() !== 'desktop') {
			this.isApplyFocusEnabled = false;
		}
	}

	BookAppointment.prototype = Object.create(app.popups.Popup.prototype);
	BookAppointment.prototype.constructor = app.popups.Popup;

	BookAppointment.prototype.open = function() {
		this.markup = getMarkup();

		// ios fix remove focus from input placeholder
		document.querySelector('.js-select-store-popup').blur();
		app.popups.Popup.prototype.open.call(this);
	};

	BookAppointment.prototype.initMarkup = function() {
		const popupStoresSelector = document.querySelector('.js-popup-stores-selector');
		const handleStoreSelected = (e) => {
			if (e.detail) {
				popupStoresSelector.value = e.detail;
				popupStoresSelector.dispatchEvent(new Event('change'));
			}
		};

		document.addEventListener('store.selected', handleStoreSelected);

		popupStoresSelector.addEventListener('change', function() {
			document.removeEventListener('store.selected', handleStoreSelected);
			// delay close
			setTimeout(() => {
				app.popupsMgr.close('bookAppointment');
			});
		});

		app.popups.Popup.prototype.initMarkup.call(this);
		app.components.global.storelocator.init();
	};

	BookAppointment.prototype.close = function() {
		const popupStoresSelector = document.querySelector('.js-popup-stores-selector');
		const selectedValue = popupStoresSelector.value;

		if (popupStoresSelector && selectedValue) {
			// replace space before the last word with dash
			const processedStoreName = popupStoresSelector.options[popupStoresSelector.selectedIndex].text.replace(/\s+(?=\S*$)/g, ' - ');

			document.querySelector('.js-select-store-popup').value = processedStoreName;

			const formStoresSelect = document.querySelector('.js-stores-selector');

			formStoresSelect.value = selectedValue;
			formStoresSelect.dispatchEvent(new Event('change'));
		}

		this.$markup.remove();
		app.popups.Popup.prototype.close.call(this);
	};

	function getMarkup() {
		return app.util.renderTemplate($('#bookAppointmentPopupTemplate').html());
	}

	app.popups.bookAppointment = new BookAppointment();
})((window.app = window.app || {}), jQuery);
