(function (app, $) {
	var $cache = {},
		nlPopupCount,
		CLICK = "click",
		nl_pop_up = "NL POP UP",
		configs = {};
		
	function initializeConfigs(params) {
		configs = {
			successCallback : successCallback,
			closePopupButton : ".js-close_newsletter",
			closeTimeout: 0
		};
		
		if(params) {
			configs = $.extend(true, {}, configs, params);
		}
	}
	
	function initializeCache() {
		$cache = {
			submitPopupSelector : ".js-popup_submit",
			formForSubmit : $(".js-popup_form_for_submit"),
			formForSubmitSelector : ".js-popup_form_for_submit",
			newsletterTypeNotRequiredSelector: '.js-newsletter-type-not-required',
			newsletterSubscriptionTypesSelector: '.js-newsletter-form_subscription-types',
			genderField: $(".js-popup_form_gender"),
			newsletterInnerSelector: '.js-newsletter_popup',
			closePopup : $(configs.closePopupButton),
			document : $(document)
		};
	}
	
	function initializeEvents() {
		
		$cache.document.on('newsletterpopup.opened', function() {
			initializeCache();
			initPopupEventsHandler();
		});
		
		if (app.validator){
			app.validator.init();
		}
	}
	
	function initPopupEventsHandler() {
		var params = {};
		params.popupForm = $cache.formForSubmit;
		$cache.document.on('submit', $cache.formForSubmitSelector, function(e) {
			e.preventDefault();
			e.stopPropagation();
			if (app.preferences.subscribeByEnter) {
				submitNewsleterPopup(e);
			}
		});
		$cache.document.on(CLICK, $cache.submitPopupSelector, function(e){
			e.preventDefault();
			submitNewsleterPopup(e);
		});
		$cache.closePopup.on(CLICK, closePopup);
		$cache.closePopup.on('keypress', function(e) {
			if (e.keyCode === 13) {
				closePopup();
				return false;
			}
		});
		app.components.global.simplesubscription.init(params);
		$cache.document.one('fancybox.closed', setCookieForClosed);
		$cache.document.one('fancybox.closed', function() {
			app.components.global.simplesubscription.init();
		});
	}
	
	function submitNewsleterPopup(e) {
		var globalNewsletter = app.components.global.newsletter;
		var sectionsStatus = true;
		var $formForSubmit = $($cache.formForSubmitSelector);

		$formForSubmit.validate();

		if (globalNewsletter && globalNewsletter.validateNewsletterSections) {
			sectionsStatus = app.components.global.newsletter.validateNewsletterSections($cache.formForSubmitSelector);
		}

		if (!$formForSubmit.valid() || !sectionsStatus || ($formForSubmit.find($cache.newsletterSubscriptionTypesSelector).length && !$formForSubmit.find('input:checkbox:checked').length && !$formForSubmit.find($cache.newsletterTypeNotRequiredSelector).length)) {
			$cache.document.trigger('newsletter.form.invalid');
			return false;
		}

		if ($cache.genderField.length) {
			$cache.genderField.val($(e.target).val());
		}
		var customEvent = $.Event('simple.subscribe');
		$(document).trigger(customEvent, $formForSubmit);
		if(customEvent.isDefaultPrevented()) return false;
		var url = app.urls.submitNewsletterPopup,
			data = $formForSubmit.serialize();

		app.ajax.load({
			url : url,
			type: 'POST',
			data: data,
			callback: function(resp) {
				if (resp && resp.redirectURL) {
					app.page.redirect(resp.redirectURL);
				} else {
					$(document).trigger('newsletter.subscribed', [app.util.form2Object($formForSubmit).newsletter_popup_email, nl_pop_up]);
					setNewsletterCookie(2);
					configs.successCallback(resp);
				}
			}
		});
	}
	
	function setNewsletterCookie(value) {
		var expiration = new Date(),
			year = 365 * 24 * 60;
		expiration.setTime(expiration.getTime() + (year * 60 * 1000));
		nlPopupCount = $.cookie("nlPopupCount", value, { expires: expiration, path: "/" });
		if (value === 1) {
			$.cookie("nlPopupCountSession", true, { path: '/' });
		}
		$.cookie("nlPopupClosedTime", (new Date()).getTime(), { expires: expiration, path: '/' });
	}
	
	function getNewsletterCookie() {
		return $.cookie("nlPopupCount") || 0;
	}
	
	function closePopup() {
		if (configs.closeTimeout > 0) {
			setTimeout(function() {
				app.fancybox.close();
			}, configs.closeTimeout);
		} else {
			app.fancybox.close();	
		}
		
		setCookieForClosed();
		//Remove handler on click outside if was closed with close button
		$cache.document.off('fancybox.closed', setCookieForClosed);
	}
	
	function setCookieForClosed() {
		setNewsletterCookie(2);
	}
	
	function successCallback(data) {
		if (data) {
			const $newsletterInner = $($cache.newsletterInnerSelector).parent();

			if (app.util.getConfig('showNotificationMessage')) {
				app.notificationsMgr.show('general', { text: data });
				app.fancybox.close();

				return;
			}

			if ($newsletterInner.length === 0) {
				app.fancybox.open(data);
			} else {
				$newsletterInner.html(data);
				app.fancybox.update();
			}
			$cache.document.trigger('newsletterpopupconfirmation.opened');

			if ((app.device.currentDevice() === 'desktop' && app.configs.hideNewsletterConfirmationPopup.desktop)
				|| (app.device.currentDevice() === 'tablet' && app.configs.hideNewsletterConfirmationPopup.tablet)
				|| (app.device.currentDevice() === 'mobile' && app.configs.hideNewsletterConfirmationPopup.mobile)) {
				setTimeout(function () {
					app.fancybox.close();
					$cache.document.trigger('newsletterpopupconfirmation.closed');
				}, app.util.getConfig('ajaxNewsletter.thankyouFancyboxCloseTimeout'));
			}
		}
	}
	
	/**
	 * @namespace app.components.newsletter.handlepopup public object
	 **/
	app.components = app.components || {};
	app.components.newsletter = app.components.newsletter || {};
	app.components.newsletter.handlepopup = {
		init : function (params) {
			initializeConfigs(params);
			initializeCache(params);
			initializeEvents(params);
		},
		successCallback: successCallback,
		closePopup: closePopup
	};
}(window.app = window.app || {}, jQuery));