import algoliasearch from 'algoliasearch';
import instantsearch from 'instantsearch.js';
import {
	connectSearchBox,
	connectInfiniteHits,
	connectRefinementList,
	connectSortBy,
	connectClearRefinements
} from 'instantsearch.js/es/connectors';

import ProductTileDirector from 'oneapp/src/classes/productTile/Director';
import ProductTileBuilder from 'oneapp/src/classes/productTile/Builder';
import ProductTile from 'oneapp/src/classes/productTile/ProductTile';
import Adapter from 'oneapp/src/classes/search/adapters/AlgoliaAdapter';
import RecentSearch from 'oneapp/src/utils/RecentSearch';

import SearchProvider from './SearchProvider';

/**
 * Represents the layout types for the search results.
 * @readonly
 * @enum {string}
 */
const layoutTypes = {
	START: 'start',
	RESULTS: 'results',
	NO_RESULTS: 'no-results'
};

/**
 * Represents the refinements types for the search results.
 * @enum {string}
 */
const refinementsTypes = {
	SORT: 'sort',
	ACTION: 'action',
	FILTERS: 'filters'
};

/**
 * Represents result action types.
 * @readonly
 * @enum {string}
 */
const resultActionsTypes = {
	MORE: 'more',
	QUERY: 'query',
	REFINE: 'refine',
	INITIAL: 'initial'
};

/**
 * The status attribute value indicating that an element has been initialized.
 * @type {string}
 */
const INITIALIZED_STATUS = 'INITIALIZED_STATUS';

/**
 * Represents the CSS classes used in the search interface.
 * @readonly
 * @enum {string}
 */
const classes = {
	open: 'open',
	selected: 'selected',
	empty: 'empty',
	refinementsContainer: 'search-refinements',
	refinementContainer: 'search-refinement-item'
};

/**
 * Represents the CSS selectors used in the search interface.
 * @readonly
 * @enum {string}
 */
const selectors = {
	actions: '.js-search-actions',
	results: '.js-search-results',
	hits: '.js-search-hits',
	pagingMore: '.js-search-paging-more',
	box: '.js-search-box',
	boxReset: '.js-search-box-reset',
	boxInput: '.js-search-box-input',
	close: '.js-search-close',
	editorialLink: '.js-search-editorial-link',
	selectedFiltersRemove: '.js-selected-filters-remove',
	queryApply: '.js-search-query-apply',
	queryRemove: '.js-search-query-remove',
	refinementList: '.js-search-refinement-list',
	refinementItemValue: '.js-search-refinement-item-value',
	refinementApply: '.js-search-refinement-apply',
	recent: '.js-search-recent',
	searchHitTile: '.js-search-hit',
	selectedFiltersItem: '.js-selected-filters-item',
	selectedFilters: '.js-search-selected-filters',
	paging: '.js-search-paging',
	resultsCounter: '.js-search-results-counter',
	selectedFiltersClear: '.js-search-selected-filters-clear',
	refinementsContainer: `.js-${classes.refinementsContainer}`
};

/**
 * Represents the template IDs used in the search interface.
 * @readonly
 * @enum {string}
 */
const templates = {
	container: '',
	results: 'search-results',
	results: 'search-results',
	noResults: 'search-no-results',
	hit: 'search-hit',
	padding: 'search-paging',
	box: 'search-box',
	editorial: 'search-editorial',
	editorialLinks: 'search-editorial-links',
	editorialLink: 'search-editorial-link',
	editorialCard: 'search-editorial-card',
	recent: 'search-recent',
	refinementItem: 'search-refinement-item',
	selectedFilters: 'search-selected-filters',
	selectedFilterItem: 'search-selected-filter-item',
	actions: 'search-actions',
	queryItem: 'search-query-item',
	counter: 'search-counter',
	refinementItemValue: 'search-refinement-item-value',
	refinementApply: 'search-refinement-apply',
	showMore: 'search-paging-showmore-button',
	applyCounter: 'search-refinement-apply-counter',
	refinementItemLabelColor: 'search-refinement-item-label-color'
};

/**
 * Represents the HTML tag name used for container elements.
 * @constant
 * @type {string}
 */
const CONTAINER_TAG_NAME = 'div';

/**
 * !TODO make as preference
 * Represents the limit of recent search queries to be displayed.
 * @constant
 * @type {number}
 */
const RECENT_SEARCH_LIMIT = 3;
const DEFAULT_BACKGROUND_COLOR = 'transparent';

/**
 * @typedef {Object} Hit
 * @property {string} objectID - The unique identifier of the hit.
 * @property {any} [other properties] - Other properties specific to your index.
 */

/**
 * Represents the data structure for an editorial item with links and an optional image URL.
 * @typedef {Object} EditorialItem
 * @property {Object[]} links - An array of link data, each containing 'url' and 'label' properties.
 * @property {string} imgUrl - The URL of the editorial image.
 * @property {string} color
 * @property {string} hoverColor
 * @property {string} backgroundColor
 */

/**
 * @typedef {Object} SearchResults
 * @property {number} nbHits - The total number of hits found.
 * @property {Hit[]} hits - An array of hits representing search results.
 */

/**
 * @typedef {Object} RenderOptions
 * @property {string} query - The current search query.
 * @property {function(string)} refine - Function to refine the search query.
 * @property {function()} clear - Function to clear the search query.
 * @property {boolean} isSearchStalled - Indicates if the search is stalled.
 * @property {Object} widgetParams - Parameters specific to the widget being rendered.
 * @property {HTMLElement} widgetParams.container - The container element for the widget.
 * @property {SearchResults} results - The search results object.
 * @property {boolean} showMore - Indicates if more results can be shown.
 * @property {boolean} isFirstPage - Indicates if the current page is the first page.
 * @property {boolean} isLastPage - Indicates if the current page is the last page.
 * @property {boolean} showPrevious - Indicates if the previous results can be shown.
 */

/**
 * Represents a search provider that interacts with Algolia search.
 */
class AlgoliaSearchProvider extends SearchProvider {
	/**
	 * Constructs a new instance of the SearchProvider.
	 */
	constructor(searchProviderConfigs) {
		super();

		const { sorting, refinements, searchId, appId, editorial, indexName } = searchProviderConfigs ?? {};

		/**
		 * The search ID used for the search provider.
		 * @type {string}
		 */
		this.searchId = searchId;

		/**
		 * The app ID used for the search provider.
		 * @type {string}
		 */
		this.appId = appId;

		/**
		 * The index name used for the search provider.
		 * @type {string}
		 */
		this.indexName = indexName;

		/**
		 * The refinements for the search provider.
		 * @type {Array<Object>}
		 */
		this.refinements = refinements;

		/**
		 * The sorting options for the search provider.
		 * @type {Array<Object>}
		 */
		this.sorting = sorting;

		/**
		 * The editorial content for the search provider.
		 * @type {Array<EditorialItem>}
		 */
		this.editorial = editorial;

		/**
		 * The Algolia search client instance.
		 * @type {import('algoliasearch').SearchClient}
		 */
		this.searchClient = algoliasearch(this.appId, this.searchId);

		/**
		 * The instantsearch instance for the search provider.
		 * @type {import('instantsearch.js').InstantSearch}
		 */
		this.search = instantsearch({
			indexName: this.indexName,
			searchClient: this.searchClient,
			insights: {
				insightsInitParams: {
					useCookie: true
				}
			}
		});

		this.search.start();
	}

	/**
	 * Initializes the search provider.
	 */
	open() {
		super.open();

		initWidgets.call(this);
		initGlobalEvents.call(this);
	}
}

/**
 * Initializes the widgets for the search provider.
 * @private
 */
function initWidgets() {
	const { widgets: refinementsWidgets, containers: refinementContainers } = getRefinementsWidgets.call(this);

	this.search.addWidgets([
		...refinementsWidgets,
		connectInfiniteHits(renderResults)({
			container: document.querySelector(selectors.results),
			editorial: this.editorial,
			refinementContainers
		}),
		connectSearchBox(renderSearchBox)({
			container: document.querySelector(selectors.box)
		}),
		connectSortBy(renderSortBy)({
			container: document.querySelector(selectors.sortBy),
			items: this.sorting
		}),
		connectClearRefinements(initClearRefinementsEvents)()
	]);
}

/**
 * Initializes the clear refinements events for a given set of render options.
 *
 * @function initClearRefinementsEvents
 * @memberof SomeObject
 * @param {RenderOptions} renderOptions - Options object containing configurations for rendering.
 */
const initClearRefinementsEvents = (renderOptions) => {
	const { refine } = renderOptions;
	const container = document.querySelector(selectors.selectedFiltersClear);

	container?.addEventListener('click', () => {
		setResultsActionType(resultActionsTypes.REFINE);
		refine();
	});
};

/**
 * Sets the action type for results in the specified container.
 * @param {string} action - The action type to be set for the results.
 */
const setResultsActionType = (action) => {
	const container = document.querySelector(selectors.hits);

	if (container) {
		container.dataset.action = action;
	}
};

/**
 * Retrieves the current action type for results.
 * @returns {string} The current action type for results.
 */
const getResultsActionType = () => document.querySelector(selectors.hits)?.dataset?.action ?? resultActionsTypes.INITIAL;

/**
 * Initializes global events for the search interface.
 */
function initGlobalEvents() {
	const closeNode = document.querySelector(selectors.close);

	closeNode.addEventListener('click', () => {
		this.close();
	});
}

/**
 * Generates refinements widgets and containers based on the provided refinements data.
 * @private
 * @returns {Object} An object containing arrays of widgets and containers.
 */
function getRefinementsWidgets() {
	const results = {
		widgets: [],
		containers: []
	};

	for (const { id, attribute, type, label } of this.refinements) {
		const refinementContainer = document.createElement(CONTAINER_TAG_NAME);

		const refinementWidget = connectRefinementList(renderRefinementItem)({
			container: refinementContainer,
			attribute,
			label,
			id,
			type
		});

		results.containers.push(refinementContainer);
		results.widgets.push(refinementWidget);
	}

	const sortingContainer = document.createElement(CONTAINER_TAG_NAME);

	const sortingWidget = connectSortBy(renderSortBy)({
		container: sortingContainer,
		indexName: this.indexName,
		items: getSortingItems.call(this)
	});

	results.containers.push(sortingContainer);
	results.widgets.push(sortingWidget);

	return results;
}

/**
 * Generates an array of sorting items with labels and values.
 * @private
 * @returns {Array} An array of sorting items with label and value properties.
 */
function getSortingItems() {
	const sortingItems = [];

	for (const { label, value, id } of this.sorting) {
		sortingItems.push({
			value: `${this.indexName}${value}`,
			label,
			id
		});
	}

	return sortingItems;
}

/**
 * Renders the sort item in the search interface based on the provided render options.
 * @param {RenderOptions} renderOptions - The render options for the sort item.
 */
const renderSortBy = (renderOptions) => {
	const { options, currentRefinement, refine, widgetParams, canRefine } = renderOptions;
	const selectedRefinements = widgetParams.indexName !== currentRefinement ? [currentRefinement] : [];

	setUpRefinementContainer({
		container: widgetParams.container,
		refinements: options,
		refine,
		selectedRefinements,
		id: refinementsTypes.SORT,
		type: refinementsTypes.SORT,
		title: app.resources.SORT_BY,
		canRefine
	});
};

/**
 * Renders the refinement item in the search interface based on the provided render options.
 * @param {RenderOptions} renderOptions - The render options for the refinement item.
 */
const renderRefinementItem = (renderOptions) => {
	const { widgetParams, refine, canRefine, ...data } = renderOptions;

	const title = widgetParams.label;
	const type = widgetParams.type;
	const id = widgetParams.id;

	const { refinements, selectedRefinements } = Adapter.adapt('refinements', { ...data, id });

	setUpRefinementContainer({
		container: widgetParams.container,
		refinements,
		refine,
		selectedRefinements,
		type,
		title,
		id,
		canRefine
	});
};

/**
 * Sets up a refinement container based on the provided data and configurations.
 * @param {Object} options - Options object containing configuration for the refinement container.
 * @param {boolean} options.canRefine - Indicates if the container can be refined.
 * @param {Element} options.container - The container element where the refinement will be rendered.
 * @param {function} options.refine - The function to call when the container is refined.
 * @param {Array} options.refinements - An array of refinement data to be used for rendering.
 * @param {Array} options.selectedRefinements - An array of selected refinement data.
 * @param {string} options.id - The ID of the refinement container.
 * @param {string} options.title - The title of the refinement container.
 * @param {string} options.type - The type of the refinement container.
 */
const setUpRefinementContainer = ({ canRefine, container, refine, refinements, selectedRefinements, id, title, type }) => {
	if (container) {
		if (canRefine) {
			container.innerHTML = getRefinementItemMarkup({ refinements, title, type, selectedRefinements });
			container.style.display = 'flex';
		} else {
			container.innerHTML = '';
			container.style.display = 'none';
		}

		container.refine = (props) => {
			setResultsActionType(resultActionsTypes.REFINE);
			refine(props);
		};
		container.dataset.type = type;
		container.dataset.id = id;
		container.dataset.refinements = JSON.stringify(refinements);
		container.dataset.selectedRefinements = JSON.stringify(selectedRefinements);

		if (type) {
			container.dataset.type = type;
		}

		const additionalClass = selectedRefinements.length ? classes.open : '';

		addContainerClasses(container, { className: classes.refinementContainer, type, id, additionalClass });
	}
};

/**
 * Generates the markup for a refinement item based on the provided data.
 * @param {Object} data - The data used to generate the refinement item markup.
 * @param {string} data.title - The title of the refinement item.
 * @param {string} data.type - The type of the refinement item.
 * @param {Object[]} data.refinements - An array of refinements for the item.
 * @param {Array<string>} data.selectedRefinements
 * @returns {string} The generated markup for the refinement item.
 */
const getRefinementItemMarkup = ({ title, type, refinements, selectedRefinements }) => {
	const template = document.getElementById(templates.refinementItem);
	const applyBlockTemplate = document.getElementById(templates.refinementApply);
	const applyCounterTemplate = document.getElementById(templates.applyCounter);

	let applyBlock = '';
	let counterBlock = '';
	let refinementListBlock = '';

	switch (type) {
		case 'action':
			refinementListBlock = getRefinementListMarkup(refinements, selectedRefinements);

			break;

		case 'sort':
			if (selectedRefinements.length) {
				refinementListBlock = getRefinementListMarkup(refinements, selectedRefinements);
			}

			applyBlock = app.util.renderTemplate(applyBlockTemplate.innerHTML, { title, counterBlock });

			break;

		default:
			if (selectedRefinements.length) {
				refinementListBlock = getRefinementListMarkup(refinements, selectedRefinements);
				counterBlock = app.util.renderTemplate(applyCounterTemplate.innerHTML, { amount: selectedRefinements.length });
			}

			applyBlock = app.util.renderTemplate(applyBlockTemplate.innerHTML, { title, counterBlock });

			break;
	}

	return app.util.renderTemplate(template.innerHTML, { refinementListBlock, applyBlock });
};

/**
 * Renders the search box in the search interface.
 * @param {RenderOptions} renderOptions - The render options for the search box.
 */
const renderSearchBox = (renderOptions) => {
	const { query, refine, clear, widgetParams } = renderOptions;
	const template = document.getElementById(templates.box);

	if ([resultActionsTypes.QUERY, resultActionsTypes.INITIAL].includes(getResultsActionType())) {
		widgetParams.container.innerHTML = app.util.renderTemplate(template.innerHTML);

		initSearchBoxEvents(widgetParams.container, { refine, clear, query });
	}
};

/**
 * Renders the search results in the search interface.
 * @param {RenderOptions} renderOptions - The render options for the search results.
 */
const renderResults = (renderOptions) => {
	const { hits, widgetParams, results, showMore, currentPageHits } = renderOptions;
	const { editorial, container, refinementContainers } = widgetParams;
	const [total, query] = [results?.nbHits, results?.query];
	const actionContainer = document.querySelector(selectors.actions);
	const allSelectedFilters = getAllSelectedFilters(refinementContainers);
	const type = getLayoutType({ total, query });

	RecentSearch.addQuery(query);

	switch (type) {
		case layoutTypes.START:
			container.innerHTML = getEditorialMarkup(editorial);
			actionContainer.innerHTML = getActionsMarkup();

			initEditorialEvents(container);

			break;

		case layoutTypes.RESULTS:
			processResults(container, { hits, currentPageHits, results, query, allSelectedFilters });
			removeRecentSearchBlock(actionContainer);
			appendRefinements(actionContainer, { refinementContainers });
			initResultsEvents(container, { showMore, refinementContainers, pageNumber: results.page });

			break;

		case layoutTypes.NO_RESULTS:
			container.innerHTML = getNoResultsMarkup({ editorial });
			actionContainer.innerHTML = getActionsMarkup(false);

			break;
	}
};

/**
 * Gets all selected filters from the provided refinement containers.
 * @param {Array<HTMLDivElement>} refinementContainers - A list of refinement containers.
 * @returns {Array} An array containing objects representing selected filters with their values and IDs.
 */
const getAllSelectedFilters = (refinementContainers) => {
	const allSelectedFilters = [];

	for (const refinementContainer of refinementContainers) {
		if (refinementContainer.dataset.type === refinementsTypes.SORT) {
			continue;
		}

		try {
			const selectedRefinements = JSON.parse(refinementContainer.dataset.selectedRefinements);

			for (const value of selectedRefinements) {
				allSelectedFilters.push({ value, id: refinementContainer.dataset.id });
			}
		} catch (error) {
			continue;
		}
	}

	return allSelectedFilters;
};

/**
 * Appends the refinement containers to the main container in the search interface.
 * @param {HTMLElement} container - The main container element to which refinement containers will be appended.
 * @param {Object} options - Options for appending the refinement containers.
 * @param {Array<HTMLElement>} options.refinementContainers - An array of refinement containers to be appended.
 */
const appendRefinements = (container, { refinementContainers }) => {
	let refinementsContainer = document.querySelector(`.js-${classes.refinementsContainer}`);

	if (!refinementsContainer) {
		refinementsContainer = document.createElement(CONTAINER_TAG_NAME);

		addContainerClasses(refinementsContainer, { className: classes.refinementsContainer });

		container.appendChild(refinementsContainer);

		for (const refinementContainer of refinementContainers) {
			refinementsContainer.appendChild(refinementContainer);
		}
	}
};

/**
 * Removes the recent search block from the container.
 * @param {HTMLElement} container - The container element from which the recent search block will be removed.
 */
const removeRecentSearchBlock = (container) => {
	const recentNode = container.querySelector(selectors.recent);

	if (recentNode) {
		container.removeChild(recentNode);
	}
};

/**
 * Generates the markup for the search actions section, which includes recent search queries.
 * @returns {string} The generated markup for the search actions section as a string.
 */
const getActionsMarkup = (isShowRecentSearchBlock = true) => {
	const template = document.getElementById(templates.actions);
	const recentBlock = isShowRecentSearchBlock ? getRecentSearchMarkup() : '';

	return app.util.renderTemplate(template.innerHTML, { recentBlock });
};

/**
 * Gets the layout type based on the provided search query and total number of hits.
 * @param {Object} data - The data containing the search query and total number of hits.
 * @param {string} data.query - The current search query.
 * @param {number} data.total - The total number of hits found.
 * @returns {string} The layout type, which can be 'start', 'results', or 'no-results'.
 */
const getLayoutType = ({ query, total }) => {
	let type = '';

	if (!query) {
		type = layoutTypes.START;
	} else if (total) {
		type = layoutTypes.RESULTS;
	} else {
		type = layoutTypes.NO_RESULTS;
	}

	return type;
};

/**
 * Initializes events for the search box in the search interface.
 * @param {HTMLElement} container - The container element for the search box.
 * @param {Object} options - Options for initializing the search box events.
 * @param {function(string)} options.refine - Function to refine the search query.
 * @param {function()} options.clear - Function to clear the search query.
 * @param {string} options.query - The current search query.
 */
const initSearchBoxEvents = (container, { refine, clear, query }) => {
	const inputNode = container.querySelector(selectors.boxInput);
	const resetButtonNode = container.querySelector(selectors.boxReset);

	inputNode.addEventListener('input', ({ target }) => {
		container.classList.toggle(classes.empty, target.value === '');

		setResultsActionType(resultActionsTypes.QUERY);
		refine(target.value);
	});

	resetButtonNode.addEventListener('click', () => {
		clear();
	});

	inputNode.value = query;
	inputNode.focus();

	initRecentSearchEvents({ refine });
};

/**
 * Generates the markup for the recent search section based on the limited recent search queries.
 * @returns {string} The generated markup for the recent search section as a string.
 */
const getRecentSearchMarkup = () => {
	const template = document.getElementById(templates.recent);
	const queryItemTemplate = document.getElementById(templates.queryItem);
	let queriesBlock = '';

	for (const value of RecentSearch.getLimitedQueries(RECENT_SEARCH_LIMIT)) {
		queriesBlock += app.util.renderTemplate(queryItemTemplate.innerHTML, { value });
	}

	return queriesBlock ? app.util.renderTemplate(template.innerHTML, { queriesBlock }) : '';
};

/**
 * Initializes events for the recent search section in the search interface.
 * @param {Object} options - Options for initializing the recent search events.
 * @param {function(string)} options.refine - Function to refine the search query.
 */
const initRecentSearchEvents = ({ refine }) => {
	const queryApplyNodes = document.querySelectorAll(selectors.queryApply);
	const queryRemoveNodes = document.querySelectorAll(selectors.queryRemove);

	for (const queryApplyNode of queryApplyNodes) {
		queryApplyNode.addEventListener('click', (event) => {
			setResultsActionType(resultActionsTypes.QUERY);
			refine(event.target.parentNode.dataset.value);
		});
	}

	for (const queryRemoveNode of queryRemoveNodes) {
		queryRemoveNode.addEventListener('click', (event) => {
			RecentSearch.removeQuery(event.target.parentNode.dataset.value);

			event.target.parentNode.remove();
		});
	}
};

/**
 * Generates the markup for the refinement list based on the provided refinements data.
 * @param {Array<Object>} refinements - An array of refinement data.
 * @returns {string} The generated markup for the refinement list as a string.
 */
const getRefinementListMarkup = (refinements, selectedRefinements = []) => {
	const template = document.getElementById(templates.refinementItemValue);
	const refinementItemLabelColorTemplate = document.getElementById(templates.refinementItemLabelColor);
	let refinementListMarkup = '';

	for (const refinement of refinements) {
		const additionalClass = selectedRefinements.includes(refinement.value) ? classes.selected : '';
		const colorBlock = refinement.hexColor
			? app.util.renderTemplate(refinementItemLabelColorTemplate.innerHTML, { color: refinement.hexColor })
			: '';

		refinementListMarkup += app.util.renderTemplate(template.innerHTML, { ...refinement, additionalClass, colorBlock });
	}

	return refinementListMarkup;
};

/**
 * Initializes a unique click event on the specified node, attaching the provided callback function.
 * @param {HTMLElement} node - The HTML element to attach the click event to.
 * @param {Function} callback - The callback function to be executed when the node is clicked.
 */
const initUniqClickEvent = (node, callback) => {
	if (node && node.dataset.status !== INITIALIZED_STATUS) {
		node?.addEventListener('click', function handler(props) {
			callback(props);
		});

		node.dataset.status = INITIALIZED_STATUS;
	}
};

/**
 * Initializes events for the search results in the search interface.
 * @param {HTMLElement} container - The container element for the search results.
 * @param {Object} options - Options for initializing the search results events.
 * @param {function()} options.showMore - Function to load more search results.
 */
const initResultsEvents = (container, { showMore, refinementContainers }) => {
	const productSearchHitTiles = container.querySelectorAll(selectors.searchHitTile);
	const pagingButtonNode = container.querySelector(selectors.pagingMore);

	initUniqClickEvent(pagingButtonNode, () => {
		setResultsActionType(resultActionsTypes.MORE);
		showMore();
	});
	initRefinementsEvents(refinementContainers);
	initSelectedRefinementsEvents(refinementContainers);
	initProductSearchHitTilesEvents(productSearchHitTiles);
};

/**
 * Initializes the event listeners for selected refinements items in the given refinement containers.
 * @param {Array<HTMLElement>} refinementContainers - A list of refinement containers.
 */
const initSelectedRefinementsEvents = (refinementContainers) => {
	const selectedFiltersRemoveNodes = document.querySelectorAll(selectors.selectedFiltersRemove);

	for (const selectedFiltersRemoveNode of selectedFiltersRemoveNodes) {
		selectedFiltersRemoveNode.addEventListener('click', (event) => {
			for (const refinementContainer of refinementContainers) {
				const parentNode = event.target.parentNode;
				const { id, value } = parentNode.dataset;

				if (id === refinementContainer.dataset.id) {
					refinementContainer.refine(value);
					parentNode.remove();

					break;
				}
			}
		});
	}
};

/**
 * Initializes events for the refinement containers in the search interface.
 * @param {Array<HTMLElement>} refinementContainers - An array of refinement container elements.
 */
const initRefinementsEvents = (refinementContainers) => {
	for (const refinementsContainer of refinementContainers) {
		const refinementApplyNode = refinementsContainer.querySelector(selectors.refinementApply);

		refinementApplyNode?.addEventListener('click', (event) => {
			const targetNode = event.target.parentNode;
			const refinementList = targetNode.querySelector(selectors.refinementList);
			let refinements = [];
			let selectedRefinements = [];

			try {
				refinements = JSON.parse(targetNode.dataset.refinements);
				selectedRefinements = JSON.parse(targetNode.dataset.selectedRefinements);
			} catch (error) {
				return;
			}

			targetNode.classList.toggle(classes.open);

			if (targetNode.classList.contains(classes.open)) {
				refinementList.innerHTML = getRefinementListMarkup(refinements, selectedRefinements);

				initRefinementListEvents(targetNode);
			} else {
				refinementList.innerHTML = '';
			}
		});

		initRefinementListEvents(refinementsContainer);
	}
};

/**
 * Initializes events for the refinement list within a refinement container.
 * @param {HTMLElement} container - The refinement container element containing the refinement list.
 */
const initRefinementListEvents = (container) => {
	const refinementItemValueNodes = container.querySelectorAll(selectors.refinementItemValue);

	for (const refinementItemValueNode of refinementItemValueNodes) {
		refinementItemValueNode.addEventListener('click', (event) => {
			container.refine(event.target.dataset.value);
		});
	}
};

/**
 * Initializes events for product search hit tiles
 * @param {HTMLElement[]} productSearchHitTiles - product search hit tiles elements
 */
const initProductSearchHitTilesEvents = (productSearchHitTiles) => {
	for (let index = 0; index < productSearchHitTiles.length; index++) {
		const productSearchHitTile = productSearchHitTiles[index];
		const productID = productSearchHitTile.dataset.pid;
		const queryID = productSearchHitTile.dataset.queryId;
		const position = index + 1;
		const linksNodes = productSearchHitTile.querySelectorAll('a');

		for (const link of linksNodes) {
			initUniqClickEvent(link, () => {
				sessionStorage.setItem('lastClickedProductSearchHit', productID);
				sessionStorage.setItem('lastClickedProductSearchHitAlgoliaQueryID', queryID);
				document.dispatchEvent(
					new CustomEvent('search.productSearchHit.click', {
						detail: { productID, queryID, position }
					})
				);
			});
		}
	}
};

/**
 * Initializes events for the editorial links in the search interface.
 * @param {HTMLElement} container - The container element containing the editorial links.
 */
const initEditorialEvents = (container) => {
	const editorialLinks = container.querySelectorAll(selectors.editorialLink);

	for (const linkNode of editorialLinks) {
		linkNode.style.color = linkNode.dataset.color;
		linkNode.style.backgroundColor = DEFAULT_BACKGROUND_COLOR;

		linkNode.addEventListener(
			'mouseover',
			(event) => {
				event.target.style.color = event.target.dataset.hoverColor;
				event.target.style.backgroundColor = event.target.dataset.backgroundColor;
			},
			false
		);

		linkNode.addEventListener(
			'mouseout',
			(event) => {
				event.target.style.color = event.target.dataset.color;
				event.target.style.backgroundColor = DEFAULT_BACKGROUND_COLOR;
			},
			false
		);
	}
};

/**
 * Generates the markup for the editorial section based on the provided editorial data.
 * @function getEditorialMarkup
 * @param {EditorialItem[]} editorial - An array of editorial.
 * @returns {string} The generated markup for the editorial section as a string.
 */
const getEditorialMarkup = (editorial) => {
	const template = document.getElementById(templates.editorial);
	const linksTemplate = document.getElementById(templates.editorialLinks);
	const linkTemplate = document.getElementById(templates.editorialLink);
	const cardTemplate = document.getElementById(templates.editorialCard);

	let editorialBlock = '';

	for (const { links, imgUrl } of editorial ?? []) {
		let linksMarkup = '';

		for (const link of links) {
			linksMarkup += app.util.renderTemplate(linkTemplate.innerHTML, link);
		}

		const linksBlock = app.util.renderTemplate(linksTemplate.innerHTML, { linksMarkup });

		if (imgUrl) {
			editorialBlock += app.util.renderTemplate(cardTemplate.innerHTML, { linksBlock, imgUrl });
		} else {
			editorialBlock += linksBlock;
		}
	}

	return app.util.renderTemplate(template.innerHTML, { editorialBlock });
};

/**
 * Generates the markup for the "no results" section based on the provided editorial data.
 * @param {Object} options
 * @param {EditorialItem[]} options.editorial - An array of EditorialItem
 */
const getNoResultsMarkup = ({ editorial }) => {
	const template = document.getElementById(templates.noResults);
	const editorialBlock = getEditorialMarkup(editorial);

	return app.util.renderTemplate(template.innerHTML, { editorialBlock });
};

/**
 * Generates markup for a list of search result hits.
 * @param {Object} options - The options object.
 * @param {Array} options.hits - An array of hit objects representing search results.
 * @param {string} options.queryID - The unique identifier for the query associated with the hits.
 * @returns {string} The generated HTML markup for the hits.
 */
const getHitsMarkup = ({ hits, queryID }) => {
	let hitsBlock = '';

	for (const hit of hits) {
		hitsBlock += getProductTileMarkup(
			Adapter.adapt('hit', {
				...hit,
				queryID
			})
		);
	}

	return hitsBlock;
};

/**
 * Renders and updates the markup for the search results section based on the provided options.
 * @param {HTMLElement} container - The container element
 * @param {Object} options - The options for rendering the search results.
 * @param {Hit[]} options.hits - An array of hits representing search results.
 * @param {Hit[]} options.currentPageHits - An array of hits representing search results.
 * @param {SearchResults} options.results - The search results object.
 * @param {Array} options.allSelectedFilters - An array of selected filter objects.
 * @param {string} options.query - The search query string.
 * @returns {string} The generated markup for the search results section as a string.
 */
const processResults = (container, { hits, currentPageHits, results, query, allSelectedFilters }) => {
	const template = document.getElementById(templates.results);
	const pagingTemplate = document.getElementById(templates.padding);
	const counterTemplate = document.getElementById(templates.counter);
	const showMoreButtonTemplate = document.getElementById(templates.showMore);
	const selectedFiltersBlock = getSelectedFiltersMarkup(allSelectedFilters);
	let hitsContainerNode = container.querySelector(selectors.hits);

	if (!hitsContainerNode) {
		container.innerHTML = app.util.renderTemplate(template.innerHTML);
		hitsContainerNode = container.querySelector(selectors.hits);
	}

	if (isResultActionValid(hitsContainerNode, { page: results.page })) {
		const resultsCounterNode = container.querySelector(selectors.resultsCounter);
		const pagingNode = container.querySelector(selectors.paging);
		const selectedFiltersNode = container.querySelector(selectors.selectedFilters);
		const hitsMarkup = getHitsMarkup({ hits: currentPageHits, queryID: results.queryID });
		let showMoreButton = '';

		if (results.page !== results.nbPages - 1) {
			showMoreButton = app.util.renderTemplate(showMoreButtonTemplate.innerHTML);
		}

		const counterBlock = app.util.renderTemplate(counterTemplate.innerHTML, { total: results.nbHits, query });
		const barPercent = `${(hits.length / results.nbHits) * 100}%`;
		const pagingBlock = app.util
			.renderTemplate(pagingTemplate.innerHTML, { barPercent, showMoreButton })
			.replace('{0}', hits.length)
			.replace('{1}', results.nbHits);

		resultsCounterNode.innerHTML = counterBlock;
		selectedFiltersNode.innerHTML = selectedFiltersBlock;
		pagingNode.innerHTML = pagingBlock;

		if (results.page && hitsContainerNode.dataset.action === resultActionsTypes.MORE) {
			hitsContainerNode.insertAdjacentHTML('beforeend', hitsMarkup);
		} else {
			hitsContainerNode.innerHTML = hitsMarkup;
		}
	}

	hitsContainerNode.dataset.page = results.page;
};

const isResultActionValid = (node, { page }) => {
	switch (node.dataset.action) {
		case resultActionsTypes.MORE: {
			return (+node.dataset.page + 1 || 0) === page;
		}

		default: {
			return true;
		}
	}
};

/**
 * Generates the markup for selected filters based on the provided array of selected filters.
 * @param {Array} allSelectedFilters - An array containing objects representing selected filters with their values and IDs.
 * @returns {string} The HTML markup for the selected filters.
 */
const getSelectedFiltersMarkup = (allSelectedFilters) => {
	if (!allSelectedFilters?.length) {
		return '';
	}

	const selectedFilterItemTemplate = document.getElementById(templates.selectedFilterItem);
	const selectedFiltersTemplate = document.getElementById(templates.selectedFilters);
	let selectedFilters = '';

	for (const { value, id } of allSelectedFilters) {
		selectedFilters += app.util.renderTemplate(selectedFilterItemTemplate.innerHTML, { title: value, value, id });
	}

	return app.util.renderTemplate(selectedFiltersTemplate.innerHTML, { selectedFilters });
};

/**
 * Generates the markup for a product tile.
 * @param {Object} productTile - The product tile object.
 * @returns {string} The markup for the product tile.
 */
const getProductTileMarkup = (productTile) => {
	const builder = new ProductTileBuilder(productTile);
	const director = new ProductTileDirector(builder);
	const template = document.getElementById(templates.hit);

	director.buildSearchProductTile();

	return new ProductTile(template.innerHTML, builder.getResult());
};

/**
 * Adds CSS classes to the container element.
 * @param {HTMLElement} container - The container element to which classes will be added.
 * @param {Object} options - Options for adding CSS classes.
 * @param {string} options.className - The base class name to be added to the container element.
 * @param {string} options.type - The specific type of the container element (optional).
 * @param {string} options.id - The specific id of the container element
 */
const addContainerClasses = (container, { className, type, id, additionalClass = '' }) => {
	container.className = `js-${className} c-${className} ${additionalClass}`;

	if (id) {
		container.classList.add(`js-${className}-${id}`, `c-${className}-${id}`);
	}

	if (type) {
		container.classList.add(`c-${className}--${type}`);
	}
};

export default AlgoliaSearchProvider;
