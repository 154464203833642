(function(app, $) {
	// update product grid count based on selected mode
	$('.l-search_result-content')
		.removeClass('m-four-columns')
		.addClass($('.b-change_view-type-active').data('grid-class'))
		.removeClass('h-hidden');

	var $cache = {
		body: $('body'),
		swipeContOpenMenuSel: '.m-site_opened .js-header_menu_toggle',
		performanceTabsSel: '.js-performance_tabs',
		widgetPerformanceChartSel: '.js-widget_performance_chart',
		chartInitializedClass: 'js-initialized'
	};

	if (app.device.isMobileView()) {
		$cache.body.on('swipeleft', $cache.swipeContOpenMenuSel, app.components.global.categoriesNavigation.menuToggle);
	}

	/**
	 * @function
	 * @description Append part of suggested phrase
	 * @param {Array} Array of searched product + suggested phrase
	 * @param {Object} global cache
	 */
	var placeSuggest = function(data, $cache) {
		if (!data || data.length === 0) {
			$cache.suggestInput.empty();

			return;
		}

		var firstElem = data[0];
		var	searchTerm = $cache.searchInput.val();

		if ('suggest' in firstElem) {
			var suggestPhrase = firstElem.suggest;

			suggestPhrase = suggestPhrase.substring(searchTerm.length, suggestPhrase.length);
			$cache.suggestInput.html(suggestPhrase);
		}
	};

	app.recommendations.initCarouselAfterAjaxLoad = function($carouselContainer) {
		$($carouselContainer).each(function() {
			$(this).trigger('recommendations.init.before');

			// Skip carousels which are already initialized (in case when several carousel present on page)
			if (!app.owlcarousel.getInstance(this)) {
				app.owlcarousel.initCarousel(this);
			}
		});
	};

	/**
	 * @function
	 * @description callback functions that are using in DG brand
	 */
	app.simpleSearchFunctions = {
		simpleSearchHandler: function($cache) {
			isLoading = true;

			var that = this;
			var searchTerm = $cache.searchInput.val();
			var url = app.util.appendParamsToUrl(app.urls.searchSuggestions, { q: searchTerm });

			app.ajax.load({
				url: url,
				callback: function(data) {
					that.successSimpleSearchCallback(data, $cache);
				}
			});
		},

		/**
		 * @function
		 * @description Append part of suggested phrase
		 * @param {Array} Array of searched product + suggested phrase
		 * @param {Object} global cache
		 */
		successSimpleSearchCallback: function(data, $cache) {
			this.buildResponseDom(data, $cache);
			placeSuggest(data, $cache);

			if ($cache.resultContainer.find('li').length) {
				$cache.resultContainer.removeClass(this.minimizedClass);
			}
		}
	};

}(window.app = window.app || {}, jQuery));
