/* eslint-disable no-new */

(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			window: $(window),
			document: $(document),
			carouselContainer: '.js-owl_carousel',
			assetWrapper: $('.js-asset_body-wrap'),
			assetImagesSel: '.js-asset_body-section_imgs',
			assetImgSel: '.js-asset_body-section_img',
			assetImgLinkSel: '.js-asset_body-section_link',
			videoTagSel: '.js-slot-video',
			videoPopupTagSel: '.js-popup-video',
			videoPoster: '.mejs-poster',
			videoPlay: '.mejs-overlay-play'
		};
	}

	function initializeDOM() {
		// initialize video
		initializeVideoBlock($cache.videoTagSel);
	}

	function initializeVideoBlock(videoSelector) {
		if ($(videoSelector).length) {
			new MediaElementPlayer(videoSelector, {
				success: function() {
					// Fix for mobile and tablet poster showing
					if (app.device.currentDevice != 'desktop') {
						$($cache.videoPoster).show();
						$($cache.videoPlay).show();
					}
				}
			});
		}
	}
	// Checks if we really need to initialize iPad "pageshow" listener
	function isInitPageShowListener() {
		var isHomePage = app.page && app.page.currentPage && (app.page.currentPage === 'homepage');
		var areImagesAvailable = $($cache.assetImagesSel).length && $($cache.assetImgLinkSel).length;

		return (isHomePage && areImagesAvailable);
	}

	function initializeEvents() {
		$cache.assetWrapper.on('mouseenter mouseleave', $cache.assetImagesSel, function() {
			var $this = $(this);

			$this.find($cache.assetImgSel).toggleClass('hide');
			$this.find($cache.assetImgLinkSel).toggleClass('show');
		});
		$cache.document.on('quickview.opened', function() {
			initializeVideoBlock($cache.videoPopupTagSel);

			if ($($cache.carouselContainer).length) {
				app.owlcarousel.initCarousel($($cache.carouselContainer));
			}
		});

		// Force IPhone reload page, instead get page from cache.
		if (isInitPageShowListener()) {
			$cache.window.on('pageshow', function(event) {
				if (event.originalEvent.persisted) {
					window.location.reload();
				}
			});
		}

		// Event to initialize video in case of its event-based appearence on a page
		$cache.window.on('storefrontvideo.init', function(e, initedClass) {
			var videoBlock = $($cache.videoTagSel);
			var videoTagSelOrigin = $cache.videoTagSel;

			$cache.videoTagSel = $cache.videoTagSel + ':not(".' + initedClass + '")';

			if (videoBlock.length) {
				initializeDOM();
				videoBlock.addClass(initedClass);
				videoBlock.closest('div' + videoTagSelOrigin).addClass(initedClass);
			}
		});
	}

	app.components = app.components || {};
	app.components.storefront = app.components.storefront || {};
	app.components.storefront.custom = {

		init: function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
