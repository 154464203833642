import PriceUtils from 'oneapp/src/utils/Price';
import colorNames from 'css-color-names'

/**
 * Class representing an adapter for transforming Algolia search data.
 */
class AlgoliaSearchAdapter {
	/**
	 * Adapts the data based on the provided type.
	 * @param {string} type - The type of data to adapt ('hit' in this case).
	 * @param {Object} data - The data to be adapted.
	 * @returns {Object} The adapted data as a product tile.
	 */
	adapt(type, data) {
		switch (type) {
			case 'hit': {
				const { available_colors: colors, price, image_groups, url, id, name, hitElementNumber, queryID } = data ?? {};

				const priceGroup = price?.[app.preferences.priceBooksGroup] || {};
				const prices = {
					standardPrice: PriceUtils.getFormattedPrice(priceGroup)
				};
				const { images } = image_groups?.find(({ _type }) => _type === 'image_group') ?? {};
				const [firstImage] = images ?? [];
				const image = {
					url: firstImage?.dis_base_link,
					alt: firstImage?.alt
				};

				const productTile = {
					id,
					queryID,
					productLink: url,
					prices,
					colors,
					image,
					name,
					hitElementNumber
				};

				return productTile;
			}

			case 'refinements': {
				const MULTI_COLOR = 'Multicolor'

				const { items, id } = data ?? {};
				const result = {
					refinements:  [],
					selectedRefinements: []
				}

				for (const { label, value, isRefined } of items ?? []) {
					const refinement = {
						label,
						value
					};

					result.refinements.push(refinement);

					if (isRefined) {
						result.selectedRefinements.push(value);
					}
				}

				return result;
			}

			default:
				break;
		}
	}
}

export default new AlgoliaSearchAdapter();
