;(function (app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document : $(document),
			feedItemSel : '.js-feed-item',
			feedpageSel : '.js-feedpage',
			controlsLinkSel: '.js-search_result-paging-controls-link',
			searchResultPagingClass : 'js-search_result-paging',
			contentTilesContainer : $('.js-feedpage-row'),
			libraryParameter: 'library',
			animContainer: $('.js_loader-anim-container'),
			mainContent: $('.js-main_content')
		};
		$cache.searchResultPagingSel = '.' + $cache.searchResultPagingClass;
		$cache.body = $cache.document.find('body');
		$cache.contentTiles = $cache.document.find($cache.feedItemSel).clone();
	}

	function initializeEvents(){
		if ($cache.contentTilesContainer.length > 0){
			// Load additional products on click on the paging buttons
			$cache.body.on('click', $cache.controlsLinkSel, function(e) {
				e.preventDefault();
				var showMoreBtn = $(this),
					nexBackBtns = $($cache.controlsLinkSel),
					showMoreBtnPos = showMoreBtn.position();

				nexBackBtns.css('pointer-events', 'none');
				showMoreBtn.first().css('opacity', '0');

				$cache.animContainer.css({
					'display': 'block',
					'top': showMoreBtn.offset().top,
					'left': showMoreBtnPos.left + 40
				});

				var pageSize = showMoreBtn.data('page-size') || 0;
				var url = app.util.appendParamsToUrl(showMoreBtn.attr('href'), {
					sz : pageSize,
					start : $($cache.feedItemSel).length
				});

				app.progress.show($cache.mainContent);
				app.ajax.load({
					url : url,
					callback : function(response) {
						loadFeedsContent(response, true);
					}
				});
			});
		}

		var currentParams = getHashParams();
		if (currentParams['currentitems'] && !isNaN(currentParams['currentitems'])){
			var itemsToLoad = currentParams['currentitems'] - $($cache.feedItemSel).length;
			if (itemsToLoad > 0){
				var url = app.util.appendParamsToUrl(window.location.href, {
					sz: itemsToLoad,
					start: $($cache.feedItemSel).length
				});

				app.progress.show();
				app.ajax.load({
					url : url,
					callback : function(response) {
						loadFeedsContent(response, true, true);
					}
				});
			}
		}
	}

	// Handles loading the HTML returned by an AJAX product search call (filter or pagination event)
	function loadFeedsContent(html, append, loadlocation) {
		var tiles = $(html).find($cache.feedItemSel),
			currentHash = window.location.hash,
			paging = $(html).hasClass($cache.searchResultPagingClass) && $(html);

		$cache.animContainer.hide();

		if($(html).find($cache.searchResultPagingSel).length > 0){
			$($cache.feedpageSel).find($cache.searchResultPagingSel).html($(html).find($cache.searchResultPagingSel).html());
		}

		if(tiles.length > 0) {
			if(append) {
				$cache.contentTilesContainer.append(tiles);
			} else {
				$cache.contentTilesContainer.html(tiles);
			}
		}


		if(paging && paging.length > 0) {
			$cache.contentTilesContainer.after(paging);
		}
		window.location.hash = '#currentitems=' + $($cache.feedItemSel).length;
		$cache.document.trigger('feedpage-updated', tiles);

		if (typeof(loadlocation) !== 'undefined' && loadlocation){
			window.location.href=currentHash;
		}
	}

	function getHashParams() {

		var hashParams = {};
		var e,
			a = /\+/g,  // Regex for replacing addition symbol with a space
			r = /([^&;=]+)=?([^&;]*)/g,
			d = function (s) { return decodeURIComponent(s.replace(a, " ")); },
			q = window.location.hash.substring(1);

		while (e = r.exec(q)){
			hashParams[d(e[1])] = d(e[2]);
		}

		return hashParams;
	}

	/**
	 * @namespace app.feedpage public object
	 **/
	app.feedpage = {
		init : function() {
			initializeCache();
			initializeEvents();
		}
	};

}(window.app = window.app || {}, jQuery));