(function (app, $) {
	var $cache = {};
	
	function initializeCache() {
		$cache = {
			document : $(document),
			maxCount : $('.js-lazyload-loadicon').data('max'),
			currentCount : $('.js-lazyload-loadicon').data('current'),
			loadMore : $('.g-loadmore_page'),
			clusterContent : $('.b-content_tiles'),
			loadIconSel : '.js-lazyload-loadicon',
			loadingCls : 'js-infinite_scroll-loading',
			clickEvent: Modernizr.touchevents ? 'touchstart' : 'click',
			clusterLoadFlag : false
		};
	}

	function initializeEvents() {
		if (app.preferences.clustersLoadButton) {
			$cache.document.on($cache.clickEvent, $cache.loadIconSel, function(){
				if (parseInt($cache.currentCount) < parseInt($cache.maxCount)) {
					loadNextPortion();
				}
			});
		} else {
			$cache.document.scroll(function(){
				var scrollCurrentPosition  = parseInt($(window).scrollTop(), 10) + parseInt($(window).height(), 10);
				var scrollLastItemPosition = $cache.loadMore.offset() == null ? 0 : $cache.loadMore.offset().top;
				if(scrollCurrentPosition > 0 && scrollCurrentPosition >= (scrollLastItemPosition - app.constants.LAZYLOAD_SCROLL_OFFSET) && (parseInt($cache.currentCount) < parseInt($cache.maxCount))){
					loadNextPortion();
				}
			});
		}
	}
	
	function loadNextPortion() {
		if($cache.clusterLoadFlag == false){
			$cache.clusterLoadFlag = true;
			$cache.loadMore.addClass($cache.loadingCls);
			var url   = $($cache.loadIconSel).data('url');
			$.ajax({
				type: "GET",
				url: url,
				dataType: "html",
				success: function(data){
					$cache.loadMore.removeClass($cache.loadingCls);
					var tiles = $(data).filter('.b-content_tiles').html();
					$cache.document.find($cache.loadIconSel).replaceWith($(data).filter($cache.loadIconSel));
					$cache.currentCount = $(data).filter($cache.loadIconSel).data('current');
					$cache.clusterContent.append(tiles);
					$cache.clusterLoadFlag = false;
					$cache.document.trigger('cluster.loaded');
				}
			});
		}
	}
	
	
/*************** app.components.global.clusterlazyload public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.clusterlazyload = {
      // initializations
		init : function (params) {
			initializeCache();
			initializeEvents();
		}
  };
})(window.app = window.app || {}, jQuery);