import ServiceWorkerUtils from 'oneapp/src/classes/service-worker-bridge/utils';

/* eslint-disable no-shadow */
(function(app, $) {
	var $cache = {};
	var defaultOrigin = 'Home-Show';

	/**
	 * @private
	 * Initializes cache
	 */
	function initializeCache() {
		$cache = {
			document: $(document),
			countrySelector: $('.js-country-selector'),
			localeSelector: $('.js-locale-selector'),
			selectors: {
				categoryRefinement: '.js-category_refinement-link',
				activeRefinement: '.js-refinement-link-active'
			}
		};
	}

	/**
	 * @private
	 * Initializes DOM
	 */
	function initializeDOM() {
		renderCurrentCountry();
		renderCurrentLocale();

		/**
		 * @private
		 * Renders current country
		 */
		function renderCurrentCountry() {
			$cache.countrySelector.html(app.user.country.displayValue);
		}

		/**
		 * @private
		 * Renders current locale
		 */
		function renderCurrentLocale() {
			$cache.localeSelector.html(app.user.locale.displayValue);
		}
	}

	/**
	 * @private
	 * Initializes events
	 */
	function initializeEvents() {
		$cache.countrySelector.click(function() {
			var countrySelectorLayoutURL = $(this).data('layout-url');

			app.flyoutMgr.open('countrySelector', {
				layoutURL: countrySelectorLayoutURL
			});

			return false;
		});

		$cache.localeSelector.click(function() {
			var localeSelectorLayoutURL = $(this).data('layout-url');

			app.flyoutMgr.open('languageSelector', {
				layoutURL: localeSelectorLayoutURL
			});

			return false;
		});
	}

	/**
	 * @private
	 * Returns URL with origin for returning to current page
	 */
	function addOriginToURL(url) {
		var queryMap;

		if (!app.constants.IS_CONTINUE_URL_BANNED_FROM_LOCALE_SELECTOR) {
			var systemParams = app.util.getQueryStringParams(app.currentCustomer.getCurrentQueryString(), true);
			var currentParams = app.util.getQueryStringParams(window.location.search.slice(1), true);

			queryMap = Object.assign(systemParams, currentParams);
			queryMap.origin = encodeURIComponent(app.currentCustomer.getCurrentPipeline());

			delete queryMap.dwcont;
		} else {
			queryMap = {
				origin: encodeURIComponent(defaultOrigin)
			};
		}

		return app.util.appendParamsToUrl(url, queryMap, true);
	}

	/**
	 * @public
	 * Change the country by making an AJAX call to the passed country URL
	 */
	function changeCountry(url) {
		app.ajax.load({
			url: url,
			dataType: 'json',
			callback: function(data) {
				ServiceWorkerUtils.emptyCache();

				if (data && data.location && data.location.length) {
					app.page.redirect(data.location);
				} else {
					app.page.refresh(true);
				}
			}
		});
	}

	/**
	 * @namespace app.components.global.localeselector public object
	 */
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.localeselector = {
		init: function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
		},
		changeCountry,
		addOriginToURL
	};
})((window.app = window.app || {}), jQuery);
