(function(app, $) {
	const templates = {
		swatch: 'colorSwatchTemplate',
		flyout: 'colorFlyoutTemplate'
	};

	const selectors = {
		action: '.js-color-swatch-action',
		main: '.js-pdp_main'
	};

	const classes = {
		selected: 'b-swatch-color-item__action--selected'
	};
	function ColorFlyout(config) {
		app.flyouts.Flyout.call(this, 'color', config);
	}

	ColorFlyout.prototype = Object.create(app.flyouts.Flyout.prototype);
	ColorFlyout.prototype.constructor = app.flyouts.Flyout;

	ColorFlyout.prototype.open = function(params) {
		this.markup = getMarkup(params);
		app.flyouts.Flyout.prototype.open.call(this, params);
	};

	ColorFlyout.prototype.initMarkup = function(params) {
		this.$markup.find('.js-swatches_color-link-selected').on('click', function() {
			this.close(params);

			return false;
		}.bind(this));

		const colorsNodes = document.querySelectorAll(selectors.action);

		for (const colorNode of colorsNodes) {
			colorNode.addEventListener('click', (e) => {
				e.delegateTarget = document.querySelector(selectors.main);
				app.product.changeMainContent(e);

				this.close(params);
			});
		}

		app.flyouts.Flyout.prototype.initMarkup.call(this, params);
	};

	function getMarkup(params = {}) {
		const colorFlyoutTemplate = document.getElementById(templates.flyout);
		let colorsBlock = '';

		if (params.hasOwnProperty('colorsData')) {
			const colorItemTemplate = document.getElementById(templates.swatch);

			for (const productColor of params.colorsData?.items ?? []) {
				const additionClass = params.colorsData?.selectedId === productColor.id ? classes.selected : '';

				colorsBlock += app.util.renderTemplate(colorItemTemplate.innerHTML, { ...productColor, additionClass });
			}
		}

		return app.util.renderTemplate(colorFlyoutTemplate.innerHTML, { colorsBlock });
	}

	var config = app.configs.flyoutComponent;

	app.flyouts.color = new ColorFlyout(config);
})((window.app = window.app || {}), jQuery);
