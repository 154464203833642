(function (app, $) {
	var $cache = {},
		configs = {},
		specificConfigs = {},
		preferences = app.preferences,
		urls = app.urls,
		resources = app.resources,
		msPerDay = 86400000;

	function initializeConfigs(params) {
		configs = {
			popupHandler: popupHandler,
			wrapperClass: ''
		};

		specificConfigs = app.componentsconfig.specific.global.components["global.newsletter"];

		if (params) {
			configs = $.extend(true, {}, configs, params);
		}
	}

	function initializeCache() {
		$cache = {
			body: $('body'),
			newsletterPopupContainer : $('#js-newsletter-popup-container'), //Newsletter popup slot has this html content
			simpleNewsletterPage : $(".js-simple_newsletter-page").length > 0,
			sectionSel: '.js-section',
			isCookiesBlockDisplay: app.device.isMobileView()
				? !(app.components.global.firstvisitbanner && app.components.global.firstvisitbanner.getMode() === 'fancybox' && !$('.js-first-visit-banner').hasClass('h-hidden')) : true,
			document: $(document),
			window: $(window),
			subscribeToNewsletterPopup: $('.js-subscribe-to-newsletter-popup'),
			openReminderForm: '.js-remindcoupon_button',
			sendRemindSel: '.js-send-remind',
			sendRemindSelForm: '.js-remind-coupon-form',
			promoCodeSel: '.js-promo-code',
			selectedForm: $('.js-newsletter_subscription_form, .js-account_registration-form'),
			popupInitialized: false,
			newsletterFormSelector: '.js-detailed_newsletter-form',
			detailedNewsletterFormSelector: 'form.js-newsletter_subscription_form',
			newsletterSubscriptionTypes: $('.js-newsletter-form_subscription-types'),
			selectAllButton: $('.js-detailed_newsletter-button_selectall'),
			buttonTextSelectAllSelector: '.js-button-text-selectall',
			buttonTextDeselectAllSelector: '.js-button-text-deselectall',
			newsletterSubscriptionTypesSelector: '.js-newsletter-form_subscription-types',
			newsletterFormNewsforMultiSelector: '.js-newsletter-form-newsfor',
			newsletterFormNewsforMultiFieldSelector: '.js-newsletter-form-newsfor_field',
			newsletterFormNewsforMultiFieldCheckedSelector: '.js-newsletter-form-newsfor_field .f-checkbox:checked',
			submitBtnSelector: '.js-newsletter-button_submit',
			submitBtnDisabledClass: 'b-simple_newsletter-button_disabled'
		};

		if (!$cache.newsletterPopupContainer.length) {
			createPopUpContainer();

			$cache.newsletterPopupContainer = $('#js-newsletter-popup-container');
		}
	}

	function createPopUpContainer() {
		var $container = $('<div />').appendTo('body');

		$container.attr('id', 'js-newsletter-popup-container').addClass('l-newsletter_popup');
	}

	function initializeEvents() {
		function allCheckboxesSelected() {
			var $checkedCheckboxes = $cache.selectedForm.find($cache.newsletterSubscriptionTypes.find('input:checkbox:checked'));
			return $checkedCheckboxes.length === $cache.typeCheckboxes.length;
		}
		function selectAllButtonChange() {
			var isAllCheckboxesSelected = allCheckboxesSelected();
			$cache.selectedForm.find($cache.buttonTextSelectAllSelector).toggleClass('h-hidden', isAllCheckboxesSelected);
			$cache.selectedForm.find($cache.buttonTextDeselectAllSelector).toggleClass('h-hidden', !isAllCheckboxesSelected);
			populateMultiChoiceSelection($cache.selectedForm);
		}

		if (
			$cache.newsletterPopupContainer.length
			&& preferences.enableNewsletterPopup
			&& $cache.isCookiesBlockDisplay
			&& !app.preferences.isIOSNativeApp
		) {
			if (app.currentCustomer.getUserClicksNumber() >= preferences.minClicksToShowNewsletterPopup) {
				var nlPopupCount = getNewsletterCookie(), // values: null, 1 or 2
					nlPopupSession = getNewsletterSessionCookie();

				if (isLogInSubscribeTime() || ((nlPopupCount || 0) < 2 && !nlPopupSession)) {
					$cache.popupInitialized = true;
					configs.popupHandler();
				} else {
					$cache.document.trigger('promoBanner.show');
				}
			}
		}

		$cache.subscribeToNewsletterPopup.on('click', function() {
			var categoryID = (app.page.pageData && app.page.pageData.currentCategoryID) || '';
			configs.popupHandler({categoryID: categoryID});
			return false;
		});

		if ($cache.selectAllButton.length) {
			$cache.typeCheckboxes = $cache.selectedForm.find($cache.newsletterSubscriptionTypes.find('input:checkbox'));

			$cache.selectAllButton.on('click', function(e) {
				e.preventDefault();
				if (!allCheckboxesSelected()) {
					$cache.typeCheckboxes.attr('checked', true);
				} else {
					$cache.typeCheckboxes.attr('checked', false);
				}
				selectAllButtonChange();
			});

			$cache.typeCheckboxes.on('change', function() {
				selectAllButtonChange();
				toggleActiveClassSubmitBtn();
			});

			selectAllButtonChange();
		}

		$cache.document.on('click', $cache.sendRemindSel, function(e) {
			e.preventDefault();
			var form = $($cache.sendRemindSelForm);
			if (!form.valid()) {
				return false;
			}
			var url = app.util.appendParamsToUrl(form.attr('action'), {format: 'ajax'});
			var data = form.serialize() + '&' + 'promoCode='+ $($cache.promoCodeSel).text().trim();
			$.ajax({
				url: url,
				type: 'POST',
				data: data
			})
				.done(function(data) {
					app.fancybox.open({
						content: data,
						type: 'html'
					});
				})

				.fail(function() {
					location.href = location.href;
				});

			return false;
		});

		$cache.document.on('click', $cache.openReminderForm, function (){
			var options = {
				type: 'ajax',
				wrapCSS : 'fancybox-sendcoupon',
				afterShow: function() {
					app.validator.init();
					app.util.limitCharacters();
				}
			};
			app.fancybox.open(app.urls.sendCoupon, options);
		});

		$cache.selectedForm.on('submit', function(e) {
			if (app.preferences.emarsys.isNewsletterMultichoice) {
				var formSelector = Array.from(e.target.closest('form').classList).map((className) => '.' + className).join('');

				if (!validateNewsletterSections(formSelector)) {
					return false;
				}
			}
		});
	}

	function showNewsletterPopupOnCurrentPage() {
		var config = app.device.isMobileView() ? app.configs.pagesWhereShowNewsletterPopup.mobile : app.configs.pagesWhereShowNewsletterPopup.desktop;

		return config.indexOf('*') !== -1 || config.indexOf(app.page.currentPage) !== -1;
	}

	function popupHandler(additionalParameters) {
		if ($cache.simpleNewsletterPage || !showNewsletterPopupOnCurrentPage()) {
			return;
		}

		var options = {
			type: 'ajax',
			afterShow: function() {
				$cache.document.trigger('newsletterpopup.opened');
			},
			onUpdate: function(){
				$cache.document.trigger('newsletterpopup.updated');
			},
			beforeClose: function() {
				$cache.document.trigger('newsletterpopup.beforeclosed');
			},
			afterClose: function() {
				// prevent firing afterClose events in jquery.fancybox.js
				// before executing H.removeClass('html_fancybox_opened')
				setTimeout(function() {
					$cache.document.trigger('newsletterpopup.closed');
				});
			},
			tpl: { closeBtn: '<span class="fancybox-close js-close_newsletter" tabindex="0" aria-label="' + resources.NL_CLOSE_ARIALABEL + '" role="button"></span>' },
			wrapCSS : 'l-newsletter_popup_desktop js-newsletter_popup-wrapper' + configs.wrapperClass
		};

		var dataOptions = $cache.newsletterPopupContainer.data('fancyboxOptions');
		if (dataOptions) {
			options = $.extend(true, options, dataOptions);
		}

		if (specificConfigs) {
			options = $.extend(true, options, specificConfigs);
		}

		var url = urls.showNewsletterPopup;
		if (additionalParameters && additionalParameters.categoryID) {
			url = app.util.appendParamToURL(url, 'categoryID', additionalParameters.categoryID);
		}

		if (preferences.isMobileNewsletterEnabled) {
			$cache.document.on('newsletterpopup.opened', function(){
				$('.fancybox-overlay.fancybox-overlay-fixed').off('click.overlay');
				$cache.window.on('orientationchange.fb', function(){
					$cache.window.scrollTop(0);
				});
			});
			options.wrapCSS = 'l-newsletter_popup js-newsletter_popup-wrapper';
			setTimeout(function () {
				app.fancybox.open(app.util.appendParamToURL(url, 'template', preferences.newsletterPopupTmpl), options);
			}, app.util.getConfig('ajaxNewsletter.newsletterPopupOpenTimeout'));
		} else {
			setTimeout(function () {app.fancybox.open(url, options)}, app.util.getConfig('ajaxNewsletter.newsletterPopupOpenTimeout'));
		}
	}

	function validateNewsletterSections(sectionWrapper){
		sectionWrapper = sectionWrapper + ' ' || '';
		var sections = $(sectionWrapper + $cache.sectionSel),
			valid = true,
			errorWrapper = $(sectionWrapper + '.js-formsections_error'),
			signupButtonWrapper = $(sectionWrapper + '.js-register_signup');

		errorWrapper.empty();

		if (sections.length) {
			if (!signupButtonWrapper.length || signupButtonWrapper.find('input:checked').length) {
				var inputs = sections.find('input');

				valid = false;

				for (var i = 0, length = inputs.length; i < length; i++) {
					var input = inputs.eq(i);

					if (input.is(':checked')) {
						valid = true;
						break;
					}
				}

				if (!valid) {
					errorWrapper.text(app.resources.SECTION_MISSED_ERROR);
				}
			}
		}

		return valid;
	}

	function getNewsletterCookie() {
		return $.cookie('nlPopupCount');
	}

	function getNewsletterClosedTimeCookie() {
		return $.cookie('nlPopupClosedTime');
	}

	function getNewsletterSessionCookie() {
		return $.cookie('nlPopupCountSession');
	}

	function diffDays(time, date2) {
		var timeDiff = Math.abs(date2.getTime() - time);
		return Math.ceil(timeDiff / msPerDay);
	}

	function isLogInSubscribeTime() {
		if (app.currentCustomer.isAuthenticated() && !app.currentCustomer.isSubscribed()) {
			var popupClosedTime = getNewsletterClosedTimeCookie() || new Date(),
				popupFrequency = Math.abs(preferences.ecirclePopupFrequency) || 0;
			if (popupFrequency > 0 && diffDays(popupClosedTime, new Date()) > popupFrequency) {
				return true;
			}
		}
		return false;
	}

	function getPopupInitStatus() {
		return $cache.popupInitialized;
	}

	function populateMultiChoiceSelection(form) {
		var newsletterFieldsWrapper = $(form).find($cache.newsletterSubscriptionTypesSelector);
		var newsForElement = newsletterFieldsWrapper.find($cache.newsletterFormNewsforMultiSelector);

		if ($($cache.newsletterFormNewsforMultiSelector).length) {
			var newsforValue = [];

			$(newsletterFieldsWrapper.find($cache.newsletterFormNewsforMultiFieldCheckedSelector)).each(function() {
				var choiceID = $(this).closest($cache.newsletterFormNewsforMultiFieldSelector).data('newsforChoiceId');

				newsforValue.push(choiceID);
			});

			newsForElement.val(newsforValue);
		}
	}

	function toggleActiveClassSubmitBtn() {
		var $checkedCheckboxes = $cache.selectedForm.find('input:checkbox:checked');

		$cache.selectedForm.find($cache.submitBtnSelector).toggleClass($cache.submitBtnDisabledClass, !$checkedCheckboxes.length);
	}

	/*************** app.components.global.newsletter public object ***************/
	app.components = app.components || {};
	app.components.global = app.components.global || {};
	app.components.global.newsletter = {
	// initializations
		init : function (params) {
			initializeConfigs(params);
			initializeCache();
			initializeEvents();
		},
		popupHandler : popupHandler,
		validateNewsletterSections: validateNewsletterSections,
		getPopupInitStatus : getPopupInitStatus
	};
})(window.app = window.app || {}, jQuery);
