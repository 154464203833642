(function(app, $) {
	function CountrySelectorFlyout(config) {
		app.flyouts.Flyout.call(this, 'countrySelector', config);
	}

	CountrySelectorFlyout.prototype = Object.create(app.flyouts.Flyout.prototype);
	CountrySelectorFlyout.prototype.constructor = CountrySelectorFlyout;

	/**
	 * @public
	 * Opens country selector flyout
 	 * @param {Object} params
 	 * @param {String} params.layoutURL - layout URL
	 */
	CountrySelectorFlyout.prototype.open = function(params) {
		this.isOpened = true;

		setMarkup.call(this, params.layoutURL).then(() => {
			if (this.isOpened) {
				app.flyouts.Flyout.prototype.open.call(this, params);
			}
		}).catch(() => {
			this.close();
		});
	};

	/**
	 * @private
	 * Initializes markup
	 */
	CountrySelectorFlyout.prototype.initMarkup = function(params) {
		app.flyouts.Flyout.prototype.initMarkup.call(this, params);

		setDefaultSelectedCountry.call(this);
		initCountryFormSubmitEvent.call(this);
	};

	/**
	 * @private
	 * Sets markup
 	 * @param {String} layoutURL - layout URL
	 * @returns {Promise} - promise with HTML markup
	 */
	function setMarkup(layoutURL) {
		return new Promise((resolve, reject) => {
			if (this.markup) {
				return resolve();
			}

			$.ajax({
				url: layoutURL
			}).done((response) => {
				this.markup = response;

				resolve();
			}).fail(() => {
				reject();
			});
		});
	}

	/**
	 * @private
	 * Sets default selected country
	 */
	function setDefaultSelectedCountry() {
		this.$markup.find(`input[name="countrycode"][value="${app.user.country.value}"]`).prop('checked', true);
	}

	/**
	 * @private
	 * Initializes country form submit event
	 */
	function initCountryFormSubmitEvent() {
		const $changeCountryForm = this.$markup.find('.js-change-country-form');

		$changeCountryForm.submit((event) => {
			event.preventDefault();

			const countryCode = $changeCountryForm.find('input[name="countrycode"]:checked').val();

			if (app.user.country.value === countryCode) {
				this.close();
			} else {
				changeCountryByCountryCode(countryCode);
			}
		});
	}

	/**
	 * @private
	 * Changes country by country code
	 */
	function changeCountryByCountryCode(countryCode) {
		var changeCountryURL = app.util.appendParamsToUrl(app.urls.changeCountry, { Country: countryCode }, true);
		var changeCountryURLwithOrigin = app.components.global.localeselector.addOriginToURL(changeCountryURL);

		var data = {
			url: changeCountryURLwithOrigin,
			selectedCountryCode: countryCode
		};

		app.components.global.localeselector.redirectURL = data.url;
		$(document).trigger('modal.removegiftcert.confirm', data);
	}

	var config = app.configs.flyoutComponent;

	app.flyouts.countrySelector = new CountrySelectorFlyout(config);
})((window.app = window.app || {}), jQuery);
