(function(app, $) {
	var $cache = {};
	var config = {};

	function initializeCache() {
		$cache = {
			filterSelector: '.js-filter',
			filterTitleSelector: '.js-filter .js-filter-title',
			stickyMainWrapper: $('.js-main_search_wrapper'),
			secondaryFilterBlock: $('.js-refinements-sticky'),
			filterLinksSelector: $('.js-filter-sortby-items')
		};
		$cache.filterTitles = $($cache.filterTitleSelector);
	}

	function initializeDOM() {
		config.sortyByItemsHeight = calculateHiddenElement($cache.filterLinksSelector);
		config.refinementBlockPaddingBottom = parseInt($cache.secondaryFilterBlock.css('padding-bottom'));
	}

	function initializeEvents() {
		$cache.filterTitles.on('click', function() {
			var currentFilterSelector = $(this).closest($cache.filterSelector);
			var mainWrapperBottom;

			if ($cache.stickyMainWrapper.length) {
				mainWrapperBottom = $cache.stickyMainWrapper.offset().top + $cache.stickyMainWrapper.outerHeight();
			}

			// manage the opening of sort by when it is on the bottom of document
			if (
				app.preferences.stickyLeftNavigation
				&& app.preferences.sortBySticky
				&& currentFilterSelector.hasClass('js-filter-sort-by')
			) {
				if (currentFilterSelector.hasClass('active')) {
					$cache.filterLinksSelector.removeClass('positioned');
				} else if (
					config.refinementBlockPaddingBottom
					&& config.sortyByItemsHeight <= (2 / 3) * config.refinementBlockPaddingBottom
				) {
					$cache.filterLinksSelector.addClass('positioned');
				}
			}

			currentFilterSelector.toggleClass('active');

			if (
				mainWrapperBottom
				&& app.preferences.stickyLeftNavigation
				&& $cache.secondaryFilterBlock.css('position') === 'fixed'
				&& currentFilterSelector.hasClass('active')
			) {
				var secondaryFilterBottom = $cache.secondaryFilterBlock.offset().top + $cache.secondaryFilterBlock.outerHeight();

				if (secondaryFilterBottom >= mainWrapperBottom) {
					// stick block to bottom on inline level
					$cache.secondaryFilterBlock.css({
						position: 'absolute',
						top: 'auto',
						bottom: 0
					});
				}
			}
		});
	}

	function calculateHiddenElement(element) {
		element.addClass('h-invisible_block');

		var elementHeight = element.height();

		element.removeClass('h-invisible_block');

		return Math.round(elementHeight);
	}

	/**
	 * @namespace app.components.search.filters.custom public object
	 */
	app.components = app.components || {};
	app.components.searchfilters = app.components.searchfilters || {};
	app.components.searchfilters.custom = {
		init: function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));
