(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			owlQuickviewSel: '.js-quickview_content .js-owl_carousel',
			productVarReloadEvent: 'product.variation.reloaded'
		};
	}

	function initializeEvents() {
		$cache.document.on('quickview.beforeShow', function() {
			app.owlcarousel.initCarousel($($cache.owlQuickviewSel));
		});

		$cache.document.on($cache.productVarReloadEvent, function(event, data) {
			if (data && data.mode === 'quickview') {
				var carousel = $($cache.owlQuickviewSel);

				app.owlcarousel.initCarousel(carousel);
			}
		});
	}

	app.components = app.components || {};
	app.components.quickview = app.components.quickview || {};
	app.components.quickview.custom = {
		init: function() {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
