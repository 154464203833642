(function(app, $) {
	let $cache = {};

	function initializeCache(container) {
		$cache = {
			document: $(document),
			container: container,
			productImagesCntr: container.find('.js-product_images_container'),
			serviceFlyoutButtons: container.find('.js-customer_service_links .js-flyout_open'),
			thumbnailsCntrSel: '.js-thumbnails_slider',
			thumbnailItemSel: '.js-thumbnail',
			thumbnailImgSel: '.js-thumbnail_img-item',
			mainImageCntrSel: '.js-container_main_image',
			carouselActiveItemSel: '.js-primary_image',
			carouselImageSel: '.js-producttile_image',
			productImagesCntrSel: '.js-product_images_container',
			carouselWrapperSel: '.js-owl_carousel',
			thumbSelectedCls: 'b-product_thumbnail-selected',
			mainContainerSelector: '.js-pdp_main',
			openColorFlyoutSelector: '.js-open-color-flyout',
			openSizeFlyoutSelector: '.js-variation-button-size',
			openWidthFlyoutSelector: '.js-variation-button-width'
		};
	}

	function initializeCommonEvents() {
		$cache.serviceFlyoutButtons.click(function() {
			const $this = $(this);
			const flyoutTitle = $this.data('title');
			const flyoutContent = $this.parent().find($this.data('contentSelector')).html();
			const customClass = $this.data('customClass');

			app.flyoutMgr.open('static', {
				title: flyoutTitle,
				content: flyoutContent,
				customClass: customClass
			});
		});

		$cache.document.on('click', $cache.openColorFlyoutSelector, (e) => {
			app.flyoutMgr.open('color', {
				colorsData: JSON.parse(e.target.dataset.colorsData),
				keepFocus: true
			});
		});

		$cache.document.on('click', $cache.openSizeFlyoutSelector, () => {
			app.flyoutMgr.open('size', {
				appendToElement: $cache.mainContainerSelector,
				keepFocus: true
			});
		});

		$cache.document.on('click', $cache.openWidthFlyoutSelector, () => {
			app.flyoutMgr.open('width', {
				appendToElement: $cache.mainContainerSelector,
				keepFocus: true
			});
		});

		$cache.document.on('product.tab.carousel.refresh', function(event, $carousel) {
			$carousel.trigger('reload.single.owl');
		});
	}

	function initializeDesktopEvents() {
		$cache.productImagesCntr.on('translated.owl.carousel', $cache.carouselWrapperSel, function() {
			var index = $(this).find($cache.carouselActiveItemSel).find($cache.carouselImageSel).data('imageindex');

			changeActiveThumbnail(index);
			document.dispatchEvent(new CustomEvent('lazyload-reinit'));
		});

		// navigates carousel by clicking on thumbnail
		$cache.productImagesCntr.on('click', $cache.thumbnailImgSel, function() {
			$($cache.productImagesCntrSel).find($cache.carouselWrapperSel).trigger('to.owl.carousel', [$(this).data('thumbnailindex'), 150]);
		});
	}

	function changeActiveThumbnail(index) {
		var $thumbImg = $($cache.thumbnailsCntrSel).find('[data-thumbnailindex=' + index + ']');

		if ($thumbImg.length) {
			$($cache.thumbnailsCntrSel).find($cache.thumbnailItemSel).removeClass($cache.thumbSelectedCls);
			$thumbImg.parent($cache.thumbnailItemSel).addClass($cache.thumbSelectedCls);
		}
	}

	function modifyZoomCntr(params) {
		const container = params.container;

		container.find($cache.mainImageCntrSel).html(container.find($cache.carouselActiveItemSel).attr('style', ''));

		return container;
	}

	function initProductSlider($container) {
		const productImageSliderSelector = '.js-productimageslider';
		const tileSliderPrevBtnSel = '.js-slider-btn-prev';
		const tileSliderNextBtnSel = '.js-slider-btn-next';

		$container.find(productImageSliderSelector).each(function(i, carousel) {
			const $carousel = $(carousel);

			app.owlcarousel.initCarousel($carousel);

			const prevImageMessage = $carousel.data('prev-slide-message');
			const nextImageMessage = $carousel.data('next-slide-message');
			const $prevSlideBtn = $carousel.find(tileSliderPrevBtnSel);
			const $nextSlideBtn = $carousel.find(tileSliderNextBtnSel);

			$prevSlideBtn.attr('aria-label', prevImageMessage);
			$nextSlideBtn.attr('aria-label', nextImageMessage);
		});
	}

	/**
	* @namespace app.product.custom public object
	*/
	app.components = app.components || {};
	app.components.product = app.components.product || {};
	app.components.product.custom = {
		init: function(params) {
			const container = 'container' in params && params.container instanceof $ ? params.container.find('.js-pdp_primary_content') : $('.js-pdp_primary_content');

			if (!container || container.hasClass('js-product-custom-initialized')) {
				return;
			}

			app.product.modifyZoomCntr = modifyZoomCntr;
			initializeCache(container);
			initializeCommonEvents();

			if (!app.device.isMobileUserAgent()) {
				initializeDesktopEvents();
			}

			container.addClass('js-product-custom-initialized');
		},
		initProductSlider
	};
}(window.app = window.app || {}, jQuery));
