(function(app) {
	function FlyoutMgr() {
		this.openedFlyouts = [];
	}

	FlyoutMgr.prototype.open = function(id, params) {
		params = params || {};

		let openPromiseResolve;
		const openPromise = new Promise(function(resolve) {
			openPromiseResolve = resolve;
		});

		if (this.isOpen(id)) {
			openPromiseResolve();

			return openPromise;
		}

		for (var i = 0, len = this.openedFlyouts.length; i < len; i++) {
			if (this.openedFlyouts[i].type !== id) {
				params.lastFocus = this.openedFlyouts[i].focusedElementBeforeFlyoutOpened;
			}
		}

		const openFlyout = () => {
			if (id in app.flyouts) {
				const openingFlyout = app.flyouts[id].open(params);
				// In some cases, the flyout can be declined for opening

				if (typeof openingFlyout === 'object' && typeof openingFlyout.then === 'function') {
					openingFlyout.then((markup) => openPromiseResolve(markup));
				} else if (app.flyouts[id].isOpened) {
					this.openedFlyouts.push(app.flyouts[id]);
					openPromiseResolve();
				}
			}
		};

		if (isOpenFlyoutOnAnimationEnd.call(this)) {
			let resolveDeferred;
			const deferred = new Promise(function(resolve) {
				resolveDeferred = resolve;
			});

			params.openFlyoutDeferred = resolveDeferred;
			this.closeAll(params);
			deferred.then(function() {
				openFlyout();
			});
		} else {
			this.closeAll(params);
			openFlyout();
		}

		return openPromise;
	};

	FlyoutMgr.prototype.close = function(id, params) {
		if (id in app.flyouts) {
			this.flyoutIsClosed(app.flyouts[id].type);

			return app.flyouts[id].close(params);
		}
	};

	FlyoutMgr.prototype.update = function(id) {
		for (var i = 0, len = this.openedFlyouts.length; i < len; i++) {
			if (this.openedFlyouts[i].type === id) {
				this.openedFlyouts[i].update();
				break;
			}
		}
	};

	FlyoutMgr.prototype.isOpen = function(id) {
		for (var i = 0, len = this.openedFlyouts.length; i < len; i++) {
			if (this.openedFlyouts[i].type === id) {
				return true;
			}
		}

		return false;
	};

	FlyoutMgr.prototype.closeAll = function(params) {
		for (var i = 0, len = this.openedFlyouts.length; i < len; i++) {
			this.openedFlyouts[i].close(params);
		}

		return params;
	};

	FlyoutMgr.prototype.flyoutIsClosed = function(flyout) {
		for (let i = 0, len = this.openedFlyouts.length; i < len; i++) {
			if (this.openedFlyouts[i].type === flyout) {
				this.openedFlyouts.splice(this.openedFlyouts.indexOf(this.openedFlyouts[i]), 1);
				break;
			}
		}
	};

	/**
	* @function
	* @description checks if flyout should be opened on animation end event and current active flyout
	* @returns {Boolean}
	*/
	function isOpenFlyoutOnAnimationEnd() {
		return this.openedFlyouts.length && app.configs.flyoutComponent.isOpenOnAnimationEndEnabled;
	}

	app.flyoutMgr = new FlyoutMgr();
})((window.app = window.app || {}), jQuery);
