import Observable from './observable';

class Wishlist extends Observable {
	constructor() {
		super();
		this.markup = {
			lineItemsQuantity: 0,
			products: [],
			prices: {}
		};
		requestWishlist.call(this);
	}

	update(data) {
		Object.assign(this.markup, data);
		notifySubscribers.call(this);

		if (app.trackerData) {
			app.trackerData.wishlistInfo = app.wishlist.trackerData;
		}
	}
}

function requestWishlist() {
	fetch(app.urls.getFlyoutWishlist, {
		credentials: 'include'
	}).then((res) => res.json())
		.then((json) => this.update(json))
		.then(() => document.dispatchEvent(new CustomEvent('wishlist.storage.init')));
}

function notifySubscribers() {
	this.notify('updateWishlistQuantity', {
		quantity: +this.markup.lineItemsQuantity
	});
}

app.wishlist = Object.assign(new Wishlist(), app.wishlist);
