import VideoAdapter from '.';

class VimeoAdapter extends VideoAdapter {
	constructor($el, callback, uuid, parentOptions = {}) {
		super(...arguments);

		this.volumeStep = 0.1;

		let options = {
			id: $el.find('source').attr('src'),
			width: $el.width(),
			controls: $el.attr('controls') === 'controls',
			toggleOnClick: true,
			parentOptions: parentOptions
		};
		const smOptions = $el.data('vimeo-options');

		if (typeof smOptions === 'object') {
			options = $.extend({}, options, smOptions);
		}

		const $newEl = $('<div />', { class: 'b-videowrapper js-videowrapper' });

		$el.replaceWith($newEl);

		const isShowOverlay = options.parentOptions && options.parentOptions.showOverlay;

		if (!options.controls || isShowOverlay) {
			$newEl.css('position', 'relative');
			$newEl.addClass('js-media-content');

			const $overlay = $('<div class="b-videomgr_iframe-overlay" />');

			$overlay.css({
				position: 'absolute',
				top: '0',
				left: '0',
				width: '100%',
				height: '100%'
			});

			if (options.toggleOnClick) {
				$overlay.click(() => {
					this.togglePlayPause();
				});
			}

			$newEl.append($overlay);
		}

		this.player = init($newEl, () => {
			this.player.getVolume().then((volume) => {
				this.volume = volume;
			});

			this.player.getMuted().then((muted) => {
				this.muted = muted;
				this.notify('volumeChange', {
					muted: muted,
					volume: this.volume
				});
			});

			this.notify('playerReady', { options: options });
		}, options);

		this.player.on('play', () => {
			callback();
			this.playing = true;
			this.notify('play');
		});

		this.player.on('pause', () => {
			this.playing = false;
			this.notify('pause');
		});

		this.player.on('volumechange', (data) => {
			this.volume = data.volume;

			this.player.getMuted().then((muted) => {
				this.muted = muted;
				this.notify('volumeChange', {
					muted: this.muted,
					volume: data.volume
				});
			});
		});

		this.player.on('timeupdate', (data) => {
			this.notify('timeupdate', data);
		});

		this.player.on('ended', () => {
			this.notify('ended');
		});
	}

	play(source = null) {
		this.notify('localPlay', { source: source });
		this.player.play().then(() => {
			setTimeout(() => {
				this.notify('afterLocalPlay', { source: source });
			}, 500);
		});
	}

	pause(source = null) {
		this.notify('localPause', { source: source });
		this.player.pause().then(() => {
			setTimeout(() => {
				this.notify('afterLocalPause', { source: source });
			}, 500);
		});
	}

	changeVolume(newVolume) {
		this.player.setVolume(newVolume).then((changed) => {
			this.volume = changed;
		});
	}

	volumeUp() {
		const newVolume = Math.min(this.volume + this.volumeStep, 1);

		this.changeVolume(newVolume);
	}

	volumeDown() {
		if (this.volume > 0) {
			const newVolume = Math.max(this.volume - this.volumeStep, 0);

			this.changeVolume(newVolume);
		}
	}

	toggleMute() {
		this.player.getMuted().then((muted) => {
			this.player.setMuted(!muted).then((newMuted) => {
				this.muted = newMuted;
			});
		});
	}

	mute() {
		this.player.setMuted(true).then(() => {
			this.muted = true;
		});
	}

	seek(seconds) {
		return this.player.setCurrentTime(seconds);
	}
}

const init = ($el, callback, options) => {
	if (typeof Vimeo !== 'object') {
		throw new Error('Vimeo player not loaded');
	}

	var player = new Vimeo.Player($el, options);

	player.ready().then(() => {
		callback();
	});

	return player;
};

export default VimeoAdapter;
