(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			document: $(document),
			productShareText: $('.js-product-share-text'),
			productShareSel: '.js-product-share',
			hoverClass: 'b-product_share-tablet'
		};

		$cache.productShare = $($cache.productShareSel);
	}

	function initializeEvents() {
		// emulate tablet onhover effect
		if (app.device.isTabletUserAgent()) {
			$cache.productShareText.on('touchstart', function(e) {
				e.preventDefault();
				e.stopPropagation();
				$cache.productShare.addClass($cache.hoverClass);
			});

			$cache.document.on('touchstart', function(e) {
				if (!$(e.target).closest($cache.productShareSel)[0]) {
					$cache.productShare.removeClass($cache.hoverClass);
				}
			});

			$cache.document.on('social.clicked', function() {
				$cache.productShare.removeClass($cache.hoverClass);
			});
		}
	}

	/* app.components.social.custom public object */
	app.components = app.components || {};
	app.components.social = app.components.social || {};
	app.components.social.custom = {
		init: function() {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
