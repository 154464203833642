(function(app, $) {
	var $doc = $(document);
	var $html = $('html');

	function Popup(type) {
		this.type = type;
		this.isOpened = false;
		this.markup = '';
		this.$markup = $();
		this.isApplyFocusEnabled = true;
	}

	Popup.prototype.open = function(params) {
		this.variant = (params && params.variant) || 'primary';
		this.$markup = $(getMarkup.call(this));
		this.$markup.appendTo($('body'));
		this.initMarkup();
		this.isOpened = true;
	};

	Popup.prototype.close = function() {
		if (this.isOpened) {
			if (this.focusedElementBeforePopupOpened) {
				this.focusedElementBeforePopupOpened.focus();
			}

			this.isOpened = false;
		}

		$doc.off('keydown.' + this.type);
		$doc.off('click.' + this.type);
		$doc.off('dialog.showed.' + this.type);
		$doc.off('fancybox.closed.' + this.type);
		this.removeFromDOM();
		$html.removeClass('lock-scroll');

		app.popupsMgr.popupIsClosed(this);
	};

	Popup.prototype.initMarkup = function() {
		this.$markup.find('.js-popup-close-button').on('click', this.close.bind(this));
		this.$markup.find('.js-open_popup-link').on('click', openPopupByDataAttribute.bind(this));

		var $content = this.$markup.find('.js-popup-content');
		if ($content.data('closeOnEsc') === true) {
			$doc.on('keydown.' + this.type, onEscKeyDownHandler.bind(this));

			$doc.on('dialog.showed.' + this.type, function() {
				$doc.off('keydown.' + this.type);
			}.bind(this));

			$doc.on('fancybox.closed.' + this.type, function() {
				$doc.on('keydown.' + this.type, onEscKeyDownHandler.bind(this));
			}.bind(this));
		}
		if ($content.data('closeOnOutsideClick') === true) {
			$doc.on('click.' + this.type, onOutsideClickHandler.bind(this));
		}

		if ($content.data('lock-scroll')) {
			$html.addClass('lock-scroll');
		}

		if (this.isApplyFocusEnabled) {
			applyFocusFunctionality.call(this, this.$markup);
		}
	};

	Popup.prototype.removeFromDOM = function() {
		if (this.$markup instanceof jQuery && this.$markup.length > 0) {
			this.$markup.remove();
		}
	};

	Popup.prototype.setHeightCSSVariable = function(el) {
		const popupElement = this.$markup.find('.js-popup')[0];
		const targetEl = el || popupElement;

		targetEl.style.setProperty('--popup-height', popupElement.offsetHeight + 'px');
	};

	function getMarkup() {
		return app.util.renderTemplate($('#popupTemplate').html(), {
			type: this.type,
			markup: this.markup,
			variant: this.variant
		});
	}

	function applyFocusFunctionality($markup) {
		/**
		 * TODO: This should be done without check. By default browser set body as document.activeElement when
		 * non of the elements on the page is focused.
		 * We should implement something like focus on first element in DOM when page is loaded.
		 * https://allyjs.io/data-tables/focusable.html
		 * */
		this.focusedElementBeforePopupOpened = document.activeElement === document.body ? $('body').find(':focusable').get(0) : document.activeElement;

		var $focusableElements = $markup.find(':focusable');

		if ($focusableElements.length) {
			this.firstFocusableElement = $focusableElements.first().get(0);
			this.lastFocusableElement = $focusableElements.last().get(0);
			this.firstFocusableElement.focus();
		}

		$markup.on('keydown.' + this.type, onFocusKeyDownHandler.bind(this));
	}

	function onFocusKeyDownHandler(e) {
		var self = this;
		var KEY_CODE_TAB = 9;

		if (e.keyCode === KEY_CODE_TAB) {
			if (e.shiftKey) {
				handleBackwardTab(e);
			} else {
				handleForwardTab(e);
			}
		}

		function handleBackwardTab(event) {
			if (document.activeElement === self.firstFocusableElement) {
				event.preventDefault();
				self.lastFocusableElement.focus();
			}
		}

		function handleForwardTab(event) {
			if (document.activeElement === self.lastFocusableElement) {
				event.preventDefault();
				self.firstFocusableElement.focus();
			}
		}
	}

	function onEscKeyDownHandler(e) {
		var KEY_CODE_ESC = 27;
		if (e.keyCode === KEY_CODE_ESC) {
			this.close();
		}
	}

	function openPopupByDataAttribute(e, closeOpenedPopups = true) {
		const anotherPopupLinkElement = e.currentTarget;
		const popupId = anotherPopupLinkElement.getAttribute('data-popup-id');

		if (!popupId) return false;

		const popupTemplateId = anotherPopupLinkElement.getAttribute('data-popup-template') || `${popupId}Template`;
		const popupVariant = anotherPopupLinkElement.getAttribute('data-popup-variant') || this.variant || 'primary';

		if (closeOpenedPopups) {
			app.popupsMgr.closeAll();
		}

		app.popupsMgr.open(popupId, {
			templateId: popupTemplateId,
			variant: popupVariant
		});
	}

	function onOutsideClickHandler(e) {
		if ($(e.target).closest('.js-popup').length === 0 && !app.fancybox.isFancyboxEvent(e)) {
			this.close();
		}
	}

	app.popups = {};
	app.popups.Popup = Popup;
})((window.app = window.app || {}), jQuery);
