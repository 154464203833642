(function(app) {
	const selectors = {
		swatches: '.js-swatchanchor'
	};

	const templates = {
		flyout: 'widthFlyoutTemplate'
	};

	function WidthFlyout(config) {
		app.flyouts.Flyout.call(this, 'width', config);
	}

	WidthFlyout.prototype = Object.create(app.flyouts.Flyout.prototype);
	WidthFlyout.prototype.constructor = app.flyouts.Flyout;

	WidthFlyout.prototype.open = function(params) {
		this.markup = getMarkup();

		app.flyouts.Flyout.prototype.open.call(this, params);
	};

	WidthFlyout.prototype.close = function(keepFocus) {
		app.flyouts.Flyout.prototype.close.call(this, keepFocus);
	};

	WidthFlyout.prototype.initMarkup = function(params) {
		const widthSwatchNodes = document.querySelectorAll(selectors.swatches);

		for (const widthSwatchNode of widthSwatchNodes) {
			widthSwatchNode.addEventListener('click', () => {
				this.close(params);
			});
		}

		app.flyouts.Flyout.prototype.initMarkup.call(this, params);
	};

	function getMarkup() {
		const template = document.getElementById(templates.flyout);

		return app.util.renderTemplate(template.innerHTML);
	}

	app.flyouts.width = new WidthFlyout(app.configs.flyoutComponent);
})((window.app = window.app || {}), jQuery);
