/**
 * The component shows correspond banner for each 2-nd level category.
 * If category does not have a banner then top category banner showed.
 *
 * Menu structure
 *
 * - TopCategory1	   (banner1default)
 *   - Level2category11 (banner11)
 *   - Level2category12 (banner12)
 * - TopCategory2	   (banner2default)
 *   - Level2category21 (banner21)
 *   - Level2category22 (banner22)
 *
 */
(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			timerIdOnHide: null,
			timerIdOnShow: null,
			body: $('body'),
			navigationWrap: $('.js-menu_subcategory_wrapper'),
			document: $(document),
			disableLinks: '.l-main_navigation.m-active .b-menu_category-link',
			mActiveLevel2Links: '.l-main_navigation.m-active .b-menu_category-level_2-link',
			withSubcategories: 'm-with_subcategories',
			hHidden: 'h-hidden',
			tabletHeaderMenuNav: 'tablet-header-menu-nav',
			level3CategoryMenu: 'b-menu_category-level_3',
			jsCtgChosenSelector: '.js-ctg-chosen',
			jsCtgChosenClass: 'js-ctg-chosen',
			jsMenuSubcatWrapper: '.js-menu_subcategory_wrapper',
			jsMenuLevel2ItemSel: '.js-menu_category-level_2-item',
			mWithoutBannerClass: 'm-without_banner',
			jsMenuCtg2BannerDef: '.js-menu_category-level_2-banner-default',
			jsMenuCtg2Banner: '.js-menu_category-level_2-banner',
			menuOpenedClass: 'm-menu_opened',
			currentCategoryCls: 'm-current',
			currentCategoryMobileCls: 'm-current_category',
			navigation: $('#navigation')
		};
	}

	// auxiliary function for displaying subcategories with delay
	function showItem() {
		var $thisLevel = $(document).find($cache.jsCtgChosenSelector);
		var $wrapper = $thisLevel.find($cache.jsMenuSubcatWrapper);

		if ($cache.timerIdOnHide) {
			return false;
		}

		$wrapper.show();

		if ($thisLevel.hasClass($cache.jsCtgChosenClass)) {
			$cache.body.addClass($cache.menuOpenedClass);
		}
	}

	// show navigation flyout on mouseenter
	function showFlyoutOnMouseEnter($wrapper, $thisLevel, $allBanners, $defaultBanner) {
		$wrapper.show();
		$thisLevel.toggleClass($cache.jsCtgChosenClass);
		$cache.navigationWrap.removeClass($cache.mWithoutBannerClass);
		$allBanners.hide();
		$defaultBanner.show();
		$cache.body.addClass($cache.menuOpenedClass);

		// if there is no default banner for category
		if (!$defaultBanner.html()) {
			$cache.navigationWrap.addClass($cache.mWithoutBannerClass);
		}

		$cache.timerIdOnShow = null;

		// bind mouseenter event handler for 2nd level onetime only
		if (!$thisLevel.data('isInitedlevel2')) {
			$thisLevel.data('isInitedlevel2', true);

			$thisLevel.find('.js-menu_category-level_2-link').on('mouseenter', function() {
				var $this = $(this);
				var $banner = $defaultBanner;
				var index = $this.attr('data-slot');

				if (index) {
					var $curBanner = $thisLevel.find('.js-menu_category-level_2-banner-' + index);

					if ($curBanner.html()) {
						$banner = $curBanner;
						$cache.navigationWrap.removeClass($cache.mWithoutBannerClass);
					} else {
						$cache.navigationWrap.addClass($cache.mWithoutBannerClass);
					}
				}

				$allBanners.hide();
				$banner.show().addClass('m-active').siblings('div').removeClass('m-active');
			});
		}
	}

	function ipadTriggerMouseleave(hoveredItem) {
		var chosenItem = $($cache.jsCtgChosenSelector);

		if (
			navigator.userAgent.match(/iPad/i)
			&& chosenItem.length > 0
			&& !hoveredItem.hasClass($cache.jsCtgChosenClass)
		) {
			chosenItem.trigger('mouseleave');
		}
	}

	function markCurrentCategory() {
		var categoryIDArr = app.trackerData.categoryIDPathGPF || app.trackerData.productCategoryIDPathGPF || [];

		if (categoryIDArr.length) {
			var currentCategoryId = categoryIDArr[categoryIDArr.length - 1];
			var currentCategoryLink = $cache.navigation.find(`a[data-category=${currentCategoryId}]`);
			var currentCategoryClass = app.device.isMobileUserAgent() ? $cache.currentCategoryMobileCls : $cache.currentCategoryCls;

			currentCategoryLink.addClass(currentCategoryClass);
		}
	}

	function initializeEvents() {
		// prevents plp open on tablet | opens level3 category list, when click on level2 category
		$cache.document.on('click', $cache.mActiveLevel2Links, function(e) {
			var $this = $(this);
			var currentItem = $this.parents($cache.jsMenuLevel2ItemSel);
			var subMenu = currentItem.find($cache.jsMenuSubcatWrapper);

			if (currentItem.hasClass($cache.withSubcategories) && subMenu.size() > 0) {
				e.preventDefault();
				currentItem.siblings($cache.jsMenuLevel2ItemSel).children($cache.jsMenuSubcatWrapper).addClass($cache.hHidden);
				subMenu.toggleClass($cache.hHidden);
			}
		});

		// top level on-mouseenter
		$('.js-menu_category-item').on('mouseenter', function(e) {
			e.preventDefault();
			var $thisLevel = $(this);
			var $defaultBanner = $thisLevel.find($cache.jsMenuCtg2BannerDef);
			var $allBanners	= $thisLevel.find($cache.jsMenuCtg2Banner);
			var $wrapper = $thisLevel.find($cache.jsMenuSubcatWrapper);

			if (!$thisLevel.hasClass($cache.withSubcategories) && (!$defaultBanner.length || !$allBanners.length)) {
				return false;
			}

			ipadTriggerMouseleave($thisLevel);

			if ($cache.timerIdOnHide) {
				$thisLevel.toggleClass($cache.jsCtgChosenClass);

				return false;
			} else if (app.device.currentDevice() === 'desktop') {
				$cache.timerIdOnShow = setTimeout(function() {
					showFlyoutOnMouseEnter($wrapper, $thisLevel, $allBanners, $defaultBanner);
				}, 500);
			} else {
				showFlyoutOnMouseEnter($wrapper, $thisLevel, $allBanners, $defaultBanner);
			}
		});

		// hide subcategories block with a short delay
		$('.js-menu_category-item').on('mouseleave', function() {
			clearTimeout($cache.timerIdOnShow);

			if ($cache.timerIdOnShow) {
				$cache.timerIdOnShow = null;
			} else {
				var $thisLevel = $(this);

				if ($thisLevel.hasClass($cache.jsCtgChosenClass)) {
					var $wrapper = $thisLevel.find($cache.jsMenuSubcatWrapper);

					$thisLevel.toggleClass($cache.jsCtgChosenClass);
					$cache.timerIdOnHide = setTimeout(function() {
						$wrapper.hide();
						$cache.timerIdOnHide = null;
						$cache.body.removeClass($cache.menuOpenedClass);
						showItem();
					}, 600);
				}
			}
		});

		$cache.document.on('click', $cache.disableLinks, function(e) {
			if ($(this).parents('div.' + $cache.tabletHeaderMenuNav).length === 0 && $(this).closest('li').find('ul').length) {
				e.preventDefault();
			}
		});

		markCurrentCategory();
	}

	/**
	 * @namespace app.components.global.categoryflyout public object
	 */
	app.components = app.components || {};
	app.components.categoryflyout = app.components.categoryflyout || {};
	app.components.categoryflyout.custom = {
		init: function() {
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
