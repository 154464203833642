/**
 * Components specific config
 */
(function(app, $) {
	var componentsConfig = {
		global: {
			components: {
				'global.toggler': { enabled: false },
				'global.searchplaceholder': { enabled: false },
				'toggler.custom': {
					headerTopSel: !app.preferences.isMobileView ? '.js-header_main-top' : '.js-header_service_menu',
					disableUpdatePaddingTop: !app.device.isMobileView()
				},
				'global.categoriesNavigation': {
					disableToggleOnSwipe: app.preferences.isMobileView
				},
				'quickview.custom': {},
				'global.scrollToTop': {},
				'global.newsletter': {},
				'global.accordion': {},
				'newsletter.handlepopup': {},
				'categoryflyout.custom': {},
				'wishlist.custom': {},
				'account.addresses': { width: '50%', height: 'auto', autoSize: false },
				'account.paymentinstruments': { width: '50%', height: 'auto', autoSize: false },
				'global.navigation': { adjustLeftNavHeightOnResize: true },
				'global.simplesearch': {
					simpleSearchHandler: app.simpleSearchFunctions && app.simpleSearchFunctions.simpleSearchHandler,
					successSimpleSearchCallback: app.simpleSearchFunctions && app.simpleSearchFunctions.successSimpleSearchCallback
				},
				'global.clusterlazyload': {},
				'global.firstvisitbanner': { updateHeader: !app.preferences.isMobileView },
				'footer.custom': {}
			}
		},
		search: {
			components: {
				'search.custom': {},
				'searchfilters.custom': {},
				'recommendationblock.custom': {},
				'search.gridcolumnswitcher': {
					list: ['four', 'three', 'two', 'one'],
					default: {
						desktop: 'four',
						tablet: {
							landscape: 'four',
							portrait: 'three'
						},
						mobile: 'two'
					}
				}
			}
		},
		product: {
			components: {
				'social.custom': {},
				'product.custom': {},
				'recommendationblock.custom': {}
			}
		},
		checkout: {
			components: {
				'checkout.custom': {},
				'recommendationblock.custom': {}
			}
		},
		storefront: {
			components: {
				'storefront.custom': {},
				'loadmore.custom': {},
				'global.quickview': { quickViewBlockSel: '.js-quickview' },
				'global.videoMgr': { elementSelector: '.js-videomgr' }
			}
		},
		contentclusters: {
			components: {
				'cluster.custom': {}
			}
		},
		orderhistory: {
			components: {
				'account.navigation': {}
			}
		},
		error: {
			components: {
				'recommendationblock.custom': {}
			}
		},
		feedpage: {
			components: {
				'global.videoMgr': { elementSelector: '.js-videomgr' }
			}
		}
	};

	/* publish app.components.config.specific */
	app.componentsconfig = app.componentsconfig || {};
	app.componentsconfig.specific = componentsConfig;
}(window.app = window.app || {}, jQuery));
