/**
 * The name of the recent search item in local storage.
 * @type {string}
 */
const RECENT_SEARCH_NAME = `${app.preferences.searchProvider}-recent-search`;

/**
 * Represents a utility class for managing recent search queries.
 */
class RecentSearch {
	/**
	 * Retrieves the recent search queries from local storage.
	 * @returns {Array<string>} An array of recent search queries.
	 */
	getQueries() {
		const recentSearchNames = window.localStorage.getItem(RECENT_SEARCH_NAME);

		return JSON.parse(recentSearchNames) ?? [];
	}

	/**
	 * Retrieves a limited number of recent search queries from the end of the list.
	 * @param {number} limit - The maximum number of recent search queries to retrieve.
	 * @returns {Array<string>} An array of limited recent search queries.
	 */
	getLimitedQueries(limit) {
		const queries = this.getQueries();

		return queries.slice(-limit);
	}

	/**
	 * Adds a new search query to the recent searches.
	 * @param {string} query - The search query to add.
	 */
	addQuery(query) {
		const searchQueries = this.getQueries();

		if (!query?.length) {
			return;
		}

		if (!searchQueries.length) {
			setRecentSearch([query]);

			return;
		}

		const lastSearch = searchQueries[searchQueries.length - 1];

		if (searchQueries.includes(query) || lastSearch.includes(query)) {
			return;
		}

		if (query?.startsWith(lastSearch)) {
			searchQueries[searchQueries.length - 1] = query;

			setRecentSearch(searchQueries);

			return;
		}

		setRecentSearch([...searchQueries, query]);
	}

	/**
	 * Removes a search query from the recent searches.
	 * @param {string} query - The search query to remove.
	 */
	removeQuery(query) {
		const queries = this.getQueries();

		setRecentSearch(queries.filter((value) => query !== value));
	}
}

/**
 * Sets the recent search queries in local storage.
 * @param {Array<string>} recentSearch - An array of recent search queries.
 */
const setRecentSearch = (recentSearch) => {
	window.localStorage.setItem(RECENT_SEARCH_NAME, JSON.stringify(recentSearch));
};

export default new RecentSearch();
