import AlgoliaSearchProvider from 'oneapp/src/classes/search/providers/AlgoliaSearchProvider';
import SFCCSearchProvider from 'oneapp/src/classes/search/providers/SFCCSearchProvider';

/**
 * Represents a factory to create different types of search providers.
 */
class SearchProviderFactory {
	/**
	 * Creates a new instance of the SearchProviderFactory.
	 */
	constructor() {}

	/**
	 * Creates a search provider based on the specified provider type.
	 * @param {string} providerType - The type of the search provider ('algolia' or 'sfcc').
	 * @returns {Object} A new instance of the specified search provider.
	 */
	create(providerType) {
		switch (providerType) {
			case 'algolia': {
				/**
				 * Represents a search provider that uses Algolia for search.
				 * @typedef {AlgoliaProvider} AlgoliaProvider
				 */
				return new AlgoliaSearchProvider(app.preferences.algolia);
			}

			case 'sfcc': {
				/**
				 * Represents a search provider that uses SFCC for search.
				 * @typedef {SFCCSearchProvider} SFCCSearchProvider
				 */
				return new SFCCSearchProvider();
			}

			default: {
				return null;
			}
		}
	}
}

export default new SearchProviderFactory();
