(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			wishlistElement: {},
			header: $('header'),
			document: $(document),
			loginToggler: $('.js-login_dropdown-title.js-toggler'),
			loginTogglerContent: $('.js-login_dropdown'),
			multiTemplate: $('#multiwishlist').html(),
			wishlistAddToCartSel: '.js-add_to_cart_button',
			carouselOwlSel: '.js-wishlist_dropdown-flyout .js-owl_carousel',
			wishlistEmptyClass: 'b-wishlist_empty',
			wishlistAddedClass: 'b-add_to_wishlist--added'
		};

		$cache.wishlistAddedSel = '.' + $cache.wishlistAddedClass;
	}

	function initializeHelpers() {
		app.components.account.fakelogin.show = function() {
			$cache.document.trigger('wishlist.notauthenticated');
		};
	}

	function initializeEvents() {
		$cache.document.on('wishlist.beforeadded', function(event, element) {
			$cache.wishlistElement = element;
		});

		$cache.document.on('wishlist.notauthenticated', function() {
			$cache.loginToggler.trigger('click', 'wishlist');
		});
	}

	app.components = app.components || {};
	app.components.wishlist = app.components.wishlist || {};
	app.components.wishlist.custom = {
		init: function() {
			initializeHelpers();
			initializeCache();
			initializeEvents();
		}
	};
})(window.app = window.app || {}, jQuery);
