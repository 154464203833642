(function(app, $) {
	function StaticFlyout(config) {
		app.flyouts.Flyout.call(this, 'static', config);
	}

	StaticFlyout.prototype = Object.create(app.flyouts.Flyout.prototype);
	StaticFlyout.prototype.constructor = StaticFlyout;

	/**
	 * @public
	 * Opens static flyout
 	 * @param {Object} params
 	 * @param {String|jQuery|HTMLElement} params.title - flyout title
 	 * @param {String|jQuery|HTMLElement} params.content - flyout content
	 */
	StaticFlyout.prototype.open = function(params) {
		this.markup = getMarkup(params);

		if (params.direction) {
			this.direction = params.direction;
		}

		app.flyouts.Flyout.prototype.open.call(this, params);
	};

	/**
	 * @private
	 * Initializes markup
	 */
	StaticFlyout.prototype.initMarkup = function(params) {
		app.flyouts.Flyout.prototype.initMarkup.call(this, params);
	};

	/**
	 * @private
	 * Sets markup
 	 * @param {Object} params
 	 * @param {String|jQuery|HTMLElement} params.title - flyout title
 	 * @param {String|jQuery|HTMLElement} params.content - flyout content
	 * @returns {String} - HTML string markup
	 */
	function getMarkup(params) {
		const $flyoutWholeContent = $('<div></div>');
		const $flyoutContainer = $(`<div class="b-slide-flyout_container ${params.customClass || ''}"></div>`);

		if (params.title) {
			const $title = $('<div class="b-flyout-static-title"></div>').append(params.title);

			$flyoutContainer.append($title);
		}

		if (params.content) {
			const $content = $('<div class="b-flyout-static-content"></div>').append(params.content);

			$flyoutContainer.append($content);
		}

		$flyoutWholeContent.append($flyoutContainer);

		return $flyoutWholeContent.html();
	}

	var config = app.configs.flyoutComponent;

	app.flyouts.static = new StaticFlyout(config);
})((window.app = window.app || {}), jQuery);
