(function(app, $) {
	function RequestFormPopup() {
		app.popups.Popup.call(this, 'requestFormPopup');
	}

	RequestFormPopup.prototype = Object.create(app.popups.Popup.prototype);
	RequestFormPopup.prototype.constructor = app.popups.Popup;

	RequestFormPopup.prototype.open = function(params) {
		params = params || {};

		if (params.onConfirm) {
			this.onConfirm = params.onConfirm;
		}

		this.markup = getMarkup();
		app.popups.Popup.prototype.open.call(this);
	};

	RequestFormPopup.prototype.initMarkup = function() {
		this.$markup.find('.js-confirm').on('click', function() {
			this.onConfirm();
			this.close();
		}.bind(this));

		this.$markup.find('.js-cancel').on('click', this.close.bind(this));

		app.popups.Popup.prototype.initMarkup.call(this);
	};

	function getMarkup() {
		return app.util.renderTemplate($('#requestFormPopupTemplate').html());
	}

	app.popups.RequestFormPopup = new RequestFormPopup();
})((window.app = window.app || {}), jQuery);
