/**
 * Represents a utility class for formatting prices based on the user's currency.
 */
class Price {
	/**
	 * Formats the provided price to a currency string based on the user's currency.
	 * @param {Object} priceData - The price data object containing the `listPrice` property.
	 * @param {number} priceData.listPrice - The price to be formatted.
	 * @returns {string} The formatted price as a currency string.
	 */
	getFormattedPrice({ listPrice } = {}) {
		const config = {
			style: 'currency',
			currency: app.user.currencyCode,
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
			trailingZeroDisplay: 'stripIfInteger'
		};

		return new Intl.NumberFormat(`en-${app.user.country.value}`, config).format(listPrice);
	}

	/**
	 * Decodes a formatted price string to extract the plain text value.
	 * @param {string} formattedPrice - The formatted price string to decode.
	 * @returns {string} The plain text representation of the price.
	 */
	decodeFormattedPrice(formattedPrice) {
		const doc = new DOMParser().parseFromString(formattedPrice, 'text/html');
		const body = doc.querySelector('body');

		return body.textContent;
	}
}

export default new Price();
