(function(app, $) {
	var $cache = {};

	function initializeCache() {
		$cache = {
			window: $(window),
			document: $(document),
			body: $('body'),
			searchWrapper: $('.js-search_result-content'),
			refinementsSticky: $('.js-refinements-sticky'),
			lastVisited: $('.js-last-visited-wrapper'),
			tileImageSLider: $('.js-productimageslider'),
			filterToggler: '.js-filter-toggler',
			filterFlyout: '.js-filter-flyout',
			clearFiltersBtn: '.js-clear_search_filters-link',
			hHiddenMinor: 'h-hidden_minor',
			refinementCheckbox: '.js-refinement_checkbox',
			searchActionsTopBar: '.js-search_actions_top_bar',
			tabContent: '.js-filter-tab_content',
			filterSubmitBtn: '.js-filter_submit',
			selectors: {
				notifyMeLink: '.js-notifyme_link',
				owlCarousel: '.js-owl_carousel'
			},
			classes: {
				showFilter: 'm-show',
				filterOpened: 'm-filters-opened',
				tabsOpenedClass: 'm-tabs-opened',
				tabContentVisible: 'b-search_actions_top_bar--open',
				loadingClass: 'loading'
			}
		};
	}

	function initializeDOM() {
		loadLastVisited();
	}

	function initializeEvents() {
		$cache.document.on('click', $cache.filterToggler, function() {
		 	toggleFilterFlyout();
		 });

		$cache.document.on('click', $cache.clearFiltersBtn, function() {
			const url = $(this).data('href');

			toggleFilterFlyout()
			app.search.updateProductListing(url);
		})

		$cache.document.on('refinements-loaded ready grid-update plp.columns.switched columns.switched grid-preload-updated', function(event, eventData) {
			const $container = eventData?.container || $cache.searchWrapper;
			app.components.product.custom.initProductSlider($container);
		});

		$cache.document.on('refinements-loaded', function() {
			$($cache.searchActionsTopBar).tabs({ active: 0 });
		});

		$cache.document.on('click', $cache.filterSubmitBtn, function(e) {
			e.preventDefault();

			$($cache.searchActionsTopBar).tabs({ active: false });
		});

		$cache.document.on('mobile.filter.reset', function() {
			$($cache.searchActionsTopBar).tabs({ active: false });
		});

		$cache.document.on('plpStrategy.columns.switched columns.switched', function(){$cache.window.trigger('reloadowl')});

		$cache.document.on('change', $cache.refinementCheckbox, function() {
			let url = $(this).data('url');

			if (app.device.isMobileView()) {
				$($cache.tabContent).addClass($cache.classes.loadingClass);
			} else {
				$($cache.filterFlyout).addClass($cache.classes.loadingClass);
			}

			url = app.util.appendParamsToUrl(url, {
				'isFilterOpened' : true
			});

			app.search.updateProductListing(url);
		});

		$cache.searchWrapper.on('click', $cache.selectors.notifyMeLink, app.product.openNotifyMePopup);
	}

	function loadLastVisited() {
		var lastVisitedTarget = $cache.lastVisited;
		var url = null;

		if (lastVisitedTarget.length) {
			url = app.util.appendParamsToUrl(app.urls.getLastVisited, {
				LastVisitedCount: app.preferences.pdpLastVisitedCount,
				zone: app.page && app.page.ns
			});

			app.ajax.load({
				url: url,
				target: lastVisitedTarget,
				callback: function() {
					$cache.document.trigger('last.visited.loaded');
				}
			});
		}
	}

	function toggleFilterFlyout() {
		const filterWrapperBlock = document.querySelector('.js-filter-flyout');
		const body = document.body;
		const params = new URLSearchParams(window.location.search);
	
		if (params.has('isFilterOpened')) {
			params.delete('isFilterOpened');
		} else {
			params.append('isFilterOpened', 'true');
		}
	
		window.history.replaceState(null, '', '?' + params);
	
		if (filterWrapperBlock.classList.contains('m-show')) {
			filterWrapperBlock.classList.remove('m-show');
			body.classList.remove('m-filters-opened');
		} else {
			filterWrapperBlock.classList.toggle('m-show');
			body.classList.toggle('m-filters-opened');
		}
	}

	/**
	 * @namespace app.search.custom public object
	 */
	app.components = app.components || {};
	app.components.search = app.components.search || {};
	app.components.search.custom = {
		init: function() {
			initializeCache();
			initializeDOM();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));
