import Observable from './observable';

class Minicart extends Observable {
	constructor() {
		super();
		this.products = [];
		this.prices = {};
		this.lineItemsQuantity = 0;
		this.approachingDiscounts = null;
		this.fetch();
		initEvents.call(this);
	}

	update(data) {
		if (data && data.redirectURL) {
			app.page.redirect(data.redirectURL);
		} else {
			Object.assign(this, data);
			notifySubcribers.call(this);
			processData();

			if (app.trackerData) {
				app.trackerData.cartInfo = app.minicart.trackerData;
			}
		}
	}

	fetch() {
		return fetch(app.urls.getNewMinicart, {
			credentials: 'include'
		}).then((res) => res.json())
			.then((json) => this.update(json));
	}
}

function initEvents() {
	const minicart = this;

	$(document).on('product.variation.reloaded', function() {
		notifySubcribers.call(minicart);
	});
}

function notifySubcribers() {
	this.notify('updateMinicartQuantity', {
		quantity: +this.lineItemsQuantity
	});
}

function processData() {
	app.minicart.taxMessageClass = !app.minicart.taxMessage ? 'h-hidden' : '';
	app.minicart.additinalWrapperClass = app.minicart.preferences && app.minicart.preferences.isPayPalAllowed ? 'b-mini_cart-flyout_paypal-express' : '';
	app.minicart.additinalCarouselClass = app.minicart.carouselSettings ? 'b-mini_cart-flyout_products js-owl_carousel' : '';
}

app.minicart = new Minicart();
