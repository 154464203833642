/**
 * Represents a search provider that provides configuration for searching.
 */
const TRANSITION_DURATION = app.util.getConfig('searchLayout.transitionDuration') ?? 0;

const classes = {
	container: 'c-search',
	searchOpened: 'm-search_opened'
};

class SearchProvider {
	/**
	 * Creates a new instance of the SearchProvider.
	 */
	constructor() {
		this.container = document.querySelector(app.util.getConfig('searchLayout.containerSelector'));

		initSearchContainer.call(this);
		initGlobalEvents.call(this);
	}

	open() {
		this.searchContainer.innerHTML = app.util.renderTemplate(document.getElementById('search')?.innerHTML);

		this.container.appendChild(this.searchContainer);

		setTimeout(function() {
			document.body.classList.add(classes.searchOpened);
		}, TRANSITION_DURATION);
	}

	/**
	 * Closes the search by removing it from the DOM and restoring the body's overflow class.
	 */
	close() {
		if (this.container.contains(this.searchContainer)) {
			document.body.classList.remove(classes.searchOpened);
			this.container.removeChild(this.searchContainer);
		}
	}
}

/**
 * Initializes the container for the search interface by creating a container element,
 * setting its class and innerHTML, and assigning it to the `container` property of the calling context.
 *
 * @function initSearchContainer
 * @this {Object} The calling context (e.g., `this` inside an object method).
 */
function initSearchContainer() {
	/**
	 * The container element for the search interface.
	 * @type {HTMLDivElement}
	 */
	const containerElement = document.createElement('div');
	containerElement.classList.add(classes.container);
	containerElement.style.transitionDuration = TRANSITION_DURATION + 'ms';

	this.searchContainer = containerElement;
}

/**
 * Initializes global events, such as keydown event for closing the app.
 * @function initGlobalEvents
 * @this {Object} The calling context (e.g., `this` inside an object method).
 */
function initGlobalEvents() {
	if (app.util.getConfig('searchLayout.isCloseByEsc')) {
		document.addEventListener('keydown', (e) => {
			if (e.key === 'Escape') {
				this.close();
			}
		});
	}
}

export default SearchProvider;
