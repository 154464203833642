(function(app, $) {
	function LanguageSelectorFlyout(config) {
		app.flyouts.Flyout.call(this, 'languageSelector', config);
	}

	LanguageSelectorFlyout.prototype = Object.create(app.flyouts.Flyout.prototype);
	LanguageSelectorFlyout.prototype.constructor = LanguageSelectorFlyout;

	/**
	 * @public
	 * Opens country selector flyout
 	 * @param {Object} params
 	 * @param {String} params.layoutURL - layout URL
	 */
	LanguageSelectorFlyout.prototype.open = function(params) {
		this.isOpened = true;

		setMarkup.call(this, params.layoutURL).then(() => {
			if (this.isOpened) {
				app.flyouts.Flyout.prototype.open.call(this, params);
			}
		}).catch(() => {
			this.close();
		});
	};

	/**
	 * @private
	 * Initializes markup
	 */
	LanguageSelectorFlyout.prototype.initMarkup = function(params) {
		app.flyouts.Flyout.prototype.initMarkup.call(this, params);

		renderCurrentCountry.call(this);
		setDefaultSelectedLocale.call(this);
		initLanguageFormSubmitEvent.call(this);
	};

	/**
	 * @private
	 * Sets markup
 	 * @param {String} layoutURL - layout URL
	 * @returns {Promise} - promise with HTML markup
	 */
	function setMarkup(layoutURL) {
		return new Promise((resolve, reject) => {
			if (this.markup) {
				return resolve();
			}

			$.ajax({
				url: layoutURL
			}).done((response) => {
				this.markup = response;

				resolve();
			}).fail(() => {
				reject();
			});
		});
	}

	/**
	 * @private
	 * Renders current country
	 */
	function renderCurrentCountry() {
		this.$markup.find('.js-current-country').html(app.user.country.displayValue);
	}

	/**
	 * @private
	 * Sets default selected locale
	 */
	function setDefaultSelectedLocale() {
		this.$markup.find(`input[name="locale"][value="${app.user.locale.value}"]`).prop('checked', true);
	}

	/**
	 * @private
	 * Initializes language form submit event
	 */
	function initLanguageFormSubmitEvent() {
		const $changeLanguageForm = this.$markup.find('.js-change-locale-form');

		$changeLanguageForm.submit((event) => {
			event.preventDefault();

			const locale = $changeLanguageForm.find('input[name="locale"]:checked').val();

			if (app.user.locale.value === locale) {
				this.close();
			} else {
				changeLocale(locale);
			}
		});
	}

	/**
	 * @public
	 * Changes locale
	 */
	function changeLocale(locale) {
		var changeLocaleURL = app.util.appendParamsToUrl(app.urls.changeLocale, { Locale: locale }, true);
		var changeLocaleURLwithOrigin = app.components.global.localeselector.addOriginToURL(changeLocaleURL);

		window.location.href = changeLocaleURLwithOrigin;
	}

	var config = app.configs.flyoutComponent;

	app.flyouts.languageSelector = new LanguageSelectorFlyout(config);
})((window.app = window.app || {}), jQuery);
