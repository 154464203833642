(function(app, $) {
	var $cache;

	function initializeCache() {
		$cache = {
			document: $(document),
			taxInfoButton: $('.js-sale-tax-icon-custom'),
			taxInfoCloseButton: $('.js-sale-tax-info-close_button'),
			taxInfoContent: $('.js-sale-tax-content'),
			hiddenClass: 'h-hidden'
		};
	}

	function initializeEvents() {
		initTaxInfoPopup();
	}

	function initTaxInfoPopup() {
		$cache.taxInfoButton.on('click', function(openingEvent) {
			openingEvent.stopPropagation();

			if ($cache.taxInfoContent.hasClass($cache.hiddenClass)) {
				$cache.taxInfoContent.removeClass($cache.hiddenClass);

				$cache.document.on('click.taxInfo', function(closingEvent) {
					if ($(closingEvent.target).is($cache.taxInfoCloseButton) || !$cache.taxInfoContent.has(closingEvent.target).length) {
						$cache.taxInfoContent.addClass($cache.hiddenClass);
						$cache.document.off('click.taxInfo');
					}
				});
			} else {
				$cache.taxInfoContent.addClass($cache.hiddenClass);
				$cache.document.off('click.taxInfo');
			}
		});
	}

	app.components = app.components || {};
	app.components.checkout = app.components.checkout || {};
	app.components.checkout.custom = {
		init: function() {
			initializeCache();
			initializeEvents();
		}
	};
}(window.app = window.app || {}, jQuery));
